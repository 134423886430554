/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-display-management-expand',
	templateUrl: './display-management-expand.component.html',
	styleUrls: ['./display-management-expand.component.scss']
})

/**
 * A component representing an instance of the
 * operation definition expand component.
 *
 * @export
 * @class DisplayManagementExpandComponent
 * @implements {IDynamicComponent<any, any>}
 */
export class DisplayManagementExpandComponent
implements IDynamicComponent<any, any>, OnInit
{
	/**
	 * Initializes a new instance of the DisplayManagementExpandComponent.
	 *
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The api service to use to handle instance data.
	 * @param {OptionsFactory} optionsFactory
	 * The common options factory used to populate dropdown data.
	 * @memberof DisplayManagementExpandComponent
	 * @param {InsuranceService} insuranceService
	 * The inusrance service.
	 */
	public constructor(
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public optionsFactory: OptionsFactory,
		private readonly insuranceService: InsuranceService)
	{
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof DisplayManagementExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the main loading state.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the static formly layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof DisplayManagementExpandComponent
	 */
	public staticFormlyLayout: FormlyFieldConfig[] ;

	/**
	 * Gets or sets the dynamic formly layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof DisplayManagementExpandComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the loading dynamic layout state.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public loadingDynamicLayout: boolean = true;

	/**
	 * Gets or sets the static formly validity.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public staticValidity: boolean;

	/**
	 * Gets or sets the dynamic formly validity.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public dynamicValidity: boolean;

	/**
	 * Initializes the component by setting the formly layouts.
	 *
	 * @async
	 * @memberof DisplayManagementExpandComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setStaticFormlyLayout();
		this.loading = false;
	}

	/**
	 * Checks the two formly forms validities.
	 *
	 * @memberof DisplayManagementExpandComponent
	 */
	public checkValidity(): void
	{
		this.context.source
			.validExpandComponentChanged(
				this.staticValidity === true
					&& this.dynamicValidity === true);
	}

	/**
	 * Sets the static form validity and
	 * checks overall validity.
	 *
	 * @param {boolean} event
	 * The form validity.
	 * @memberof DisplayManagementExpandComponent
	 */
	public staticFormValidity(event: boolean): void
	{
		this.staticValidity = event;
		this.checkValidity();
	}

	/**
	 * Sets the dynamic form validity and
	 * checks overall validity.
	 *
	 * @param {boolean} event
	 * The form validity.
	 * @memberof DisplayManagementExpandComponent
	 */
	public dynamicFormValidity(event: boolean): void
	{
		this.dynamicValidity = event;
		this.checkValidity();
	}

	/**
	 * Sets the static formly layout.
	 *
	 * @async
	 * @memberof DisplayManagementExpandComponent
	 */
	public async setStaticFormlyLayout(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions(
				await this.insuranceService.getMasterSecurityGroups());

		this.staticFormlyLayout = [
			{
				key: 'typeId',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				props: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Type',
					showClear: true,
					required: true,
					placeholder: AppConstants.placeholders.selectAnOption,
					options: (<any>this.context.source.customContext.source)
						.displayComponentTypeOptions
				}
			},
			{
				key: 'definitionId',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				props: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Definition',
					showClear: true,
					required: true,
					placeholder: AppConstants.placeholders.selectAnOption,
					options: (<any>this.context.source.customContext.source)
						.displayComponentDefinitionOptions,
					change:
						(field: FormlyFieldConfig) =>
							this.setDynamicFormlyLayout(
								field.formControl.value)
				}
			},
			{
				key: 'name',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				props: {
					label: 'Name',
					required: true
				},
				asyncValidators: {
					uniqueName: {
						expression:
							async (
								control: UntypedFormControl) =>
								this.isExistingName(control),
						message: 'Existing instance Name.'
					}
				}
			},
			{
				key: 'securityGroups',
				type: FormlyConstants.customControls.customMultiSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				props: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Security Groups',
					showClear: true,
					required: true,
					placeholder: AppConstants.placeholders.selectAnOption,
					options: (<any>this.context.source.customContext.source)
						.securityGroupOptions
				}
			},
			{
				...CommonFormlyFieldConstants
					.publicField,
				props: {
					...CommonFormlyFieldConstants
						.publicField
						.props,
					required: true
				}
			},
			{
				...CommonFormlyFieldConstants
					.ownershipSecurityGroupField,
				props: {
					...CommonFormlyFieldConstants
						.ownershipSecurityGroupField
						.props,
					label: 'Instance Owner(s)',
					options: securityGroupOptions,
					required: true,
					appendTo: FormlyConstants.appendToTargets.body
				}
			},
			{
				key: 'description',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				props: {
					label: 'Description',
					required: true
				}
			}
		];
	}

	/**
	 * Sets the dynamic formly layout.
	 *
	 * @async
	 * @memberof DisplayManagementExpandComponent
	 */
	public async setDynamicFormlyLayout(
		definitionId: number): Promise<void>
	{
		if (AnyHelper.isNullOrWhitespace(definitionId))
		{
			this.context.source.selectedItem.displayDefinition = null;
			this.dynamicFormlyLayout = [];

			return;
		}

		const interpolationData: object = {};

		this.context.source.selectedItem.definitionId =
			definitionId;
		this.context.source.selectedItem =
			await (<any>this.context.source.customContext.source)
				.decorateViewModel(
					this.context.source.selectedItem);

		const selectedItem: any =
			this.context.source.selectedItem;

		if (!AnyHelper.isNullOrEmpty(
			selectedItem.displayDefinition.jsonData)
			&& this.context.source.displayMode ===
				AppConstants.displayMode.create)
		{
			const interpolationSchema: string[] =
				JSON.parse(selectedItem.displayDefinition.jsonData)
					.interpolationSchema;

			if (!AnyHelper.isNull(interpolationSchema))
			{
				interpolationSchema.forEach(
					(interpolationItem) =>
					{
						interpolationData[interpolationItem] =
							AppConstants.empty;
					});

				const attributes: object =
					{
						interpolationData: interpolationData,
						parameterDefinition: {},
						parameterLayout: [],
						initialParameters: {}
					};

				selectedItem.jsonData =
					JSON.stringify(
						attributes,
						undefined,
						AppConstants.jsonTabIndent);
			}
		}

		this.dynamicFormlyLayout =
			[
				{
					type: FormlyConstants.customControls.customTextDisplay,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						title: 'Definition',
						useCodeBlock: true,
						usePanelDisplay: true,
						codeBlockType: AppConstants.markdownLanguages.json,
						content: AppConstants.empty
					},
					expressions: {
						'props.content':
							'`${model.displayDefinition.jsonData}`'
					}
				},
				{
					key: 'jsonData',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Attributes',
						rows: FormlyConstants.textAreaRowSizes.standard
					},
					validators: {
						validAttibute: {
							expression: (
								control: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.attributeValidator(
									control,
									field,
									interpolationData),
							message: AppConstants.empty
						}
					},
				}
			];

		this.loadingDynamicLayout = false;
	}

	/**
	 * Validates if the attribute field meets the
	 * templated criteria.
	 *
	 * @param {FormControl} control
	 * The form control.
	 * @param {FormlyFieldConfig} field
	 * The formly field config
	 * @param {object} interpolationData
	 * The interpolation data.
	 * @returns {boolean}
	 * Whether the entry is valid or not.
	 *
	 * @memberof DisplayManagementExpandComponent
	 */
	public attributeValidator(
		control: UntypedFormControl,
		field: FormlyFieldConfig,
		interpolationData: object): boolean
	{
		// Validates if null or empty.
		if (AnyHelper.isNullOrEmpty(control.value))
		{
			field.validators.validAttibute.message =
				'This value is required.';

			return false;
		}

		let isValid: boolean;
		let attributeObject: any;

		// Checks if a valid json.
		try
		{
			attributeObject = JSON.parse(control.value);
		}
		catch
		{
			field.validators.validAttibute.message =
				'Content is not a valid JSON.';

			return false;
		}

		// Validates no extra properties inside interpolationData.
		if (Object.keys(interpolationData)?.length > 0
			&& !AnyHelper.isNullOrEmpty(attributeObject.interpolationData))
		{
			for (const fieldValue of Object.keys(
				attributeObject.interpolationData))
			{
				isValid = false;
				for (const originalValue of Object.keys(interpolationData))
				{
					if (fieldValue === originalValue)
					{
						isValid = true;
					}
				}

				if (isValid !== true)
				{
					field.validators.validAttibute.message =
						fieldValue +
							' is not a valid interpolation data property.';

					return false;
				}
			}
		}

		// Validates interpolationData is required.
		if (attributeObject.interpolationData === undefined)
		{
			field.validators.validAttibute.message =
				'Interpolation Data field is required.';

			return false;
		}

		// Validates each outside property type.
		for (const value of Object.keys(attributeObject))
		{
			switch (value){
				case 'interpolationData':
				{
					let isValidType = this.propertyTypeChecker(
						attributeObject,
						field,
						value);

					if (!AnyHelper.isNullOrEmpty(attributeObject[value])
						&& Object.entries(attributeObject[value]).length === 0)
					{
						field.validators.validAttibute.message =
							`${value} must have at least one definition `
								+ 'property.';

						isValidType = false;
					}

					if (isValidType === false)
					{
						return false;
					}
					break;
				}
				case 'initialParameters':
				case 'parameterDefinition':
				{
					const isValidType = this.propertyTypeChecker(
						attributeObject,
						field,
						value);

					if (isValidType === false)
					{
						return false;
					}
					break;
				}
				case 'parameterLayout':
				{
					const isValidType =
						this.propertyTypeChecker(
							attributeObject,
							field,
							value,
							AppConstants.propertyTypes.array,
							'is not a valid object array.');

					if (isValidType === false)
					{
						return false;
					}
					break;
				}
				// Validates no extra outside properties are allowed.
				default:
				{
					field.validators.validAttibute.message =
						value +
							'is not part of the definition template.';

					return false;
				}
			}
		}

		// Validates if any duplicate key.
		const duplicates: object[] =
			control.value
				.replace(/(\r\n|\n|\r)/gm, AppConstants.empty)
				.match(/\b(\w+)(?=":)\b(?=.*?\b\1\b)/ig);

		if (duplicates?.length > 0)
		{
			field.validators.validAttibute.message =
				`${duplicates[0]} is a duplicate key.`;

			return false;
		}

		return true;
	}

	/**
	 * Checks the property type.
	 *
	 * @param {object} attributeObject
	 * The attribute Object.
	 * @param {FormlyFieldConfig} field
	 * The formly field config
	 * @param {string} value
	 * The string value.
	 * @param {propertyType} string
	 * The property type.
	 * @param {string} message
	 * The message string.
	 * @returns {boolean}
	 * Whether the property is a valid type.
	 *
	 * @memberof DisplayManagementExpandComponent
	 */
	public propertyTypeChecker(
		attributeObject: object,
		field: FormlyFieldConfig,
		value: string,
		propertyType: string = AppConstants.propertyTypes.object,
		message: string = 'is not a valid object.'): boolean
	{
		let isValidType: boolean = true;

		if (propertyType === AppConstants.propertyTypes.object)
		{
			isValidType = typeof attributeObject[value]
				=== AppConstants.propertyTypes.object
				&& attributeObject[value]?.length === undefined;
		}
		else if (propertyType === AppConstants.propertyTypes.array)
		{
			isValidType = typeof attributeObject[value]
				=== AppConstants.propertyTypes.object
				&& attributeObject[value]?.length !== undefined;
		}

		if (isValidType === false)
		{
			field.validators.validAttibute.message =
				`${value} ${message}`;
		}

		return isValidType;
	}

	/**
	 * Defines and validates if the value is existing or not.
	 * Note: This validation will not find matches if the user does not have
	 * query permissions for the  entity with a matching name.
	 *
	 * @async
	 * @param {UntypedFormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof DisplayManagementExpandComponent
	 */
	private async isExistingName(
		control: UntypedFormControl): Promise<boolean>
	{
		const initialPromiseArray: Promise<any>[] =
			[
				this.displayComponentInstanceApiService
					.query(
						`Name.ToLower() eq '${control.value.toLowerCase()}'`,
						AppConstants.empty)
			];

		initialPromiseArray.push(
			this.displayComponentInstanceApiService
				.query(
					`Id eq ${this.context.source.selectedItem.id}`,
					AppConstants.empty));

		return Promise.all(
			initialPromiseArray)
			.then(
				async (
					[
						typedOperationName,
						existingOperation
					]) =>
					Promise.resolve(
						typedOperationName.length === 0
							|| typedOperationName[0].name ===
								existingOperation[0]?.name));
	}
}