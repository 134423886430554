/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DrawerEntityComponentDirective
} from '@shared/directives/drawer-entity-component-directive';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

@Component({
	selector: 'entity-update',
	templateUrl: './entity-update.component.html',
	styleUrls: [
		'./entity-update.component.scss'
	]
})

/**
 * A component representing context level entity update display.
 *
 * @export
 * @class EntityUpdateComponent
 * @extends {DrawerEntityComponentDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class EntityUpdateComponent
	extends DrawerEntityComponentDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the entity update component.
	 *
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The entity version API service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition API service.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @param {EntityTypeApiService} entityService
	 * The entity service.
	 * @param {ResolverService} resolverService
	 * The resolver service.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector reference for this directive.
	 * @memberof EntityUpdateComponent
	 */
	 public constructor(
		public entityVersionApiService: EntityVersionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public activityService: ActivityService,
		public entityService: EntityService,
		public resolverService: ResolverService,
		public changeDetectorReference: ChangeDetectorRef)
	{
		super(
			entityService,
			entityInstanceApiService,
			changeDetectorReference);
	}

	/**
	 * Gets or sets a string representing the operation group for this udpate
	 * views action menu.
	 *
	 * @type {string}
	 * @memberof EntityUpdateComponent
	 */
	public operationGroupName: string = AppConstants.empty;

	/**
	 * Gets or sets an entity definition representing the currently displayed
	 * drawer item.
	 *
	 * @type {string}
	 * @memberof EntityUpdateComponent
	 */
	public entityDefinition: EntityDefinition;

	/**
	 * Handles the on initialization event.
	 * This will gather and display the entity based on the given
	 * context parameters.
	 *
	 * @async
	 * @memberof EntityUpdateComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.data =
			{
				...this.context.data,
				itemDisplayComponent: this
			};

		await this.selectedEntityDisplaySetup();

		const entityDefinition: IEntityDefinition =
			await this.entityService.getEntityDefinition(
				this.selectedEntityType.id,
				this.selectedItem.versionNumber);
		this.entityDefinition =
			new EntityDefinition(entityDefinition);
		this.operationGroupName =
			this.entityDefinition.actionMenuOperationGroupName;

		this.scrubMenuItems();

		this.loading = false;
	}

	/**
	 * Updates the existing entity instance values into the database for
	 * the current drawer item.
	 *
	 * @async
	 * @memberof EntityUpdateComponent
	 */
	public async updateEntity(): Promise<void>
	{
		this.saving = true;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.selectedEntityType.group;

		const updateDisplayName: string =
			this.getDisplayName(this.selectedEntityType.name)
				+ ' entity';

		await this.activityService.handleActivity<object>(
			new Activity<object>(
				this.entityInstanceApiService.update(
					this.selectedItem.id,
					this.selectedItem),
				`<strong>Updating</strong> ${updateDisplayName}`,
				`<strong>Updated</strong> ${updateDisplayName}`,
				`${updateDisplayName} was updated.`,
				`${updateDisplayName} was not updated.`));

		this.saving = false;

		this.changeDisplayMode.emit(
			AppConstants.displayMode.list);

		this.entityAltered.emit();
	}

	/**
	 * Handles an update of the current item given an update promise. This will
	 * redirect to the list when the update is complete.
	 *
	 * @async
	 * @param {Promise<any>} updatePromise
	 * The awaitable promise that will update this drawer entity item.
	 * @memberof EntityUpdateComponent
	 */
	public async handleUpdate(
		updatePromise: Promise<any>): Promise<void>
	{
		setTimeout(
			() =>
				this.saving = true);

		await updatePromise;

		this.saving = false;

		this.changeDisplayMode.emit(
			AppConstants.displayMode.list);

		this.entityAltered.emit();
	}
}