/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	ISecurityGroupDto
} from '@api/interfaces/security/security-group.dto.interface';
import {
	IActionResponseDto
} from '@api/interfaces/workflow/action-response.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	Transaction
} from '@insurance/business-logic-entities/transactions/transaction';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceHelper
} from '@insurance/helpers/insurance.helper';
import {
	IInsuranceEntityTypes
} from '@insurance/interfaces/insurance-entity-types.interface';
import {
	IInsuranceKeyContacts
} from '@insurance/interfaces/insurance-key-contacts.interface';
import {
	IInsurancePaymentSummary
} from '@insurance/interfaces/insurance-payment-summary.interface';
import {
	IInsuranceReferences
} from '@insurance/interfaces/insurance-references.interface';
import {
	IInsuranceServiceProvider
} from '@insurance/interfaces/insurance-service-provider.interface';
import {
	IPolicySummary
} from '@insurance/interfaces/policy-summary.interface';
import {
	IPolicyTermHierarchy
} from '@insurance/interfaces/policy-term-hierarchy';
import {
	WorkItem
} from '@shared/business-logic-entities/work-item';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SharedTypeConstants
} from '@shared/constants/shared-type-constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import { ApiHelper } from '@shared/helpers/api.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	IWorkItem
} from '@shared/interfaces/workItems/work-item.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	UserService
} from '@shared/services/user.service';
import {
	DateTime
} from 'luxon';

/**
 * A class representing a common interface to gather fully populated
 * insurance information.
 *
 * @export
 * @class InsuranceService
 */
@Injectable()
export class InsuranceService
{
	/**
	 * Creates an instance of an InsuranceService.
	 *
	 * @param {EntityService} entityService
	 * The entity service for this component.
	 * @param {RuleService} ruleService
	 * The rule service for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity type service for this component.
	 * @param {ResolverService} resolverService
	 * The resolver service for this component.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The resolver service for this component.
	 * @param {UserService} userService
	 * The user service for this component.
	 * @memberof InsuranceService
	 */
	public constructor(
		public readonly entityService: EntityService,
		public readonly ruleService: RuleService,
		public readonly entityInstanceApiService: EntityInstanceApiService,
		public readonly resolverService: ResolverService,
		public readonly securityGroupApiService: SecurityGroupApiService,
		public readonly userService: UserService)
	{
	}

	/**
	 * Creates and populates an insurance entity type lookup object.
	 *
	 * @async
	 * @returns {Promise<IInsuranceEntityTypes>}
	 * An awaitable insurance entity type object used for lookups.
	 * @memberof InsuranceService
	 */
	public async populateInsuranceEntityTypes(): Promise<IInsuranceEntityTypes>
	{
		await this.entityService.setStoredVariables();

		const insuranceEntityTypes: IInsuranceEntityTypes =
			<IInsuranceEntityTypes> {};

		const promiseArray: Promise<void>[] =
			[
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.policyEntityType =
							this.entityService
								.entityTypes
								.find(
									(entityType: IEntityType) =>
										entityType.group ===
											InsuranceConstants
												.insuranceEntityTypeGroups
												.policies);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.policyTermEntityType =
							this.entityService
								.entityTypes
								.find(
									(entityType: IEntityType) =>
										entityType.group ===
											InsuranceConstants
												.insuranceEntityTypeGroups
												.policyTerms);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.productEntityType =
							this.entityService
								.entityTypes
								.find(
									(entityType: IEntityType) =>
										entityType.group ===
											InsuranceConstants
												.insuranceEntityTypeGroups
												.products);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.ledgerEntityType =
							this.entityService
								.entityTypes
								.find(
									(entityType: IEntityType) =>
										entityType.group ===
											InsuranceConstants
												.insuranceEntityTypeGroups
												.ledger);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.ledgerTransactionEntityType =
							this.entityService
								.entityTypes
								.find(
									(entityType: IEntityType) =>
										entityType.group ===
											InsuranceConstants
												.insuranceEntityTypeGroups
												.ledgerTransaction);
						resolve();
					})
			];

		await Promise.all(promiseArray);

		return insuranceEntityTypes;
	}

	/**
	 * Gets the active policy term entity instance from the policy.
	 *
	 * @async
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the active policy term.
	 * @memberof InsuranceService
	 */
	public async getActivePolicyTerm(
		policyId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policies;
		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyId,
					null,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

		let activePolicyTermInstance: IEntityInstance =
			entityRelationships.find((instance: IEntityInstance) =>
				instance.data.status ===
					InsuranceConstants.policyTermStatusTypes.active);

		if (!AnyHelper.isNull(activePolicyTermInstance))
		{
			return activePolicyTermInstance;
		}

		activePolicyTermInstance =
			entityRelationships.find((instance: IEntityInstance) =>
				instance.data.status ===
					InsuranceConstants.policyTermStatusTypes.pending);

		return activePolicyTermInstance;
	}

	/**
	 * Gets the policy instance by policy term id.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy.
	 * @memberof InsuranceService
	 */
	public async getPolicyByPolicyTermId(
		policyTermId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getParents(
					policyTermId,
					null,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policies);

		return entityRelationships[0];
	}

	/**
	 * Gets the policy term instance by policy term transaction id.
	 *
	 * @async
	 * @param {number} transactionId
	 * The policy term transaction id.
	 * @param {string} transactionEntityTypeGroup
	 * The policy term transaction entity type group.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy term transaction.
	 * @memberof InsuranceService
	 */
	public async getPolicyTermByTransaction(
		transactionId: number,
		transactionEntityTypeGroup: string): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = transactionEntityTypeGroup;

		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getParents(
					transactionId,
					null,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

		return entityRelationships[0];
	}

	/**
	 * Gets the policy term instance by id.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy term.
	 * @memberof InsuranceService
	 */
	 public async getPolicyTerm(
		policyTermId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const policyTermInstance: IEntityInstance =
			await this.entityInstanceApiService.get(
				policyTermId);

		return policyTermInstance;
	}

	/**
	 * Gets all policy term instances by policy id that are not archived
	 * or obsolete unless specified otherwise.
	 *
	 * @async
	 * @param {number} policyId
	 * The policy id.
	 * @param {boolean} [includeArchived=false]
	 * Optional parameter to include archived transaction types.
	 * @returns {Promise<IEntityInstance[]>}
	 * An awaitable IEntityInstance array
	 * related to the policy term transaction.
	 * @memberof InsuranceService
	 */
	public async getPolicyTermsByPolicyId(
		policyId: number,
		includeArchived: boolean = false): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policies;

		let filter = `${AppConstants.commonProperties.status} ne `
			+ `'${InsuranceConstants.transactionStatusTypes.obsolete}'`;

		if (!includeArchived) {
			filter += ` and ${AppConstants.commonProperties.status} ne `
				+ `'${InsuranceConstants.transactionStatusTypes.archived}'`;
		}

		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyId,
					filter,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

		return entityRelationships;
	}

	/**
	 * Gets the Product instance by name.
	 *
	 * @async
	 * @param {number} productName
	 * The product name.
	 * @return {Promise<IEntityInstance>}
	 * The product instance that matches the product name.
	 * @memberof InsuranceService
	 */
	public async getProductByName(
		productName: string): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.products;

		const productInstance: IEntityInstance =
			await this.entityInstanceApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq '${productName}'`,
					AppConstants.empty);

		return productInstance;
	}

	/**
	 * Gets the Product of a transaction.
	 *
	 * @async
	 * @param {IEntityInstance} transaction
	 * The transaction.
	 * @return {Promise<IEntityInstance>}
	 * The product instance to which the transaction belongs.
	 * @memberof InsuranceService
	 */
	public async getProductByTransaction(
		transaction: IEntityInstance): Promise<IEntityInstance>
	{
		await this.entityService.setStoredVariables();

		const transactionType: IEntityType =
			this.entityService
				.entityTypes
				.find(
					(entityType: IEntityType) =>
						entityType.name === transaction.entityType);

		const policyTerm: IEntityInstance =
			await this.getPolicyTermByTransaction(
				transaction.id,
				transactionType.group);

		return this.getProductByName(
			policyTerm.data.productName);
	}

	/**
	 * Gets the policy term transaction entity type by group name.
	 *
	 * @async
	 * @param {number} group
	 * The group name.
	 * @return {Promise<IEntityType>}
	 * The policy term transaction entity type that matches the group name.
	 * @memberof InsuranceService
	 */
	 public async getPolicyTermTransactionEntityType(
		group: string): Promise<IEntityType>
	 {
		await this.entityService.setStoredVariables();

		 const policyTermTransactionEntityType: IEntityType =
			 this.entityService
				 .entityTypes
				 .find(
					 (entityType: IEntityType) =>
						 entityType.group === group);

		return policyTermTransactionEntityType;
	}

	/**
	 * Gets the latest child transaction of the sent policy term id be effective
	 * date. If status or type is sent, this will filter those results to that
	 * matching status, type, or both.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id to find a child policy term transaction for.
	 * @param {string} transactionEntityTypeGroup
	 * The policy term transaction type to get latest for.
	 * @param {string} transactionType
	 * If sent, this will filter the result set to only those with a matching
	 * transaction type. The status filter will also be applied if sent.
	 * @param {string} transactionStatus
	 * If sent, this will filter the result set to only those with a matching
	 * transaction status. The type filter will also be applied if sent.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance that signifies the latest policy term
	 * transaction.
	 * @memberof InsuranceService
	 */
	public async getLatestPolicyTermTransaction(
		policyTermId: number,
		transactionEntityTypeGroup: string,
		transactionType: string = AppConstants.empty,
		transactionStatus: string = AppConstants.empty):
			Promise<IEntityInstance>
	{
		const typeFilter: string =
			!AnyHelper.isNullOrWhitespace(transactionType)
				? `${AppConstants.commonProperties.type} eq `
					+ `'${transactionType}'`
				: AppConstants.empty;
		const statusFilter: string =
			!AnyHelper.isNullOrWhitespace(transactionStatus)
				? `${AppConstants.commonProperties.status} eq `
					+ `'${transactionStatus}'`
				: AppConstants.empty;

		let combinedFilter: string = AppConstants.empty;
		switch (true)
		{
			case !AnyHelper.isNullOrWhitespace(typeFilter)
				&& AnyHelper.isNullOrWhitespace(statusFilter):
				combinedFilter = typeFilter;
				break;
			case AnyHelper.isNullOrWhitespace(typeFilter)
				&& !AnyHelper.isNullOrWhitespace(statusFilter):
				combinedFilter = statusFilter;
				break;
			case !AnyHelper.isNullOrWhitespace(typeFilter)
				&& !AnyHelper.isNullOrWhitespace(statusFilter):
				combinedFilter = `${typeFilter} AND ${statusFilter}`;
				break;
		}

		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const entityTransactions: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyTermId,
					combinedFilter,
					`${InsuranceConstants.commonProperties.effectiveDate} `
						+ AppConstants.sortDirections.descending,
					0,
					1,
					transactionEntityTypeGroup);

		return entityTransactions.length > 0
			? entityTransactions[0]
			: null;
	}

	/**
	* Gets the latest policy term transaction associated to the policy term id.
	* This method will filter obsolete
	* transactions and optionally filter archived transactions.
	*
	* @async
	* @param {number} policyTermId
	* The policy term id.
	* @param {boolean} [includeArchived=false]
	* Optional parameter to include archived transaction types.
	* @returns {Promise<IEntityInstance>}
	* An awaitable IEntityInstance related to the policy.
	* @memberof InsuranceService
	*/
	public async getLatestPolicyTermTransactionByPolicyTerm(
		policyTermId: number,
		includeArchived: boolean = false): Promise<IEntityInstance>
	{
		const policyTermInstance: IEntityInstance =
			await this.getPolicyTerm(policyTermId);

		const policyTermTransactionTypeGroup: string  =
			InsuranceConstants.policyTermTransactionPrefix
				+ `${policyTermInstance.data.productName}`;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		let filter = `${AppConstants.commonProperties.status} ne `
			+ `'${InsuranceConstants.transactionStatusTypes.obsolete}'`;

		if (!includeArchived) {
			filter += ` and ${AppConstants.commonProperties.status} ne `
				+ `'${InsuranceConstants.transactionStatusTypes.archived}'`;
		}

		const policyTermTransactions: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				policyTermId,
				filter,
				`${AppConstants.commonProperties.id} `
					+ `${AppConstants.sortDirections.descending}`,
				null,
				AppConstants.dataLimits.large,
				policyTermTransactionTypeGroup);

		return policyTermTransactions[0];
	}

	/**
	 * Gets the latest policy term transaction associated to the policy id.
	 * This method will filter obsolete and archived transactions and will use
	 * the latest active policy term.
	 *
	 * @async
	 * @param {number} policyId
	 * The policy term id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy.
	 * @memberof InsuranceService
	 */
	public async getLatestPolicyTermTransactionByPolicy(
		policyId: number): Promise<IEntityInstance>
	{
		const policyTerm: IEntityInstance =
			await this.getActivePolicyTerm(policyId);

		return this.getLatestPolicyTermTransactionByPolicyTerm(
			policyTerm.id);
	}

	/**
	 * Adds policy forms to the policy term passed.
	 *
	 * @async
	 * @param {IEntityInstance} policyTerm
	 * The policy term for which to get the forms.
	 * @returns {Promise<any>}
	 * An awaitable object with the forms.
	 * @memberof InsuranceService
	 */
	public async getForms(
		policyTerm: IEntityInstance): Promise<any>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const response: any
			= await this.entityInstanceApiService
				.executeAction(
					policyTerm.id,
					'GetFormFiles',
					null,
					'generatorFilter=Tags.Contains("PolicyForm")');

		const actionResponse: IActionResponseDto = response.body;

		const forms: IFileEntity[] = actionResponse.value;

		const loadedForms: any =
			<any>
			{
				count: forms.length,
				forms: forms
			};

		return loadedForms;
	}

	/**
	 * Gets key contacts data from a transaction, term, or policy instance.
	 *
	 * @async
	 * @param {IEntityInstance} entity
	 * The entity to get key contacts for.
	 * @param {string} entityTypeGroup
	 * The entity type group to get key contacts for.
	 * @returns {Promise<IInsuranceKeyContacts>}
	 * An awaitable key contacts object holding product, agency, and
	 * primary insured information.
	 * @memberof InsuranceService
	 */
	public async getKeyContacts(
		entity: IEntityInstance,
		entityTypeGroup: string): Promise<IInsuranceKeyContacts>
	{
		let policy: IEntityInstance;
		let policyTerm: IEntityInstance;
		let policyTermTransaction: IEntityInstance;

		switch (entityTypeGroup)
		{
			case InsuranceConstants.insuranceEntityTypeGroups.policies:
				policy = entity;
				policyTerm =
					await this.getActivePolicyTerm(
						policy.id);
				policyTermTransaction =
					await this.getLatestPolicyTermTransactionByPolicyTerm(
						policyTerm.id);
				break;
			case InsuranceConstants.insuranceEntityTypeGroups.policyTerms:
				policyTerm = entity;
				policy =
					await this.getPolicyByPolicyTermId(
						policyTerm.id);
				policyTermTransaction =
					await this.getLatestPolicyTermTransactionByPolicyTerm(
						policyTerm.id);
				break;
			default:
				policyTermTransaction = entity;
				policyTerm =
					await this.getPolicyTermByTransaction(
						policyTermTransaction.id,
						entityTypeGroup);
				policy =
					await this.getPolicyByPolicyTermId(
						policyTerm.id);
				break;
		}

		const insuranceKeyContacts: IInsuranceKeyContacts =
			{
				agency:
					await this.getActiveServiceProviderInstance(
						policy,
						policyTermTransaction,
						InsuranceConstants.serviceProviderTypes.agency,
						InsuranceConstants.serviceProviderEntityTypeGroups
							.agency),
				producer:
					await this.getActiveServiceProviderInstance(
						policy,
						policyTermTransaction,
						InsuranceConstants.serviceProviderTypes.producer,
						InsuranceConstants.serviceProviderEntityTypeGroups
							.producer),
				primaryInsured:
					policyTermTransaction.data.interests[0]
			};

		return insuranceKeyContacts;
	}

	/**
	 * Gets insurance references from a transaction, term, or policy instance.
	 *
	 * @async
	 * @param {IEntityInstance} entity
	 * The entity to get references for.
	 * @param {string} entityTypeGroup
	 * The entity type group to get references for.
	 * @returns {Promise<IInsuranceReferences>}
	 * An awaitable references object holding related insurance identifiers.
	 * @memberof InsuranceService
	 */
	public async getInsuranceReferences(
		entity: IEntityInstance,
		entityTypeGroup: string): Promise<IInsuranceReferences>
	{
		const insuranceReferences: IInsuranceReferences =
			{
				policyNumber: entity.data.policyNumber
			};

		if (entityTypeGroup ===
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms)
		{
			insuranceReferences.renewalTerm = entity.data.renewalTerm;
		}
		else if (entityTypeGroup !==
			InsuranceConstants.insuranceEntityTypeGroups.policies)
		{
			const policyTerm: IEntityInstance =
				await this.getPolicyTermByTransaction(
					entity.id,
					entityTypeGroup);
			insuranceReferences.renewalTerm =
				policyTerm.data.renewalTerm;
			insuranceReferences.transactionNumber =
				entity.data.transactionNumber;
		}

		return insuranceReferences;
	}

	/**
	 * Gets active product payment plan definitions based on the sent entity
	 * id of a policy term or policy term transaction.
	 *
	 * @async
	 * @param {number} entityId
	 * The entity id of the policy term or term transaction.
	 * @param {string} entityTypeGroup
	 * The entity type group of policy term or term transaction.
	 * @returns {Promise<any[]>}
	 * An awaitable set of enabled product payment plan definitions based
	 * on the policy term effective date.
	 * @memberof InsuranceService
	 */
	public async getActiveProductPaymentPlanDefinitions(
		entityId: number,
		entityTypeGroup: string,
		invoiceToResourceId: string = null): Promise<any[]>
	{
		let product: IEntityInstance;
		let policyTerm: IEntityInstance;

		if (entityTypeGroup ===
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms)
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				entityTypeGroup;
			policyTerm =
				await this.entityInstanceApiService
					.get(entityId);
			product =
				await this.getProductByName(
					policyTerm.data.productName);
		}
		else
		{
			policyTerm =
				await this.getPolicyTermByTransaction(
					entityId,
					entityTypeGroup);
			product =
				await this.getProductByName(
					policyTerm.data.productName);
		}

		const policyTermEffectiveDate: DateTime =
			DateHelper.fromUtcIso(policyTerm.data.effectiveDate);

		const interestResourceId: string =
			invoiceToResourceId ?? policyTerm.data.preferences?.invoiceTo;

		const transaction: IEntityInstance =
			await this.getLatestPolicyTermTransactionByPolicyTerm(
				policyTerm.id);

		const selectedInterest: any =
			transaction.data.interests
				.find((interest: any) =>
					interest.resourceIdentifier === interestResourceId);

		const supportedInvoiceInterests: string[] =
			product.data.billing.supportedInvoiceInterests;

		return product.data.billing.paymentPlans.filter(
			(paymentPlan: any) =>
				paymentPlan.enabled === true
					&& (AnyHelper.isNull(paymentPlan.termLength)
						|| paymentPlan.termLength ===
							policyTerm.data.termLength)
					&& policyTermEffectiveDate >=
						DateHelper.fromUtcIso(paymentPlan.startDate)
					&& policyTermEffectiveDate <
						DateHelper.fromUtcIso(paymentPlan.endDate)
					&& ((AnyHelper.isNull(selectedInterest))
						|| ((!AnyHelper.isNullOrEmptyArray(
							paymentPlan.settings.enabledInvoiceInterests)
							&& (<any[]>paymentPlan.settings
								.enabledInvoiceInterests)
								.includes(selectedInterest.type))
							|| ((AnyHelper.isNullOrEmptyArray(
								paymentPlan.settings.enabledInvoiceInterests)
								&& !AnyHelper.isNullOrEmptyArray(
									supportedInvoiceInterests))
									&& supportedInvoiceInterests
										.includes(selectedInterest.type)))));
	}

	/**
	 * Generates and returns a draft payment plan schedule based on the
	 * sent parameters.
	 *
	 * @async
	 * @param {number} entityId
	 * The entity id of the policy term or term transaction.
	 * @param {string} entityTypeGroup
	 * The entity type group of policy term or term transaction.
	 * @param {string} paymentPlanId
	 * The draft payment plan id. If not sent this will default to the stored
	 * term accounting payment schedule payment plan id.
	 * @param {string} paymentPlanName
	 * The draft payment plan name. If not sent this will default to the stored
	 * term preferences payment plan selection.
	 * @param {string} paymentPlanVersion
	 * The draft payment plan version. If not sent this will default to the
	 * stored term accounting payment schedule payment plan version.
	 * @param {DateTime} startDate
	 * The start date for this payment schedule. If not sent this will default
	 * to the stored term effective date.
	 * @param {DateTime} targetDate
	 * The target date for this payment schedule. If not sent this will default
	 * to the stored term effective date.
	 * @param {boolean} includeFutureTransactionAccountingData
	 * Whether or not to include a future issued transaction's written values
	 * for making this draft. This will udpate the term accounting object
	 * sent to account for the payment at the effective date of the future
	 * transaction.
	 * @param {boolean} rebasePaymentPlan
	 * Whether or not to rebase this payment plan, this value defaults to false.
	 * @returns {Promise<any[]>}
	 * An awaitable set of payments that map to the sent parameters of this
	 * request based on an existing policy term or term transaction.
	 * @memberof InsuranceService
	 */
	public async generateDraftPaymentPlanSchedule(
		entityId: number,
		entityTypeGroup: string,
		paymentPlanId: string = null,
		paymentPlanName: string = null,
		paymentPlanVersion: string = null,
		startDate: DateTime = null,
		targetDate: DateTime = null,
		includeFutureTransactionAccountingData: boolean = false,
		rebasePaymentPlan: boolean = false): Promise<any[]>
	{
		let policyTerm: IEntityInstance;

		if (entityTypeGroup ===
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms)
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				entityTypeGroup;
			policyTerm =
				await this.entityInstanceApiService.get(entityId);
		}
		else
		{
			policyTerm =
				await this.getPolicyTermByTransaction(
					entityId,
					entityTypeGroup);
		}

		// Map default values.
		const mappedPaymentPlanId =
			paymentPlanId ??
				policyTerm.data.accounting.paymentSchedule.paymentPlan.id;
		const mappedPaymentPlanName =
			paymentPlanName ??
				policyTerm.data.preferences.paymentPlan;
		const mappedPaymentPlanVersion =
			paymentPlanVersion ??
				policyTerm.data.accounting.paymentSchedule.paymentPlan.version;
		const mappedStartDate: any =
			AnyHelper.isNull(startDate)
				? DateHelper.fromUtcIso(policyTerm.data.effectiveDate)
				: startDate;
		const mappedTargetDate: any =
			AnyHelper.isNull(targetDate)
				? mappedStartDate
				: targetDate;

		// Set the request entity as though this selection was made.
		// This value will not be saved.
		policyTerm =
			InsuranceHelper.modifyPolicyTermPaymentPlan(
				policyTerm,
				mappedPaymentPlanId,
				mappedPaymentPlanName,
				mappedPaymentPlanVersion);

		// Generate the payment schedule.
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		return this.entityInstanceApiService.executeAction(
			policyTerm.id,
			'GenerateDraftPaymentSchedule',
			policyTerm,
			`?paymentPlanId=${mappedPaymentPlanId}`
				+ `&startDate=${mappedStartDate}`
				+ `&targetDate=${mappedTargetDate}`
				+ '&isDraft=true'
				+ `&rebase=${rebasePaymentPlan}`
				+ '&includePendingTransaction='
				+ includeFutureTransactionAccountingData);
	}

	/**
	 * Gets a description used to display a policy term invoice to value.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id to find an invoice to description for.
	 * @param {string} invoiceToResourceIdentifier
	 * The interest's resource identifier that identifies an interest on the
	 * latest transaction.
	 * @returns {Promise<string>}
	 * An awaitable description of the interest name whether an organization
	 * or an individual.
	 * @memberof InsuranceService
	 */
	public async getPolicyTermInvoiceToDescription(
		policyTermId: number,
		invoiceToResourceIdentifier: string): Promise<string>
	{
		const latestTransaction: IEntityInstance =
			await this.getLatestPolicyTermTransactionByPolicyTerm(
				policyTermId);

		const invoiceTo: any =
			latestTransaction.data.interests
				.find(
					(interest: any) =>
						interest.resourceIdentifier ===
							invoiceToResourceIdentifier);

		if (AnyHelper.isNull(invoiceTo))
		{
			return null;
		}

		if (AnyHelper.isNullOrWhitespace(invoiceTo.characteristics?.name?.type))
		{
			return StringHelper.toNameString(
				invoiceTo.characteristics?.name?.firstName,
				invoiceTo.characteristics?.name?.lastName,
				invoiceTo.characteristics?.name?.legalName);
		}

		return SharedTypeConstants.individualTypes.includes(
			invoiceTo.characteristics.name.type)
			? StringHelper.toNameString(
				invoiceTo.characteristics?.name?.firstName,
				invoiceTo.characteristics?.name?.lastName)
			: StringHelper.toNameString(
				null,
				null,
				invoiceTo.characteristics?.name?.legalName);
	}

	/**
	 * Gets the policy term supported invoice interests.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id to get the invoice to interests.
	 * @returns {Promise<any[]>}
	 * The applicable invoice to interests that matches the product
	 * invoice to interests pre-defined from configuration.
	 * @memberof InsuranceService
	 */
	public async getPolicyTermSupportedInvoiceInterests(
		policyTermId: number): Promise<any[]>
	{
		const latestTransaction: IEntityInstance =
			await this.getLatestPolicyTermTransactionByPolicyTerm(
				policyTermId);

		const product: IEntityInstance =
			await this.getProductByTransaction(latestTransaction);

		const productInvoiceToInterests: string[] =
			product.data.billing.supportedInvoiceInterests;
		const transactionInterests: any[] =
			latestTransaction.data.interests;

		return transactionInterests.filter(
			(transactionInterest) =>
				productInvoiceToInterests.some(
					(productInvoiceToInterest) =>
						productInvoiceToInterest === transactionInterest.type));
	}

	/**
	 * Gets a description used to display a reason in a term, transaction, or
	 * policy.
	 *
	 * @async
	 * @param {number} entityId
	 * The entity id to find reason descriptions for.
	 * @param {string} entityTypeGroup
	 * The entity type group to find reason descriptions for.
	 * @param {string} reasonId
	 * The reason id.
	 * @param {string} productName
	 * If this is available, this will be the product name to look up reasons
	 * against.
	 * @returns {Promise<string>}
	 * An awaitable description related to the reason.
	 * @memberof InsuranceService
	 */
	public async getReasonDescription(
		entityId: number,
		entityTypeGroup: string,
		reasonId: string,
		productName: string = AppConstants.empty): Promise<string>
	{
		let productIdentifier: string = productName;
		if (AnyHelper.isNullOrWhitespace(productIdentifier))
		{
			if (entityTypeGroup ===
				InsuranceConstants.insuranceEntityTypeGroups.policies)
			{
				const policyTerms: IEntityInstance[] =
					await this.getPolicyTermsByPolicyId(entityId);

				if (AnyHelper.isNull(policyTerms[0]))
				{
					return null;
				}

				productIdentifier = policyTerms[0].data.productName;
			}
			else
			{
				const policyTerm: IEntityInstance =
					await this.getPolicyTermByTransaction(
						entityId,
						entityTypeGroup);

				if (AnyHelper.isNullOrWhitespace(policyTerm))
				{
					return null;
				}

				productIdentifier = policyTerm.data.productName;
			}
		}

		const product: IEntityInstance =
			await this.getProductByName(productIdentifier);

		if (AnyHelper.isNull(product?.data))
		{
			return null;
		}

		return product.data.reasons?.find(
			(reason: any) =>
				reason.id === reasonId)?.description;
	}

	/**
	 * Gets the service provider description matching the sent identifiers.
	 *
	 * @async
	 * @param {number} serviceProviderId
	 * The resource identifier of the service provider.
	 * @param {string} serviceProviderType
	 * The type of the service provider.
	 * @param {string} serviceProviderEffectiveDate
	 * The effective date of the service provider.
	 * @returns {Promise<string>}
	 * An awaitable description used to display a service provider.
	 * @memberof InsuranceService
	 */
	public async getServiceProviderDescription(
		serviceProviderId: number,
		serviceProviderType: string,
		serviceProviderEffectiveDate: string): Promise<string>
	{
		const isAgencyProvider: boolean =
			serviceProviderType ===
				InsuranceConstants.serviceProviderTypes.agency;
		this.entityInstanceApiService.entityInstanceTypeGroup =
			isAgencyProvider === true
				? InsuranceConstants.insuranceEntityTypeGroups.agencies
				: AppConstants.typeGroups.users;

		const serviceProvider: IEntityInstance =
			await this.entityInstanceApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.resourceIdentifier} eq `
						+ `'${serviceProviderId}'`,
					AppConstants.empty,
					true);

		if (AnyHelper.isNull(serviceProvider?.data))
		{
			return null;
		}

		return (isAgencyProvider === true
			? serviceProvider.data.name.legalName
			: StringHelper.toNameString(
				serviceProvider.data.firstName,
				serviceProvider.data.lastName,
				null))
				+ ' - Effective: '
				+ StringHelper.format(
					serviceProviderEffectiveDate,
					AppConstants.formatTypes.shortDate);
	}

	/**
	 * Given a set of policy term accounting payment schedule payments, this
	 * will translate that data into a summary output.
	 *
	 * @async
	 * @param {number} entityId
	 * The entity id of the policy term or term transaction.
	 * @param {string} entityTypeGroup
	 * The entity type group of policy term or term transaction.
	 * @param {string} paymentSchedulePayments
	 * The set of payments to map into a summary display.
	 * @param {boolean} includeUninvoicedOperationalFees
	 * Determines whether the payment schedule should include
	 * uninvoiced operational fees.
	 * @returns {Promise<IInsurancePaymentSummary[]>}
	 * An awaitable set of summaries displaying common information used
	 * in payment displays and accounting.
	 * @memberof InsuranceService
	 */
	public async mapPaymentSchedulePaymentSummary(
		entityId: number,
		entityTypeGroup: string,
		paymentSchedulePayments: any[],
		includeUninvoicedOperationalFees: boolean = false):
			Promise<IInsurancePaymentSummary[]>
	{
		let policyTerm: IEntityInstance;
		let ledger: IEntityInstance;

		if (entityTypeGroup !==
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms)
		{
			policyTerm =
				await this.getPolicyTermByTransaction(
					entityId,
					entityTypeGroup);
			ledger =
				await this.getLedger(
					policyTerm.id);
		}
		else
		{
			ledger =
				await this.getLedger(
					entityId);
		}

		const ledgerTransactions: IEntityInstance[] =
			await this.getLedgerTransactions(
				ledger.id,
				`type eq \"${InsuranceConstants
					.ledgerTransactionTypes
					.payment}\" `
					+ ` or adjustmentType eq \"${InsuranceConstants
						.ledgerTransactionAdjustmentTypes
						.writeOff}\"`);

		let totalPaid: number =
			-1 * ledgerTransactions
				.reduce(
					(sum: number,
						item: IEntityInstance) =>
						sum
							+ item.data.amount,
					0);

		let balanceForward: number = 0;

		return paymentSchedulePayments.map(
			(payment: any) =>
			{
				const operationalFeeTotal: number =
					this.sumPaymentDetailSplitOuts(
						payment.details
							.operationalFees);
				const invoicedOperationalFeeTotal: number =
					this.sumPaymentDetailSplitOuts(
						payment.details
							.operationalFees
							.filter(
								(operationalFee: any) =>
									!AnyHelper.isNullOrWhitespace(
										operationalFee
											.referenceId)));

				// Count operational fees that have been invoiced
				// based on the flag.
				const invoicedAmountDue: number =
					payment.amount
						+ (!includeUninvoicedOperationalFees
							? -operationalFeeTotal
								+ invoicedOperationalFeeTotal
							: 0);

				let amountDue: number =
					invoicedAmountDue;

				if (totalPaid > 0)
				{
					if (totalPaid > amountDue)
					{
						totalPaid -= amountDue;
						amountDue = 0;
					}
					else
					{
						amountDue =
							amountDue
								- totalPaid;
						totalPaid = 0;
					}
				}

				balanceForward += amountDue;

				const currentDate: DateTime =
					DateHelper.getSystemDateTime();
				const dueDate: DateTime =
					DateHelper.fromUtcIso(payment.date);
				const realizationDate: DateTime =
					DateHelper.fromUtcIso(payment.realizationDate);
				const pastDuePayment: boolean =
					amountDue > 0
						&& dueDate <= currentDate;
				const realizedPayment: boolean =
					amountDue > 0
						&& realizationDate <= currentDate;

				const annualStatementLines: number =
					this.sumPaymentDetailSplitOuts(
						payment.details.writtenAmount
							.annualStatementLines);
				const fees: number =
					this.sumPaymentDetailSplitOuts(
						payment.details.writtenAmount
							.fees);
				const taxes: number =
					this.sumPaymentDetailSplitOuts(
						payment.details.writtenAmount
							.taxes);

				const statusIcon: string =
					this.getPaymentStatusIcon(
						pastDuePayment,
						amountDue,
						invoicedAmountDue);

				return <IInsurancePaymentSummary>
					{
						date:
							dueDate,
						realizationDate:
							realizationDate,
						pastDuePayment:
							pastDuePayment,
						realizedPayment:
							realizedPayment,
						due:
							amountDue,
						paymentAmountDue:
							amountDue,
						paymentBalanceForward:
							balanceForward - amountDue,
						realizedPaymentBalanceForward:
							0,
						initialAmountDue:
							invoicedAmountDue,
						statusIcon:
							statusIcon,
						statusIconTooltip:
							this.getPaymentStatusIconTooltip(
								statusIcon),
						premium:
							annualStatementLines
								+ fees
								+ taxes,
						fees:
							operationalFeeTotal,
						adjustments:
							this.sumPaymentDetailSplitOuts(
								payment.details
									.adjustments),
						installmentWrittenPremium:
							annualStatementLines,
						installmentWrittenFees:
							fees,
						installmentWrittenTaxes:
							taxes,
						operationalFees:
							payment.details
								.operationalFees
					};
			});
	}

	/**
	 * Given a set of policy term accounting payment schedule payments, this
	 * will translate that data into summary outputs representing remaining due
	 * payments.
	 *
	 * @async
	 * @param {number} entityId
	 * The entity id of the policy term or term transaction.
	 * @param {string} entityTypeGroup
	 * The entity type group of policy term or term transaction.
	 * @param {string} paymentSchedulePayments
	 * The set of payments to map into a summary display or a previously
	 * summarized set of payment data.
	 * @param {bool} loadFromAccountingPayments
	 * If sent and false, this will use the existing payment schedule payments.
	 * If not sent or true, this will load the full summary data from
	 * the data accounting payment schedule.
	 * @returns {Promise<IInsurancePaymentSummary[]>}
	 * An awaitable set of remaining payment due summaries displaying common
	 * information used in payment displays and accounting.
	 * @memberof InsuranceService
	 */
	public async getRemainingPaymentSummaries(
		entityId: number,
		entityTypeGroup: string,
		paymentSchedulePayments: any[],
		loadFromAccountingPayments: boolean = true):
		Promise<IInsurancePaymentSummary[]>
	{
		const paymentSummary: IInsurancePaymentSummary[] =
			loadFromAccountingPayments === true
				? await this.mapPaymentSchedulePaymentSummary(
					entityId,
					entityTypeGroup,
					paymentSchedulePayments)
				: paymentSchedulePayments;

		return paymentSummary.filter(
			(payment: IInsurancePaymentSummary) =>
				payment.due > 0.005);
	}

	/**
	 * Given a set of policy term accounting payment schedule payments, this
	 * will translate that data into the next summary output from the set of
	 * remaining due payments.
	 *
	 * @async
	 * @param {number} entityId
	 * The entity id of the policy term or term transaction.
	 * @param {string} entityTypeGroup
	 * The entity type group of policy term or term transaction.
	 * @param {string} paymentSchedulePayments
	 * The set of payments to map into a summary display or a previously
	 * summarized set of payment data.
	 * @param {bool} loadFromAccountingPayments
	 * If sent and false, this will use the existing payment schedule payments.
	 * If not sent or true, this will load the full summary data from
	 * the data accounting payment schedule.
	 * @returns {Promise<IInsurancePaymentSummary[]>}
	 * An awaitable remaining payment due summary displaying common
	 * information used in payment displays and accounting.
	 * @memberof InsuranceService
	 */
	public async getNextInstallmentPayment(
		entityId: number,
		entityTypeGroup: string,
		paymentSchedulePayments: any[],
		loadFromAccountingPayments: boolean = true):
		Promise<IInsurancePaymentSummary>
	{
		const remainingAmountDuePaymentSummaries: IInsurancePaymentSummary[] =
			await this.getRemainingPaymentSummaries(
				entityId,
				entityTypeGroup,
				paymentSchedulePayments,
				loadFromAccountingPayments);

		const pastDuePayments: IInsurancePaymentSummary[] =
			remainingAmountDuePaymentSummaries
				.filter(
					(paymentSummary: IInsurancePaymentSummary) =>
						paymentSummary.pastDuePayment === true);
		const realizedPayments: IInsurancePaymentSummary[] =
			remainingAmountDuePaymentSummaries
				.filter(
					(paymentSummary: IInsurancePaymentSummary) =>
						paymentSummary.realizedPayment === true
							&& paymentSummary.pastDuePayment === false);

		let matchingPayment: IInsurancePaymentSummary = null;
		if (pastDuePayments.length > 0
			&& realizedPayments.length === 0)
		{
			matchingPayment =
				pastDuePayments[pastDuePayments.length - 1];
		}
		else if (realizedPayments.length > 0)
		{
			matchingPayment =
				realizedPayments[0];
		}

		if (!AnyHelper.isNull(matchingPayment))
		{
			matchingPayment.due =
				matchingPayment.due + matchingPayment.paymentBalanceForward;
			matchingPayment.realizedPaymentBalanceForward =
				Math.max(
					matchingPayment.paymentBalanceForward,
					matchingPayment.realizedPaymentBalanceForward);
			matchingPayment.paymentBalanceForward = 0;

			return matchingPayment;
		}

		return null;
	}

	/**
	 * Gets a work item business logic entity.
	 *
	 * @param instance
	 * An entity instance of a work itew.
	 * @returns {WorkItem}
	 * The work item business logic entity.
	 * @memberof InsuranceService
	 */
	public getWorkItem(instance: IWorkItem): WorkItem
	{
		return new WorkItem(
			instance,
			this.resolverService);
	}

	/**
	 * Gets a transaction business logic entity.
	 *
	 * @param instance
	 * An entity instance of a work itew.
	 * @returns {Transaction}
	 * The transaction business logic entity.
	 * @memberof InsuranceService
	 */
	public getTransaction(instance: IEntityInstance): Transaction
	{
		return new Transaction(
			instance,
			this.resolverService);
	}

	/**
	 * refreshes components.
	 *
	 * @param {string[]} components
	 * the component name.
	 * @param {string} targetType
	 * The target type to refresh
	 * @param {boolean} refreshBadges
	 * If sent and false, this will not refresh the badges of this component,
	 * this value defaults to true.
	 * @memberof InsuranceService
	 */
	public refreshComponents(
		components: string[],
		targetType: string,
		refreshBadges: boolean = true)
	{
		EventHelper.refreshComponents(
			components,
			targetType);

		if (refreshBadges)
		{
			this.refreshBadges(
				components,
				targetType);
		}
	}

	/**
	 * refreshes badges.
	 *
	 * @param {string[]} components
	 * the component name.
	 * @param {string} targetType
	 * The target type to refresh
	 * @memberof InsuranceService
	 */
	public refreshBadges(
		components: string[],
		targetType: string) {
		EventHelper.dispatchRefreshBadgePromiseEvents(
			components,
			targetType);
	}

	/**
	 * Gets the Ledger instance data associated to the current PolicyTerm
	 * instance.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The PolicyTerm entity instance id.
	 * @return {Promise<IEntityInstance>}
	 * The Ledger instance.
	 * @memberof InsuranceService
	 */
	public async getLedger(
		policyTermId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const ledgerInstances: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyTermId,
					null,
					null,
					null,
					null,
					InsuranceConstants.insuranceEntityTypeGroups
						.ledger);

		return ledgerInstances[0];
	}

	/**
	 * Gets the Ledger instance data associated to the current Agency
	 * instance.
	 *
	 * @async
	 * @param {number} agencyId
	 * The Agency entity instance id.
	 * @return {Promise<IEntityInstance>}
	 * The Ledger instance.
	 * @memberof InsuranceService
	 */
	public async getAgencyLedger(
		agencyId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.agencies;

		const ledgerInstances: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					agencyId,
					null,
					null,
					null,
					null,
					InsuranceConstants.insuranceEntityTypeGroups
						.ledger);

		return ledgerInstances[0];
	}

	/**
	 * Gets the LedgerTransacion entity instances associated to the current
	 * Ledger instance.
	 *
	 * @async
	 * @param {number} ledgerId
	 * The Ledger entity instance id.
	 * @return {Promise<IEntityInstance[]>}
	 * The LedgerTransaction instances array.
	 * @memberof InsuranceService
	 */
	public async getLedgerTransactions(
		ledgerId: number,
		filter: string = null): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.ledger;

		const ledgerTransactionInstances: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					ledgerId,
					filter,
					null,
					null,
					null,
					InsuranceConstants.insuranceEntityTypeGroups
						.ledgerTransaction);

		return ledgerTransactionInstances;
	}

	/**
	 * Get a value indicating true if submission is enabled on the product.
	 *
	 * @async
	 * @returns {boolean}
	 * The value indicating true if it is enabled, false if not.
	 * @memberof InsuranceService
	 */
	public async productSubmissionEnabled(
		transaction: IEntityInstance): Promise<boolean>
	{
		const product: IEntityInstance =
			await this.getProductByTransaction(transaction);

		return product
			.data
			.submit
			.enabled;
	}

	/**
	 * Gets the organization's user groups.
	 *
	 * @async
	 * @param {IEntityInstance} organization
	 * @returns {Promise<any[]>}
	 * A modified list of security groups that conatinsa display names.
	 * @memberof InsuranceService
	 */
	public async getOrganizationUserGroups(
		organization: IEntityInstance): Promise<any[]>
	{
		const organizationType: string =
			StringHelper.getLastSplitValue(
				organization.entityType,
				AppConstants.characters.period);

		const filter: string =
			`Name.StartsWith('${organizationType}_${organization.id}_')`;

		const groups: ISecurityGroupDto[] =
			await this.securityGroupApiService
				.query(
					filter,
					'Id');

		return groups.map(
			(group: ISecurityGroupDto) =>
				(
					{
						...group,
						displayName: StringHelper
							.beforeCapitalSpaces(StringHelper
								.getLastSplitValue(
									group.name,
									'_'))
					}
				));
	}

	/**
	 * Gets the organization's user groups input items.
	 *
	 * @async
	 * @param {IEntityInstance} organization
	 * @returns {Promise<any[]>}
	 * A modified list of security groups input items.
	 * @memberof InsuranceService
	 */
	public async GetOrganizationSecurityGroupInputItems(
		organization: IEntityInstance): Promise<any[]>
	{
		const securityGroups: any[] =
			await this.getOrganizationUserGroups(organization);

		const inputGroups: any[] = [];

		securityGroups.forEach(
			(group: any) =>
			{
				inputGroups.push(
					{
						label: group.displayName,
						description: group.description,
						key: group,
						value: false
					});
			});

		return inputGroups;
	}

	/**
	 * Updates the organization's user.
	 *
	 * @async
	 * @param {any} user
	 * The user to update.
	 * @param {IEntityInstance} organization
	 * The organization to which the user belongs.
	 * @param {ISecurityGroupDto[]} selectedGroups
	 * The selected groups of which the user is to be a member.
	 * @param {string} typeGroup
	 * @returns {Promise<any>}
	 * the response from the update organization user action.
	 * @memberof InsuranceService
	 */
	public async updateOrganizationUser(
		user: any,
		organization: IEntityInstance,
		selectedGroups: ISecurityGroupDto[],
		typeGroup: string = AppConstants.typeGroups.users): Promise<any>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = typeGroup;

		const organizationType: string =
			StringHelper
				.getLastSplitValue(
					organization.entityType,
					AppConstants.characters.period);

		const prefix: string = `${organizationType}_${organization.id}`;

		const selectedOrganizationGroups: string =
			selectedGroups
				.filter(group => group.name.startsWith(prefix))
				.map(group => group.id)
				.join(AppConstants.characters.comma);

		const query: string =
			AnyHelper.isNullOrEmpty(selectedOrganizationGroups)
				? '?securityGroups=none'
					+ `&parentId=${organization.id}`
				: `?securityGroups=${selectedOrganizationGroups}`
					+ `&parentId=${organization.id}`;

		const promise: Promise<any> =
			this.entityInstanceApiService.executeAction(
				user.id,
				'UpdateOrganizationUser',
				<IEntityInstanceDto>
				{
					id: user.id,
					resourceIdentifier: user.resourceIdentifier,
					versionNumber: user.versionNumber,
					data: user.data,
					entityType: user.entityType,
					createdById: user.createdById,
					changedById: user.changedById,
					changeDate: user.changeDate,
					createDate: user.createDate
				},
				query);

		await promise;

		// clear/reset client cache for this user.
		await this.userService.setUserSecurityGroups(user);

		return promise;
	}

	/**
	 * Loads and returns an organization name matching the sent id and type.
	 *
	 * @async
	 * @param {number} organizationId
	 * The organization id to load.
	 * @param {string} organizationTypeGroup
	 * The organization type group to load.
	 * @returns {Promise<string>}
	 * An awaitable display name matching the sent id and type for this
	 * organization.
	 * @memberof InsuranceService
	 */
	public async getOrganizationName(
		organizationId: number,
		organizationTypeGroup: string): Promise<string>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			organizationTypeGroup;
		const organizationInstance: IEntityInstance =
			await this.entityInstanceApiService.get(
				organizationId);

		if (AnyHelper.isNull(organizationInstance))
		{
			return null;
		}

		return StringHelper.toNameString(
			null,
			null,
			organizationInstance.data.name.legalName);
	}

	/**
	 * Gets all issued transactions with a future effective date.
	 *
	 * @async
	 * @param {IEntityInstance} policyTerm
	 * The policy term used to find future issued transactions.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable entity instance set that represents future
	 * issued transactions.
	 * @memberof InsuranceService
	 */
	public async getFutureIssuedTransactions(
		policyTerm: IEntityInstance): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const issuedTransactions: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				policyTerm.id,
				`${AppConstants.commonProperties.status} eq `
					+ `'${InsuranceConstants.transactionStatusTypes.issued}'`,
				'Id desc',
				null,
				null,
				InsuranceConstants.policyTermTransactionPrefix
					+ policyTerm.data.productName);

		return issuedTransactions
			.filter(
				(transaction: IEntityInstance) =>
					DateHelper.fromUtcIso(transaction.data.effectiveDate)
						> DateHelper.getSystemDateTime());
	}

	/**
	 * Sums all issued transactions with a future effective date and returns
	 * the total of either premium or fees and taxes.
	 *
	 * @async
	 * @param {IEntityInstance} policyTerm
	 * The policy term used to find future issued transactions.
	 * @param {string} accountingSumType
	 * If sent, this will be the accounting sum returned. This value defaults
	 * to returning the sum of premium accounting and any other value will
	 * return as the sum of fees and taxes.
	 * @returns {Promise<number>}
	 * An awaitable calculation consisting of the sum of future issued
	 * transactions based on the accounting sum type.
	 * @memberof InsuranceService
	 */
	public async sumFutureIssuedTransactions(
		policyTerm: IEntityInstance,
		accountingSumType: string = InsuranceConstants.accountingTypes.premium):
		Promise<number>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		let issuedTransactions: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				policyTerm.id,
				'status eq '
					+ `'${InsuranceConstants.transactionStatusTypes.issued}'`,
				'Id desc',
				null,
				null,
				InsuranceConstants.policyTermTransactionPrefix
					+ policyTerm.data.productName);
		const newBusinessOrRenewalTransaction: IEntityInstance =
			issuedTransactions
				.filter(
					(transaction: IEntityInstance) =>
						transaction.data.type ===
							InsuranceConstants.transactionTypes.newBusiness
							|| transaction.data.type ===
								InsuranceConstants.transactionTypes.renewal)[0];
		issuedTransactions =
			issuedTransactions
				.filter(
					(transaction: IEntityInstance) =>
						transaction.data.type !==
							InsuranceConstants.transactionTypes.newBusiness
							&& transaction.data.type !==
								InsuranceConstants.transactionTypes.renewal);

		// Inception date issued endorsements are included in term accounting.
		const futureIssuedTransactions: IEntityInstance[] =
			issuedTransactions
				.filter(
					(transaction: IEntityInstance) =>
						DateHelper.fromUtcIso(transaction.data.effectiveDate)
							> DateHelper.getSystemDateTime()
							&& DateHelper.fromUtcIso(
								transaction.data.effectiveDate)
								> DateHelper.fromUtcIso(
									newBusinessOrRenewalTransaction
										.data.effectiveDate));

		if (accountingSumType === InsuranceConstants.accountingTypes.premium)
		{
			return futureIssuedTransactions.reduce(
				(accumulator: number,
					policyTermTransaction: IEntityInstance) =>
					accumulator +
						policyTermTransaction.data
							.accounting.directWrittenPremium,
				0);
		}

		return futureIssuedTransactions.reduce(
			(accumulator: number,
				policyTermTransaction: IEntityInstance) =>
				accumulator
					+ policyTermTransaction.data
						.accounting.directWrittenFees
					+ policyTermTransaction.data
						.accounting.directWrittenTaxes,
			0);
	}

	/**
	 * Loads and outputs a policy summmary based on the sent policy number.
	 *
	 * @async
	 * @param {string} policyNumber
	 * The policy number to use to find the matching policy to summarize. This
	 * value is available in a policy or policy term.
	 * @returns {Promise<IPolicySummary>}
	 * An awaitable policy summary for common policy displays.
	 * @memberof InsuranceService
	 */
	public async getPolicySummary(
		policyNumber: string): Promise<IPolicySummary>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policies;
		const policy: IEntityInstance =
			await this.entityInstanceApiService.getSingleQueryResult(
				`policyNumber eq '${policyNumber}'`,
				`${AppConstants.commonProperties.id} `
					+ AppConstants.sortDirections.descending,
				true);

		if (AnyHelper.isNull(policy))
		{
			return null;
		}

		const policyTerms: IEntityInstance[] =
			await this.getPolicyTermsByPolicyId(
				policy.id);
		const latestPolicyTerm: IEntityInstance =
			policyTerms
				.find(
					(policyTerm: IEntityInstance) =>
						policyTerm.data.status ===
						InsuranceConstants.policyTermStatusTypes.active)
				?? policyTerms[policyTerms.length - 1];
		const policyTermTransaction: IEntityInstance =
			await this.getLatestPolicyTermTransactionByPolicyTerm(
				latestPolicyTerm.id);

		let summedLedgers: number = 0;
		const promiseArray: Promise<IEntityInstance>[] = [];
		for (const policyTerm of policyTerms)
		{
			promiseArray.push(
				this.getLedger(
					policyTerm.id));
		}

		await Promise
			.all(promiseArray)
			.then(
				(ledgers: IEntityInstance[]) =>
					summedLedgers =
						ledgers
							.reduce(
								(accumulator: number,
									ledger: IEntityInstance) =>
									accumulator
										+ (ledger?.data.balance ?? 0),
								0));

		const primaryInsured: any =
			policyTermTransaction.data.interests[0];

		const insuredName: string =
			StringHelper.toNameString(
				primaryInsured.characteristics.name?.firstName
					?? AppConstants.empty,
				primaryInsured.characteristics.name?.lastName
					?? AppConstants.empty);

		const matchingAddress: any =
			primaryInsured.characteristics
				.addresses
				?.find(
					(address: any) =>
						address.type ===
							SharedTypeConstants.addressType.mailing);
		const mailingAddress: string =
			StringHelper.toAddressString(
				matchingAddress?.address ?? AppConstants.empty,
				matchingAddress?.city ?? AppConstants.empty,
				matchingAddress?.state ?? AppConstants.empty,
				matchingAddress?.postalCode ?? AppConstants.empty);

		const policySummary: IPolicySummary =
			<IPolicySummary>
			{
				insured: insuredName,
				mailingAddress: mailingAddress,
				policyTotal:
					StringHelper.format(
						latestPolicyTerm?.data.accounting
							.writtenTotal.toString() ?? '0',
						AppConstants.formatTypes.currency),
				remainingBalance:
					StringHelper.format(
						summedLedgers?.toString() ?? '0',
						AppConstants.formatTypes.currency)
			};

		return policySummary;
	}

	/**
	 * Gets the unique states for an agencies active products.
	 *
	 * @async
	 * @param {number} agencyId
	 * The agency Id.
	 * @returns {Promise<any[]>}
	 * A list of unique states
	 * @memberof InsuranceService
	 */
	public async getUniqueStates(
		agencyId: number): Promise<any[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup
			= InsuranceConstants.insuranceEntityTypeGroups.agencies;

		const agency = await this.entityInstanceApiService.get(agencyId);

		const productSettings = agency.data.productSettings.filter(
			(productSetting: any) =>
				productSetting.authority ===
			 		InsuranceConstants.policyStatusTypes.active);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.products;

		const products = await this.entityInstanceApiService.query(
			'active eq \'true\'',
			AppConstants.name);

		const filteredProducts = products.filter(
			(product: any) =>
				productSettings.some(
					(productSetting: any) =>
						productSetting.name === product.data.name));

		return [...new Set(filteredProducts.map(
			(product: any) => product.data.state))];
	}

	/**
	 * Retrieves the names of active products for a specified agency and state,
	 * provided that the product settings permit quote creation.
	 *
	 * @async
	 * @param {number} agencyId
	 * The agency Id.
	 * @param {string} state
	 * The state name.
	 * @returns {Promise<any[]>}
	 * A list of product names.
	 * @memberof InsuranceService
	 */
	public async getActiveProductNamesByState(
		agencyId: number,
		state: string): Promise<any[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup
			= InsuranceConstants.insuranceEntityTypeGroups.agencies;

		const agency = await this.entityInstanceApiService.get(agencyId);

		const productSettings = agency.data.productSettings.filter(
			(productSetting: any) =>
				productSetting.authority ===
					InsuranceConstants.policyStatusTypes.active);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.products;

		const products = await this.entityInstanceApiService.query(
			`active eq 'true' and state eq '${state}'`,
			AppConstants.name);

		const filteredProducts = products.filter(
			(product: any) =>
				productSettings.some(
					(productSetting: any) =>
						productSetting.name === product.data.name
							&& product.data.newBusiness.quote.enableCreate));

		return [...new Set(filteredProducts.map(
			(product: any) => product.data.name))];
	}

	/**
	 * Given a policy term id, this will return the hierarchy of the current
	 * term which includes all issued transactions and the last pending
	 * transaction if existing.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id to get the hierarchy for.
	 * @returns {Promise<IPolicyTermHierarchy>}
	 * An awaitable policy term hierarchy which consists of the term, all issued
	 * transactions, and the latest pending transaction.
	 * @memberof InsuranceService
	 */
	public async getPolicyTermHierarchy(
		policyTermId: number): Promise<IPolicyTermHierarchy>
	{
		const policyTerm: IEntityInstance =
			await this.getPolicyTerm(
				policyTermId);

		const filter: string =
			`${AppConstants.commonProperties.status} ne `
				+ `'${InsuranceConstants.transactionStatusTypes.obsolete}' `
				+ `and ${AppConstants.commonProperties.status} ne `
				+ `'${InsuranceConstants.transactionStatusTypes.archived}'`;
		const policyTermTransactionTypeGroup: string  =
			InsuranceConstants.policyTermTransactionPrefix
				+ `${policyTerm.data.productName}`;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;
		const policyTermTransactions: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				policyTermId,
				filter,
				`${AppConstants.commonProperties.id} `
					+ `${AppConstants.sortDirections.descending}`,
				null,
				AppConstants.dataLimits.large,
				policyTermTransactionTypeGroup);

		const pendingTransactionTypes: string[] =
			[
				InsuranceConstants.transactionStatusTypes.quote,
				InsuranceConstants.transactionStatusTypes.pending,
				InsuranceConstants.transactionStatusTypes.application,
				InsuranceConstants.transactionStatusTypes.submitted,
				InsuranceConstants.transactionStatusTypes.bound
			];

		const policyTermHierarchy: IPolicyTermHierarchy =
				<IPolicyTermHierarchy>
				{
					policyTerm: policyTerm,
					issuedTransactions:
						policyTermTransactions.filter(
							(transaction: IEntityInstance) =>
								transaction.data.status ===
									InsuranceConstants.transactionStatusTypes
										.issued),
					pendingTransaction:
						policyTermTransactions.find(
							(transaction: IEntityInstance) =>
								pendingTransactionTypes.indexOf(
									transaction.data.status) !== -1)
				};

		return policyTermHierarchy;
	}

	/**
	 * Given a policy term hierarchy, this will gather all files that are
	 * children of the term, issued transactions, or the latest pending
	 * transaction if existing.
	 *
	 * @async
	 * @param {IPolicyTermHierarchy} policyTermHierarchy
	 * The policy term hierarchy to gather all files for.
	 * @returns {Promise<IEntityInstance[]>}
	 * The awaitable policy term hierarchy files which consists of the term,
	 * all issued transactions, and the latest pending transaction file
	 * collections.
	 * @memberof InsuranceService
	 */
	public async getHierarchyFiles(
		policyTermHierarchy: IPolicyTermHierarchy): Promise<IEntityInstance[]>
	{
		const fileWildcard: string = 'File';
		const policyTerm: IEntityInstance =
			policyTermHierarchy.policyTerm;
		const policyTermTransactionTypeGroup: string  =
			InsuranceConstants.policyTermTransactionPrefix
				+ `${policyTerm.data.productName}`;

		const promiseArray: Promise<IEntityInstance[]>[] = [];

		promiseArray.push(
			(async() =>
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms;
				const files: IEntityInstance[] =
					await this.entityService.getWildcardChildren(
						policyTerm.id,
						InsuranceConstants.insuranceEntityTypeGroups
							.policyTerms,
						fileWildcard,
						null);

				return files;
			})());

		if (!AnyHelper.isNull(policyTermHierarchy.issuedTransactions))
		{
			// Check all issued transactions for files.
			for (const issuedTransaction
				of policyTermHierarchy.issuedTransactions)
			{
				promiseArray.push(
					(async() =>
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
							policyTermTransactionTypeGroup;
						const files: IEntityInstance[] =
							await this.entityService.getWildcardChildren(
								issuedTransaction.id,
								issuedTransaction.entityType,
								fileWildcard,
								null);

						return files;
					})());
			}
		}

		if (!AnyHelper.isNull(policyTermHierarchy.pendingTransaction))
		{
			promiseArray.push(
				(async() =>
				{
					this.entityInstanceApiService.entityInstanceTypeGroup =
						policyTermTransactionTypeGroup;
					const files: IEntityInstance[] =
						await this.entityService.getWildcardChildren(
							policyTermHierarchy.pendingTransaction.id,
							policyTermHierarchy.pendingTransaction.entityType,
							fileWildcard,
							null);

					return files;
				})());
		}

		const hierarchyFileSets: IEntityInstance[][] =
			await Promise.all(promiseArray);
		const hierarchyFiles: IEntityInstance[] =
			hierarchyFileSets.flat();

		return hierarchyFiles;
	}

	/**
	 * Given a policy term hierarchy, this will gather all rule violations that
	 * are children of the term, issued transactions, or the latest pending
	 * transaction if existing.
	 *
	 * @async
	 * @param {IPolicyTermHierarchy} policyTermHierarchy
	 * The policy term hierarchy to gather all rules for.
	 * @returns {Promise<IEntityInstanceRuleViolation[]>}
	 * The awaitable policy term hierarchy rules which consists of the term,
	 * all issued transactions, and the latest pending transaction rule
	 * violation collections.
	 * @memberof InsuranceService
	 */
	public async getHierarchyRuleViolations(
		policyTermHierarchy: IPolicyTermHierarchy):
		Promise<IEntityInstanceRuleViolation[]>
	{
		const promiseArray: Promise<IEntityInstanceRuleViolation[]>[] = [];

		promiseArray.push(
			(async() =>
			{
				const ruleViolations: IEntityInstanceRuleViolation[] =
					await this.ruleService.getInstanceRuleViolations(
						policyTermHierarchy.policyTerm.id);

				return ruleViolations;
			})());

		if (!AnyHelper.isNull(policyTermHierarchy.issuedTransactions)
			&& policyTermHierarchy.issuedTransactions.length > 0)
		{
			// Only check the latest issued transaction for rules.
			promiseArray.push(
				(async() =>
				{
					const ruleViolations: IEntityInstanceRuleViolation[] =
						await this.ruleService.getInstanceRuleViolations(
							policyTermHierarchy.issuedTransactions[0].id);

					return ruleViolations;
				})());
		}

		if (!AnyHelper.isNull(policyTermHierarchy.pendingTransaction))
		{
			promiseArray.push(
				(async() =>
				{
					const ruleViolations: IEntityInstanceRuleViolation[] =
						await this.ruleService.getInstanceRuleViolations(
							policyTermHierarchy.pendingTransaction.id);

					return ruleViolations;
				})());
		}

		const hierarchyRuleSets: IEntityInstanceRuleViolation[][] =
			await Promise.all(promiseArray);
		const hierarchyRules: IEntityInstanceRuleViolation[] =
			hierarchyRuleSets.flat();

		return hierarchyRules;
	}

	/**
	 * Gets base level / master security groups.
	 *
	 * @returns {Promise<ISecurityGroup[]}
	 * An awaitable set of security groups.
	 * @memberof OptionsFactory
	 */
	public getMasterSecurityGroups(): Promise<ISecurityGroup[]>
	{
		const filter: string =
			'Name.StartsWith("Agency_")=false'
				+ ' AND Name.StartsWith("BackOfficeVendor_")=false'
				+ ' AND Name.StartsWith("HoldingCompany_")=false'
				+ ' AND Name.StartsWith("InsuranceCompany_")=false'
				+ ' AND Name.StartsWith("ManagingGeneralAgency_")=false'
				+ ' AND Name.StartsWith("ReinsuranceCompany_")=false'
				+ ' AND Name.StartsWith("ClaimsVendor_")=false'
				+ ' AND Name.StartsWith("IndependentAdjustingCompany_")=false'
				+ ' AND Name.StartsWith("ThirdPartyAdministrator_")=false';

		return ApiHelper.getFullDataSet(
			this.securityGroupApiService,
			filter,
			AppConstants.commonProperties.name,
			null,
			AppConstants.dataLimits.maxResultSet);
	}

	/**
	 * Given a policy entity instance, policyTermTransaction entity instance,
	 * service provider type, and a service
	 * provider type group this will load and return the associated entity
	 * instance matching the provider type and id.
	 *
	 *
	 * @async
	 * @param {IEntityInstance} policy
	 * The policy data holding service providers.
	 * @param {IEntityInstance} policyTermTransaction
	 * The policyTermTransaction data holding transaction type.
	 * @param {string} serviceProviderType
	 * The service provider type to load an entity instance for.
	 * @param {string} serviceProviderEntityTypeGroup
	 * The service provider entity type to be loaded.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable entity instance that represents the currently active
	 * service provider.
	 * @memberof InsuranceService
	 */
	private async getActiveServiceProviderInstance(
		policy: IEntityInstance,
		policyTermTransaction: IEntityInstance,
		serviceProviderType: string,
		serviceProviderEntityTypeGroup: string): Promise<IEntityInstance>
	{
		const matchingServiceProviders: IInsuranceServiceProvider[] =
			policy.data.serviceProviders
				.filter(
					(serviceProvider: IInsuranceServiceProvider) =>
						serviceProvider.type === serviceProviderType
							&& (policyTermTransaction.data.type ===
								InsuranceConstants.transactionTypes.newBusiness
								|| DateHelper.fromUtcIso(
									serviceProvider.effectiveDate)
									<= DateHelper.getSystemDateTime()));

		matchingServiceProviders.sort(
			(itemOne: IInsuranceServiceProvider,
				itemTwo: IInsuranceServiceProvider) =>
			{
				if (DateHelper.fromUtcIso(itemOne.effectiveDate)
					> DateHelper.fromUtcIso(itemTwo.effectiveDate))
				{
					return 1;
				}

				if (DateHelper.fromUtcIso(itemOne.effectiveDate)
					< DateHelper.fromUtcIso(itemTwo.effectiveDate))
				{
					return -1;
				}

				return 0;
			});

		if (matchingServiceProviders.length === 0)
		{
			return null;
		}

		this.entityInstanceApiService.entityInstanceTypeGroup =
			serviceProviderEntityTypeGroup;

		// user may not have read permissions.
		try
		{
			// Do not remove the await or the error will not be caught here.
			return await this.entityInstanceApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.resourceIdentifier} eq `
						+ `'${matchingServiceProviders[0].id}'`,
					AppConstants.empty);
		}
		catch
		{
			return null;
		}
	}

	/**
	 * Given a set of payment level values, this will map the icon that
	 * should be associated to those sent status values such as overdue
	 * or paid in full.
	 *
	 * @param {boolean} pastDueDate
	 * Signifies whether or not this payment is past it's due date.
	 * @param {number} amountDue
	 * The amount remaining as due on this payment, this value will include
	 * non-realized operational fees to calculate a  due amount different
	 * than expected.
	 * @param {number} amountDue
	 * The amount invoicede on this payment, this value will include
	 * realized operational fees to calculate a  due amount different
	 * than expected.
	 * @returns {string}
	 * A string that signifies that class that should be used when displaying
	 * the payment status icon.
	 * @memberof InsuranceService
	 */
	private getPaymentStatusIcon(
		pastDueDate: boolean,
		amountDue: number,
		invoicedAmountDue: number): string
	{
		if (amountDue > -0.005 && amountDue < 0.005)
		{
			return AppConstants.statusIconCssClasses.success;
		}

		if (pastDueDate === true)
		{
			return AppConstants.statusIconCssClasses.error;
		}

		const invoicedDifference: number =
			invoicedAmountDue - amountDue;
		if (invoicedDifference < -0.005 || invoicedDifference > 0.005)
		{
			return AppConstants.statusIconCssClasses.warning;
		}

		return AppConstants.empty;
	}

	/**
	 * Given a status icon class for a payment, this will return the expected
	 * tootip value.
	 *
	 * @param {string} statusIcon
	 * The status based icon for a payment used to define which icon to display.
	 * @returns {string}
	 * A string that signifies the tooltip value to use for the icon when
	 * displayed.
	 * @memberof InsuranceService
	 */
	private getPaymentStatusIconTooltip(
		statusIcon: string): string
	{
		switch (statusIcon)
		{
			case AppConstants.statusIconCssClasses.success:
				return 'Paid';
			case AppConstants.statusIconCssClasses.error:
				return 'Overdue';
			case AppConstants.statusIconCssClasses.warning:
				return 'Amount Due is different from Invoiced';
			default: return AppConstants.empty;
		}
	}

	/**
	 * Given a set of payment detail split outs, this will sum those values
	 * and return the full calculated amount of the sent data set.
	 *
	 * @param {any[]} paymentDetailSplitOuts
	 * The payment detail split outs.
	 * @returns {number}
	 * The summed amounts of all of the split outs sent.
	 * @memberof InsuranceService
	 */
	private sumPaymentDetailSplitOuts(
		paymentDetailSplitOuts: any[]): number
	{
		return paymentDetailSplitOuts
			.reduce(
				(sum: number,
					item: any) =>
					sum
						+ item.amount,
				0);
	}
}
