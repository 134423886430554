<ng-template #Invalid
	[ngIf]="isValid() === false
		|| isOwnershipAllowed === false"
	[ngIfElse]="Content">
	<div class="ui-g-12 text-center no-access-content">
		<div *ngIf="isOwnershipAllowed === true">
			This view is not currently available.
		</div>
		<div *ngIf="isOwnershipAllowed === false">
			<app-access-denied-content
				[drawerDisplay]="true"
				drawerName="Files"
				[sessionIdentifier]="sessionIdentifier"
				[accessDeniedUrl]="accessDeniedUrl"
				[resources]="resources"
				[clientMessage]="clientMessage">
			</app-access-denied-content>
		</div>
	</div>
</ng-template>

<ng-template #Content>
	<ng-template
		#BackNavigation
		[ngIf]="view!=='list'">
		<div
			class="ui-g-12 no-padding text-left nested-display-header">
			<span
				class="text-link theme-color nested-display-back-container"
				(click)="view='list'">
				<i class="fa fa-arrow-left nested-display-back-icon">
				</i>
				<span class="nested-display-back">
					Back
				</span>
			</span>
		</div>
	</ng-template>

	<div class="ui-g-12 no-padding text-center"
		*ngIf="view==='list'">
		<div *ngIf="loading; else CommonList"
			class="ui-g-12 text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
		<ng-template
			#CommonList>
			<common-list
				[context]="commonListContext"
				[enabledFilters]="enabledFilters">
			</common-list>
		</ng-template>
	</div>

	<app-add-file
		*ngIf="view==='add'"
		[context]="context"
		[categories]="creatableCategories"
		[securityDefinitions]="securityDefinitions"
		(added)="filesModified()"
		(changeDisplayMode) = "view=$event">
	</app-add-file>

	<app-file-details
		*ngIf="view==='details'"
		[categories]="categories"
		[fileEntity] = "fileSelected"
		[securityDefinitions]="securityDefinitions"
		(changeDisplayMode) = "view=$event">
	</app-file-details>

	<app-edit-file
		*ngIf="view==='edit'"
		[context]="context"
		[originalFileEntity]="fileSelected"
		[categories]="categories"
		[securityDefinitions]="securityDefinitions"
		(changeDisplayMode)="view=$event"
		(updated)="filesModified()">
	</app-edit-file>

	<app-delete-file
		*ngIf="view==='remove'"
		[context]="context"
		[fileEntity] = "fileSelected"
		[categories] = "categories"
		[securityDefinitions]="securityDefinitions"
		(changeDisplayMode) = "view=$event"
		(removed) = "fileRemoved($event)">
	</app-delete-file>

	<app-download-file
		*ngIf="view==='download'"
		[fileEntity]="fileSelected"
		[categories]="categories"
		[securityDefinitions]="securityDefinitions"
		(changeDisplayMode)="view=$event"
		(downloaded)="view='list'">
	</app-download-file>
</ng-template>