/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/**
 * A class containing static helper methods for insurance entities.
 *
 * @export
 * @class InsuranceHelper
 */
export class InsuranceHelper
{
	/**
	 * Gets or sets the set of commonly excluded transaction status values.
	 *
	 * @static
	 * @type {string[]}
	 * @memberof InsuranceHelper
	 */
	public static readonly transactionExcludedStatusValues: string[] =
		[
			InsuranceConstants.transactionStatusTypes.archived,
			InsuranceConstants.transactionStatusTypes.declined,
			InsuranceConstants.transactionStatusTypes.issued,
			InsuranceConstants.transactionStatusTypes.obsolete
		];

	/**
	 * Confirms that the status of the policy term transaction exists in the
	 * sent included status values and that this status value does not exist
	 * in the sent excluded status values.
	 *
	 * @static
	 * @param {string} status
	 * The policy term transaction status to be checked.
	 * @param {string[]} includedStatusValues
	 * If sent, this will be the set of possible status values that should
	 * return as a true conditional if it contains the sent status. This value
	 * defaults to an empty array.
	 * @param {string[]} excludedStatusValues
	 * If sent, this will be the set of possible status values that should
	 * return as a false conditional if it contains the sent status. This value
	 * will default to the set of archived, declined, issued, and obsolete
	 * status values.
	 * @returns {boolean}
	 * A value indicating whether the sent status value exists in the included
	 * status values and does not exist in the set of excluded status values.
	 * @memberof InsuranceHelper
	 */
	public static policyTermTransactionStatusCheck(
		status: string,
		includedStatusValues: string[] = [],
		excludedStatusValues: string[] = this.transactionExcludedStatusValues):
		boolean
	{
		return (includedStatusValues.length === 0
			|| includedStatusValues.includes(status))
			&& (excludedStatusValues.length === 0
				|| !excludedStatusValues.includes(status));
	}

	/**
	 * Gets the Ledger prior term transfer balance.
	 *
	 * @static
	 * @param {IEntityInstance[]} ledgerTransactions
	 * The LedgerTransaction entity instances.
	 * @return {number}
	 * The ledger prior term transfer balance.
	 * @memberof InsuranceHelper
	 */
	public static getPriorTermTransferBalance(
		ledgerTransactions: IEntityInstance[]): number
	{
		const transferBalance: number =
			ledgerTransactions.filter(
				ledgerTransaction =>
					ledgerTransaction.data.type ===
						InsuranceConstants.ledgerTransactionTypes.payment
						&& ledgerTransaction.data.category ===
							InsuranceConstants.ledgerTransactionCategories
								.transfer)
				.reduce(
					(sum, transfer) =>
						sum + transfer.data.amount,
					0);

		return transferBalance;
	}

	/**
	 * Gets the policy term written off balance.
	 *
	 * @static
	 * @param {IEntityInstance[]} ledgerTransactions
	 * The LedgerTransaction entity instances.
	 * @return {number}
	 * The policy term written off balance.
	 * @memberof InsuranceHelper
	 */
	public static getPolicyTermWrittenOffBalance(
		ledgerTransactions: IEntityInstance[]): number
	{
		const writtenOffBalance: number =
			ledgerTransactions.filter(
				ledgerTransaction =>
					ledgerTransaction.data.adjustmentType ===
						InsuranceConstants.ledgerTransactionAdjustmentTypes
							.writeOff)
				.reduce(
					(sum, transfer) =>
						sum + transfer.data.amount,
					0);

		return writtenOffBalance;
	}

	/**
	 * Gets the policy term fee balance from operational fees. This will
	 * not include the accounting written fee amount.
	 *
	 * @static
	 * @param {IEntityInstance} policyTerm
	 * The policy term associated to the ledger transaction set.
	 * @param {IEntityInstance[]} ledgerTransactions
	 * The LedgerTransaction entity instances.
	 * @return {number}
	 * The policy term operational fee balance.
	 * @memberof InsuranceHelper
	 */
	public static getPolicyTermOperationalFeeBalance(
		policyTerm: IEntityInstance,
		ledgerTransactions: IEntityInstance[]): number
	{
		const totalFeeBalance: number =
			ledgerTransactions.filter(
				ledgerTransaction =>
					ledgerTransaction.data.type ===
						InsuranceConstants.ledgerTransactionTypes.fee)
				.reduce(
					(sum, transfer) =>
						sum + transfer.data.amount,
					0);

		return totalFeeBalance - (policyTerm.data.accounting?.writtenFees ?? 0);
	}

	/**
	 * Gets the Ledger payments balance.
	 *
	 * @static
	 * @param {IEntityInstance[]} ledgerTransactions
	 * The LedgerTransaction entity instances.
	 * @return {number}
	 * The ledger payments balance.
	 * @memberof InsuranceHelper
	 */
	public static getPaymentsBalance(
		ledgerTransactions: IEntityInstance[]): number
	{
		const transferBalance: number =
			ledgerTransactions.filter(
				ledgerTransaction =>
					ledgerTransaction.data.type ===
						InsuranceConstants.ledgerTransactionTypes.payment
						&& ledgerTransaction.data.category !==
							InsuranceConstants.ledgerTransactionCategories
								.transfer)
				.reduce(
					(sum, transfer) =>
						sum + transfer.data.amount,
					0);

		return transferBalance;
	}

	/**
	 * Updates payment plan based values of the policy term object.
	 *
	 * @static
	 * @param {IEntityInstance} policyTerm
	 * The policy term entity to be updated.
	 * @param {string} paymentPlanId
	 * The payment plan id to set.
	 * @param {string} paymentPlanName
	 * The payment plan name to set.
	 * @param {string} paymentPlanVersion
	 * The payment plan version to set.
	 * @returns {IEntityInstance}
	 * A mapped policy term holding updated payment plan information based
	 * on the sent parameters.
	 * @memberof InsuranceHelper
	 */
	public static modifyPolicyTermPaymentPlan(
		policyTerm: IEntityInstance,
		paymentPlanId: string,
		paymentPlanName: string,
		paymentPlanVersion: string): IEntityInstance
	{
		policyTerm.data.preferences.paymentPlan =
			paymentPlanName;

		if (AnyHelper.isNull(
			policyTerm.data.accounting.paymentSchedule.paymentPlan))
		{
			policyTerm.data.accounting.paymentSchedule.paymentPlan =
				<any>
				{
					id: paymentPlanId,
					version: paymentPlanVersion
				};
		}
		else
		{
			policyTerm.data.accounting.paymentSchedule.paymentPlan.id =
				paymentPlanId;
			policyTerm.data.accounting.paymentSchedule.paymentPlan.version =
				paymentPlanVersion;
		}

		return policyTerm;
	}

	/**
	 * Given a policy term and future issued transaction, this will calculate
	 * the expected policy term accounting object written values. Note: This
	 * will not calculate or alter earned and should only be used for
	 * forecasting the term accounting.
	 *
	 * @static
	 * @param {IEntityInstance} policyTerm
	 * The policy term that will have it's accounting object updated.
	 * @param {IEntityInstance} policyTermTransaction
	 * The future issued or pending policy term transaction that will
	 * be used to update the policy term accounting object.
	 * @returns {Promise<IEntityInstance>}
	 * An updated policy term accounting object mapped from the future
	 * issued or pending sent policy term transaction.
	 * @memberof InsuranceHelper
	 */
	public static mapFutureTransactionToTermAccounting(
		policyTerm: IEntityInstance,
		policyTermTransaction: IEntityInstance): any
	{
		const policyTermAccounting =
			policyTerm.data.accounting;
		const policyTermTransactionAccounting =
			policyTermTransaction.data.accounting;

		policyTermAccounting.writtenPremium +=
			policyTermTransactionAccounting.directWrittenPremium;
		policyTermAccounting.writtenFees +=
			policyTermTransactionAccounting.directWrittenFees;
		policyTermAccounting.writtenTaxes +=
			policyTermTransactionAccounting.directWrittenTaxes;
		policyTermAccounting.writtenTotal +=
			policyTermTransactionAccounting.directWrittenTotal;

		policyTermAccounting.annualStatementLines =
			this.mapFutureAccountingSplitOuts(
				policyTermAccounting.annualStatementLines,
				policyTermTransactionAccounting.annualStatementLines);
		policyTermAccounting.fees =
			this.mapFutureAccountingSplitOuts(
				policyTermAccounting.fees,
				policyTermTransactionAccounting.fees);
		policyTermAccounting.taxes =
			this.mapFutureAccountingSplitOuts(
				policyTermAccounting.taxes,
				policyTermTransactionAccounting.taxes);

		return policyTermAccounting;
	}

	/**
	 * Given a policy term split out set, this will calculate the mapped
	 * future split out of the policy term based on the transaction
	 * split outs in the same group with the same name.
	 *
	 *
	 * @static
	 * @param {IEntityInstance} termSplitOuts
	 * The policy term split outs that will have their accounting data updated.
	 * @param {IEntityInstance} transactionSplitOuts
	 * The future issued or pending policy term transaction split out that will
	 * be used to update the policy term accounting object.
	 * @returns {Promise<IEntityInstance>}
	 * An updated policy term accounting split out set mapped from the future
	 * issued or pending sent policy term transaction.
	 * @memberof InsuranceHelper
	 */
	private static mapFutureAccountingSplitOuts(
		termSplitOuts: any[],
		transactionSplitOuts: any[]): any
	{
		for (const termSplitOut of termSplitOuts)
		{
			const transactionSplitOut: any =
				transactionSplitOuts.find(
					(splitOut: any) =>
						splitOut.name === termSplitOut.name);

			if (!AnyHelper.isNull(transactionSplitOut))
			{
				termSplitOut.written +=
					transactionSplitOut.directWritten;
			}
		}

		for (const transactionSplitOut of transactionSplitOuts)
		{
			const termSplitOut: any =
				termSplitOuts.find(
					(splitOut: any) =>
						splitOut.name === transactionSplitOut.name);

			if (AnyHelper.isNull(termSplitOut))
			{
				termSplitOuts.push(
					<any>
					{
						name: transactionSplitOut.name,
						written: transactionSplitOut.directWritten
					});
			}
		}

		return termSplitOuts;
	}
}