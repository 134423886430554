/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';

/**
 * A class representing static constant and read only values.
 *
 * @export
 * @class AppConstants
 */
export class AppConstants
{
	public static readonly activityStatus:
	{
		complete: string;
		error: string;
		info: string;
		pending: string;
	} = {
			complete: 'Complete',
			error: 'Error',
			info: 'Info',
			pending: 'Pending'
		};

	public static readonly apiControllers:
	{
		authenticate: string;
		displayComponentDefinitions: string;
		displayComponentInstances: string;
		displayComponentTypes: string;
		entityDefinitions: string;
		entityInstances: string;
		entityInstanceRuleViolations: string;
		entityInstanceRuleViolationOverrides: string;
		entityLayouts: string;
		entityLayoutTypes: string;
		entityTypes: string;
		entityVersions: string;
		logs: string;
		operationDefinitions: string;
		operationDefinitionParameters: string;
		operationGroups: string;
		operationGroupHierarchies: string;
		operationTypes: string;
		operationTypeParameters: string;
		ruleDefinitions: string;
		rulePresentationDefinitions: string;
		rulePresentationLogicDefinitions: string;
		ruleValidationDefinitions: string;
		ruleValidatorDefinitions: string;
		ruleViolationWorkflowActionDefinitions: string;
		securityGroups: string;
		securityAccessPolicies: string;
		securityAccessPolicyDefinitions: string;
		securityAccessPolicyMappings: string;
		securityApplications: string;
		securityGroupRuleDefinitionViolationOverrides: string;
		securitySessions: string;
		workflowActionDefinitions: string;
		workflowActionInstances: string;
		workflowActionRequisites: string;
		workflowFailureActions: string;
		workflowRequisiteTypes: string;
	} = {
			authenticate: 'authenticate',
			displayComponentDefinitions: 'displayComponentDefinitions',
			displayComponentInstances: 'displayComponentInstances',
			displayComponentTypes: 'displayComponentTypes',
			entityDefinitions: 'entityDefinitions',
			entityInstances: 'entityInstances',
			entityInstanceRuleViolations: 'entityInstanceRuleViolations',
			entityInstanceRuleViolationOverrides:
			'entityInstanceRuleViolationOverrides',
			entityLayouts: 'entityLayouts',
			entityLayoutTypes: 'entityLayoutTypes',
			entityTypes: 'entityTypes',
			entityVersions: 'entityVersions',
			logs: 'logs',
			operationDefinitions: 'operationDefinitions',
			operationDefinitionParameters: 'operationDefinitionParameters',
			operationGroups: 'operationGroups',
			operationGroupHierarchies: 'operationGroupHierarchies',
			operationTypes: 'operationTypes',
			operationTypeParameters: 'operationTypeParameters',
			ruleDefinitions: 'ruleDefinitions',
			rulePresentationDefinitions: 'rulePresentationDefinitions',
			rulePresentationLogicDefinitions:
				'rulePresentationLogicDefinitions',
			ruleValidationDefinitions: 'ruleValidationDefinitions',
			ruleValidatorDefinitions: 'ruleValidatorDefinitions',
			ruleViolationWorkflowActionDefinitions:
			'ruleViolationWorkflowActionDefinitions',
			securityGroups: 'securityGroups',
			securityAccessPolicies: 'securityAccessPolicies',
			securityAccessPolicyDefinitions: 'securityAccessPolicyDefinitions',
			securityAccessPolicyMappings: 'securityAccessPolicyMappings',
			securityApplications: 'securityApplications',
			securityGroupRuleDefinitionViolationOverrides:
			'securityGroupRuleDefinitionViolationOverrides',
			securitySessions: 'securitySessions',
			workflowActionDefinitions: 'workflowActionDefinitions',
			workflowActionInstances: 'workflowActionInstances',
			workflowActionRequisites: 'workflowActionRequisites',
			workflowFailureActions: 'workflowFailureActions',
			workflowRequisiteTypes: 'workflowRequisiteTypes'
		};

	public static readonly apiMethods:
	{
		get: string;
		getChildren: string;
		getParents: string;
		query: string;
		create: string;
		createChild: string;
		createEntityInstance: string;
		update: string;
		updateChild: string;
		delete: string;
		deleteChild: string;
		aggregate: string;
		put: string;
		post: string;
	} = {
			get: 'Get',
			getChildren: 'GetChildren',
			getParents: 'GetParents',
			query: 'Query',
			create: 'Create',
			createChild: 'CreateChild',
			createEntityInstance: 'createEntityInstance',
			update: 'Update',
			updateChild: 'UpdateChild',
			delete: 'Delete',
			deleteChild: 'DeleteChild',
			aggregate: 'Aggregate',
			put: 'Put',
			post: 'Post'
		};

	public static readonly aggregateMethods:
	{
		count: string;
		sum: string;
		average: string;
		min: string;
		max: string;
	} = {
			count: 'Count',
			sum: 'Sum',
			average: 'Average',
			min: 'Min',
			max: 'Max'
		};

	public static readonly basePageSections:
	{
		headerIdentifier: string;
		topIdentifier: string;
	} = {
			headerIdentifier: 'base-page-header',
			topIdentifier: 'Top'
		};

	public static readonly characters:
	{
		and: string;
		asterisk: string;
		at: string;
		colon: string;
		comma: string;
		dollarSign: string;
		doubleQuote: string;
		forwardSlash: string;
		leftBracket: string;
		leftParantheses: string;
		hyphen: string;
		leftSquareBracket: string;
		period: string;
		percent: string;
		pound: string;
		rightParantheses: string;
		rightSquareBracket: string;
		space: string;
		underscore: string;
		questionMark: string;
	} = {
			and: '&',
			asterisk: '*',
			at: '@',
			colon: ':',
			comma: ',',
			dollarSign: '$',
			doubleQuote: '"',
			forwardSlash: '/',
			leftBracket: '[',
			leftParantheses: '(',
			hyphen: '-',
			leftSquareBracket: '[',
			period: '.',
			percent: '%',
			pound: '#',
			rightParantheses: ')',
			rightSquareBracket: ']',
			space: ' ',
			underscore: '_',
			questionMark: '?'
		};

	public static readonly commonProperties: {
		accessPolicyId: string;
		actionId: string;
		assignedTo: string;
		changeDate: string;
		changedById: string;
		characteristics: string;
		createDate: string;
		createdBy: string;
		createdById: string;
		datasetId: string;
		doneDate: string;
		effectiveDate: string;
		entityType: string;
		firstName: string;
		group: string;
		id: string;
		jsonData: string;
		keywords: string;
		label: string;
		layoutId: string;
		layoutTypeId: string;
		lastName: string;
		name: string;
		number: string;
		order: string;
		ownershipSecurityGroupId: string;
		postalCode: string;
		ruleDefinitionId: string;
		rulePresentationDefinitionId: string;
		ruleValidationDefinitionId: string;
		ruleWorkflowDefinitionId: string;
		resourceIdentifier: string;
		startDate: string;
		status: string;
		subtype: string;
		subType: string;
		type: string;
		typeId: string;
		userName: string;
		versionId: string;
		versionNumber: string;
	} = {
			accessPolicyId: 'accessPolicyId',
			actionId: 'actionId',
			assignedTo: 'assignedTo',
			changeDate: 'changeDate',
			createdBy: 'createdBy',
			createdById: 'createdById',
			changedById: 'changedById',
			characteristics: 'characteristics',
			createDate: 'createDate',
			datasetId: 'datasetId',
			doneDate: 'keyDates.doneDate',
			effectiveDate: 'effectiveDate',
			entityType: 'entityType',
			firstName: 'firstName',
			group: 'group',
			id: 'id',
			jsonData: 'jsonData',
			keywords: 'keywords',
			label: 'label',
			layoutId: 'layoutId',
			layoutTypeId: 'layoutTypeId',
			lastName: 'lastName',
			name: 'name',
			number: 'number',
			order: 'order',
			ownershipSecurityGroupId: 'ownershipSecurityGroupId',
			postalCode: 'postalCode',
			ruleDefinitionId: 'ruleDefinitionId',
			rulePresentationDefinitionId: 'rulePresentationDefinitionId',
			ruleValidationDefinitionId: 'ruleValidationDefinitionId',
			ruleWorkflowDefinitionId: 'ruleWorkflowDefinitionId',
			resourceIdentifier: 'resourceIdentifier',
			startDate: 'startDate',
			status: 'status',
			subtype: 'subtype',
			subType: 'subType',
			type: 'type',
			typeId: 'typeId',
			userName: 'userName',
			versionId: 'versionId',
			versionNumber: 'versionNumber'
		};

	public static readonly commonTableActions:
	{
		create: string;
		delete: string;
		filter: string;
		filterInput: string;
		tableLevelEllipsis: string;
		update: string;
		view: string;
	} = {
			create: 'create',
			delete: 'delete',
			filter: 'filter',
			filterInput: 'filterInput',
			tableLevelEllipsis: 'tableLevelEllipsis',
			update: 'update',
			view: 'view'
		};

	public static readonly securityRightTypes:
	{
		create: string;
		read: string;
		update: string;
		delete: string;
		execute: string;
	} = {
			create: SecurityRightType.create,
			read: SecurityRightType.read,
			update: SecurityRightType.update,
			delete: SecurityRightType.delete,
			execute: SecurityRightType.execute
		};

	public static readonly commonTableAttributes:
	{
		standardWidgetTableHeight: string;
	} = {
			standardWidgetTableHeight: 'standardWidgetTableHeight'
		};

	public static readonly commonRegex:
	{
		alphaNumericOnly: string;
	} = {
			alphaNumericOnly: '[^a-zA-Z0-9]'
		};

	public static readonly contentAnimation:
	{
		standardAnimation: string;
		translateXZero: string;
		translateXZeroPercent: string;
		translateXHundredPercent: string;
		translateXMinusThreeSixtyPx: string;
		translateXTwoPointFivePercent: string;
		translateXMinusTwoPointFivePercent: string;
		translateXMinusFivePercent: string;
		transitionVisibleHidden: string;
		transitionHiddenVisible: string;
		zeroEaseInOut: string;
	} = {
			standardAnimation: '400ms cubic-bezier(0.86, 0, 0.07, 1)',
			translateXZero: 'translateX(0)',
			translateXZeroPercent: 'translateX(0%)',
			translateXHundredPercent: 'translateX(100%)',
			translateXMinusThreeSixtyPx: 'translateX(-360px)',
			translateXTwoPointFivePercent: 'translateX(2.5%)',
			translateXMinusTwoPointFivePercent: 'translateX(-2.5%)',
			translateXMinusFivePercent: 'translateX(-5%)',
			transitionVisibleHidden: 'visible => hidden',
			transitionHiddenVisible: 'hidden => visible',
			zeroEaseInOut: '0s ease-in-out'
		};

	public static readonly cssIcons:
	{
		ellipsis: string;
		exclamation: string;
		flag: string;
		handDown: string;
		handUp: string;
		history: string;
		info: string;
		infoCircle: string;
		star: string;
		starOpen: string;
		visa: string;
		masterCard: string;
		americanExpress: string;
		discover: string;
		dinersClub: string;
		payPal: string;
		creditCard: string;
		money: string;
		bank: string;
	} = {
			ellipsis: 'fa fa-ellipsis-v',
			exclamation: 'exclamation',
			flag: 'flag',
			handDown: 'hand-o-down',
			handUp: 'hand-o-up',
			history: 'history',
			info: 'info',
			infoCircle: 'info-circle',
			star: 'star',
			starOpen: 'star-o',
			visa: 'cc-visa',
			masterCard: 'cc-mastercard',
			americanExpress: 'cc-amex',
			discover: 'cc-discover',
			dinersClub: 'cc-diners-club',
			payPal: 'cc-paypal',
			creditCard: 'credit-card',
			money: 'money',
			bank: 'bank'
		};

	public static readonly cssClasses:
	{
		active: string;
		customTooltip: string;
		displayNone: string;
		drawer: string;
		entityLayoutMask: string;
		exclamationCircle: string;
		fontAwesomeBell: string;
		fontAwesomeCalendarCheckMark: string;
		fontAwesomeComments: string;
		fontAwesomeFullWidth: string;
		fontAwesomePrefix: string;
		fontAwesomeCalendar: string;
		fontAwesomeEdit: string;
		fontAwesomeInfoCircle: string;
		fontAwesomeHistory: string;
		fontAwesomeHourglass: string;
		fontAwesomeKey: string;
		fontAwesomePencil: string;
		fontAwesomeHouse: string;
		fontAwesomeCar: string;
		fontAwesomeQuestion: string;
		hidden: string;
		invalid: string;
		lineOne: string;
		overlayDrawer: string;
		pAccordionHeaderText: string;
		pButtonOutlined: string;
		pButtonPrimary: string;
		pButtonSecondary: string;
		pButtonDanger: string;
		pContentSmallPhone: string;
		pDropDownLabel: string;
		pinned: string;
		pinnedDrawerOverlay: string;
		productSettingsOverlay: string;
		scrollPanelContent: string;
		scrollPanelHidden: string;
		scrollPanelVerticalScrollBar: string;
		scrollPanelWrapper: string;
		sectionTitle: string;
		sectionTitleText: string;
		selected: string;
		selectedErrorContainerBorder: string;
		sortAscending: string;
		sortDescending: string;
		stonlyWidget: string;
		tabViewTitle: string;
		textSecondaryColor: string;
		textErrorColor: string;
		textWarningColor: string;
		textSuccessColor: string;
		textInfoColor: string;
		visible: string;
		visuallyHidden: string;
		warningColor: string;
		largeFont: string;
	} = {
			active: 'active',
			customTooltip: 'custom-tooltip',
			displayNone: 'display-none',
			drawer: 'drawer',
			entityLayoutMask: '.entity-layout-mask',
			exclamationCircle: 'exclamation-circle',
			fontAwesomeBell: 'fa fa-bell',
			fontAwesomeCalendarCheckMark: 'fa fa-calendar-check-o',
			fontAwesomeComments: 'fa fa-comments-o',
			fontAwesomeFullWidth: 'fa fa-fw ',
			fontAwesomePrefix: 'fa fa-fw fa-',
			fontAwesomeCalendar: 'fa fa-calendar',
			fontAwesomeEdit: 'fa fa-edit',
			fontAwesomeInfoCircle: 'fa fa-info-circle',
			fontAwesomeHistory: 'fa fa-history',
			fontAwesomeHourglass: 'fa fa-hourglass-half',
			fontAwesomeKey: 'fa fa-key',
			fontAwesomePencil: 'fa fa-pencil',
			fontAwesomeHouse: 'fa fa-home',
			fontAwesomeCar: 'fa fa-car',
			fontAwesomeQuestion: 'fa fa-question',
			hidden: 'hidden',
			invalid: 'p-invalid',
			lineOne: 'line1',
			overlayDrawer: 'overlay-drawer',
			pAccordionHeaderText: 'p-accordion-header-text',
			pButtonOutlined: 'p-button-outlined',
			pButtonPrimary: 'p-button-primary',
			pButtonSecondary: 'p-button-secondary',
			pButtonDanger: 'p-button-danger',
			pContentSmallPhone: 'p-content-small-phone',
			pDropDownLabel: 'p-dropdown-label',
			pinned: 'pinned',
			pinnedDrawerOverlay: '.pinned-drawer-overlay',
			productSettingsOverlay: '.product-settings-overlay',
			scrollPanelContent: 'p-scrollpanel-content',
			scrollPanelHidden: 'p-scrollpanel-hidden',
			scrollPanelVerticalScrollBar: 'p-scrollpanel-bar-y',
			scrollPanelWrapper: 'p-scrollpanel-wrapper',
			sectionTitle: 'section-title',
			sectionTitleText: 'section-title-text',
			selected: 'selected',
			selectedErrorContainerBorder: 'selected-error-container-border',
			sortAscending: 'fa fa-sort-amount-asc',
			sortDescending: 'fa fa-sort-amount-desc',
			stonlyWidget: 'stn-wdgt',
			tabViewTitle: 'p-tabview-title',
			textSecondaryColor: 'text-secondary-color',
			textErrorColor: 'text-color-error',
			textWarningColor: 'text-color-warning',
			textSuccessColor: 'text-color-success',
			textInfoColor: 'text-color-info',
			visible: 'visible',
			visuallyHidden: 'visually-hidden',
			warningColor: 'warning-color',
			largeFont: 'font-larger'
		};

	public static readonly cssStyles:
	{
		fitContent: string;
		maxContent: string;
	} = {
			fitContent: 'fit-content',
			maxContent: 'max-content'
		};

	public static readonly cssValues:
	{
		fontFamily: string;
	} = {
			fontFamily: '-apple-system, BlinkMacSystemFont, '
				+ '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, '
				+ '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
		};

	public static readonly contextMenuIdentifiers:
	{
		addRelatedContextMenuIdentifier: string;
		addCreateActionIdentifier: string;
	} = {
			addRelatedContextMenuIdentifier: 'CONTEXTMENU',
			addCreateActionIdentifier: '|CREATE'
		};

	public static readonly dataFormatTypes:
	{
		currency: string;
		decimal: string;
		date: string;
		dateTime: string;
		icon: string;
		longDate: string;
		percent: string;
		shortDate: string;
	} = {
			currency: 'Currency',
			decimal: 'Decimal',
			date: 'Date',
			dateTime: 'DateTime',
			icon: 'Icon',
			longDate: 'LongDate',
			percent: 'Percent',
			shortDate: 'ShortDate'
		};

	public static readonly dataLimits:
	{
		small: number;
		medium: number;
		large: number;
		extraLarge: number;
		maxResultSet: number;
	} = {
			small: 20,
			medium: 50,
			large: 100,
			extraLarge: 1000,
			maxResultSet: 10000
		};

	public static readonly maxRouteDataSize: number = 15000;

	public static readonly dataUrlPrefixes:
	{
		pngImage: string;
	} = {
			pngImage: 'data:image/png;base64,'
		};

	public static readonly differenceTypes:
	{
		array: string;
		date: string;
		object: string;
		property: string;
	} = {
			array: 'Array',
			date: 'Date',
			object: 'Object',
			property: 'Property'
		};

	public static readonly displayComponentTypes:
	{
		basePage: string;
		basePageEntityCreate: string;
		reportPowerBiCustom: string;
		reportPowerBiStandard: string;
	} = {
			basePage: 'BasePage',
			basePageEntityCreate: 'BasePage.Wizard.Entity.Create',
			reportPowerBiCustom: 'Report.PowerBi.Custom',
			reportPowerBiStandard: 'Report.PowerBi.Standard'
		};

	public static readonly displayTypes:
	{
		block: string;
		none: string;
	} = {
			block: 'block',
			none: 'none'
		};

	public static readonly drawerActionLabels:
	{
		add: string;
		save: string;
	} = {
			add: 'Add',
			save: 'Save'
		};

	public static readonly documentElementTypes:
	{
		div: string;
		script: string;
	} = {
			div: 'div',
			script: 'script'
		};

	public static readonly empty: string = '';

	public static readonly emptyArray: string = '[]';

	public static readonly externalReportTypes:
	{
		powerBi: string;
	} = {
			powerBi: 'powerBi'
		};

	public static readonly externalUrls:
	{
		fontAwesomeIcons: string;
	} = {
			fontAwesomeIcons: 'https://fontawesome.com/v4.7.0/icons/'
		};

	public static readonly feeTypes:
	{
		flat: string;
		percent: string;
	} = {
			flat: 'Flat',
			percent: 'Percent'
		};

	public static readonly fileBaseType: string = 'File';

	public static readonly fileBaseTypeCategory: string = 'Uncategorized';

	public static readonly filterQueryOperators:
	{
		equal: string;
		notEqual: string;
		lessThan: string;
		lessOrEqual: string;
		greaterThan: string;
		greaterOrEqual: string;
		and: string;
		or: string;
	} = {
			equal: 'eq',
			notEqual: 'ne',
			lessThan: 'lt',
			lessOrEqual: 'le',
			greaterThan: 'gt',
			greaterOrEqual: 'ge',
			and: 'and',
			or: 'or'
		};

	public static readonly formatTypes:
	{
		beforeCapitalSpaces: string;
		boolean: string;
		currency: string;
		entityTypeDisplay: string;
		longDate: string;
		none: string;
		number: string;
		percent: string;
		shortDate: string;
		yesNoBoolean: string;
	} = {
			beforeCapitalSpaces: 'beforeCapitalSpaces',
			boolean: 'boolean',
			currency: 'currency',
			entityTypeDisplay: 'entityTypeDisplay',
			longDate: 'longDate',
			none: 'none',
			number: 'number',
			percent: 'percent',
			shortDate: 'shortDate',
			yesNoBoolean: 'yesNoBoolean'
		};

	public static readonly formControlStatus:
	{
		disabled: string;
		pending: string;
		valid: string;
		invalid: string;
	} = {
			disabled: 'DISABLED',
			pending: 'PENDING',
			valid: 'VALID',
			invalid: 'INVALID'
		};

	public static readonly httpHeaders:
	{
		queryOnlyGet: string;
	} = {
			queryOnlyGet: 'query-only-get'
		};

	public static readonly httpRequestTypes:
	{
		get: string;
		delete: string;
		patch: string;
		post: string;
		put: string;
		head: string;
	} = {
			get: 'GET',
			delete: 'DELETE',
			patch: 'PATCH',
			post: 'POST',
			put: 'PUT',
			head: 'HEAD'
		};

	public static readonly httpStatusCodes:
	{
		notModified: number;
		preconditionFailed: number;
		unauthorized: number;
	} = {
			notModified: 304,
			preconditionFailed: 412,
			unauthorized: 401
		};

	public static readonly interpolationTypes:
	{
		string: string;
		object: string;
	} = {
			string: '${',
			object: '#{'
		};

	public static readonly jsonTabIndent: number = 4;

	public static readonly keyBoardKeyConstants:
	{
		backspace: string;
		enter: string;
	} = {
			backspace: 'Backspace',
			enter: 'Enter'
		};

	public static readonly layoutBreakpoints:
	{
		smallPhone: number;
		phone: number;
		tablet: number;
		desktop: number;
		widgetfullWidth: number;
		pinnableDrawerWidth: number;
		largeDesktop: number;
	} = {
			smallPhone: 500,
			phone: 640,
			tablet: 800,
			desktop: 1024,
			widgetfullWidth: 1000,
			pinnableDrawerWidth: 1200,
			largeDesktop: 1360
		};

	public static readonly layoutTypes:
	{
		drawer: string;
		full: string;
		generated: string;
		quickQuote: string;
		simple: string;
		summary: string;
		tableExpand: string;
	} = {
			drawer: 'Drawer',
			full: 'Full',
			generated: 'Generated',
			quickQuote: 'QuickQuote',
			simple: 'Simple',
			summary: 'Summary',
			tableExpand: 'TableExpand'
		};

	public static readonly loginStatus:
	{
		invalid: string;
		failure: string;
	} = {
			invalid: 'Invalid',
			failure: 'Failure'
		};

	public static readonly markdownLanguages:
	{
		json: string;
		javascript: string;
		markup: string;
		typescript: string;
		sql: string;
		csharp: string;
	} = {
			json: 'json',
			javascript: 'javascript',
			markup: 'markup',
			typescript: 'typescript',
			sql: 'sql',
			csharp: 'csharp',
		};

	public static readonly messageLevel:
	{
		success: string;
		info: string;
		warn: string;
		error: string;
	} = {
			success: 'success',
			info: 'info',
			warn: 'warn',
			error: 'error'
		};

	public static readonly messages:
	{
		contactSupportForPermissions: string;
		genericErrorMessage: string;
		ownershipNotAllowed: string;
		pageAccessNotAllowed: string;
		pleaseEnterCredentials: string;
		requiredValueMessage: string;
		sessionExpired: string;
		switchCaseNotHandled: string;
	} = {
			contactSupportForPermissions:
			'Please contact support for additional permissions.',
			genericErrorMessage: 'An Error Occurred',
			ownershipNotAllowed: 'The ownership check has failed.',
			pageAccessNotAllowed: 'Page access is not allowed.',
			pleaseEnterCredentials: 'Please enter your credentials to login.',
			requiredValueMessage: 'This value is required.',
			sessionExpired: 'Your session has expired.',
			switchCaseNotHandled:
			'a switch case currently not implemented.'
		};

	public static readonly moduleNames:
	{
		admin: string;
		bi: string;
		dashboard: string;
		organizations: string;
		policy: string;
		profile: string;
		account: string;
	} = {
			admin: 'admin',
			bi: 'bi',
			dashboard: 'dashboard',
			organizations: 'organization',
			policy: 'policy',
			profile: 'profile',
			account: 'account'
		};

	public static readonly navigatorBrowserNames:
	{
		firefox: string;
		chrome: string;
		edge: string;
		safari: string;
		other: string;
	} = {
			firefox: 'Firefox',
			chrome: 'Chrome',
			edge: 'Edg',
			safari: 'Safari',
			other: 'other'
		};

	public static readonly navigatorPermissionStates:
	{
		granted: string;
		denied: string;
		prompt: string;
		unknown: string;
	} = {
			granted: 'granted',
			denied: 'denied',
			prompt: 'prompt',
			unknown: 'unknown'
		};

	public static readonly navigatorPermissionTypes:
	{
		geolocation: PermissionName;
	} = {
			geolocation: 'geolocation'
		};

	public static readonly negativeIndex: number = -1;

	public static readonly nestedDataIdentifier: string = 'data.';

	public static readonly nestedDataKeyPrefix: string = '$.';

	public static readonly nestedRouteTypes:
	{
		actions: string;
		children: string;
		events: string;
		history: string;
		parents: string;
		securityGroups: string;
		users: string;
	} = {
			actions: 'actions',
			children: 'children',
			events: 'events',
			history: 'history',
			parents: 'parents',
			securityGroups: 'securityGroups',
			users: 'users'
		};

	public static readonly noResultsFoundMessage: string = 'No results found.';

	public static readonly parseRadix: number = 10;

	public static readonly paymentMethodTypes:
	{
		ach: string;
		creditCard: string;
	} = {
			ach: 'ACH',
			creditCard: 'CreditCard'
		};

	public static readonly placeholders:
	{
		selectAnOption: string;
		noAvailableOptions: string;
	} = {
			selectAnOption: 'Select An Option',
			noAvailableOptions: 'No Available Options'
		};

	public static readonly primaryOperationGroups:
	{
		primaryNavigation: string;
		profileActions: string;
		settingActions: string;
	} = {
			primaryNavigation: 'PrimaryNavigation',
			profileActions: 'ProfileActions',
			settingActions: 'SettingActions'
		};

	public static readonly propertyTypes:
	{
		boolean: string;
		function: string;
		number: string;
		bigint: string;
		string: string;
		symbol: string;
		array: string;
		object: string;
	} = {
			boolean: 'boolean',
			function: 'function',
			number: 'number',
			bigint: 'bigint',
			string: 'string',
			symbol: 'symbol',
			array: 'array',
			object: 'object'
		};

	public static readonly overlays:
	{
		sidebar: string;
	} = {
			sidebar: 'Sidebar'
		};

	public static readonly overlayLocations:
	{
		topCenter: string;
		topLeft: string;
		topRight: string;
		bottomCenter: string;
		bottomLeft: string;
		bottomRight: string;
	} = {
			topCenter: 'TopCenter',
			topLeft: 'TopLeft',
			topRight: 'TopRight',
			bottomCenter: 'BottomCenter',
			bottomLeft: 'BottomLeft',
			bottomRight: 'BottomRight'
		};

	public static readonly OperationIdentifiers:
	{
		contextMenuModule: string;
	} = {
			contextMenuModule: 'Module.ContextMenu'
		};

	public static readonly operationTypes:
	{
		operationDefinition: string;
		operationGroup: string;
	} = {
			operationDefinition: 'OperationDefinition',
			operationGroup: 'OperationGroup'
		};

	public static readonly regularExpressionFlags:
	{
		global: string;
	} = {
			global: 'g'
		};

	public static readonly responsiveClasses:
	{
		smallPhoneContent: string;
		phoneContent: string;
		tabletContent: string;
		largeTabletContent: string;
		desktopContent: string;
		largeDesktopContent: string;
	} = {
			smallPhoneContent: 'ui-content-small-phone',
			phoneContent: 'ui-content-phone',
			tabletContent: 'ui-content-tablet',
			largeTabletContent: 'ui-content-large-tablet',
			desktopContent: 'ui-content-desktop',
			largeDesktopContent: 'ui-content-large-desktop'
		};

	public static readonly route:
	{
		loginPage: string;
		changePasswordPage: string;
		dashboardPage: string;
		display: string;
		entities: string;
	} = {
			loginPage: 'login',
			changePasswordPage: 'changePassword',
			dashboardPage: 'dashboard',
			display: 'display',
			entities: 'entities'
		};

	public static readonly itemSelectionMode:
	{
		single: string;
		multiple: string;
	} = {
			single: 'single',
			multiple: 'multiple'
		};

	public static readonly ruleActionTypes:
	{
		blocked: number;
		continue: number;
	} = {
			blocked: 1,
			continue: 2
		};

	public static readonly ruleActionTypeNames:
	{
		blocked: string;
		continue: string;
	} = {
			blocked: 'Blocked',
			continue: 'Continue'
		};

	public static readonly securityGroups:
	{
		administratorDisplay: string;
		administrators: string;
		supportUserDisplay: string;
		supportUsers: string;
		userDisplay: string;
		users: string;
		waterStreetStandard: string;
		adminModuleAccess: string;
		biModuleAccess: string;
		claimModuleAccess: string;
		organizationModuleAccess: string;
		policyModuleAccess: string;
		workitemsModuleAccess: string;
	} = {
			administratorDisplay: 'Administrator',
			administrators: 'Administrators',
			supportUserDisplay: 'Support User',
			supportUsers: 'Support Users',
			userDisplay: 'User',
			users: 'Users',
			waterStreetStandard: 'WaterStreetStandard',
			adminModuleAccess: 'Admin_Module_Acess',
			biModuleAccess: 'Bi_Module_Access',
			claimModuleAccess: 'Claim_Module_Access',
			organizationModuleAccess: 'Organization_Module_Access',
			policyModuleAccess: 'Policy_Module_Access',
			workitemsModuleAccess: 'WorkItems_Module_Access'
		};

	public static readonly sizeIdentifiers:
	{
		extraLarge: string;
		large: string;
		medium: string;
		small: string;
	} = {
			extraLarge: 'extra-large',
			large: 'large',
			medium: 'medium',
			small: 'small'
		};

	public static readonly sortDirections:
	{
		ascending: string;
		descending: string;
	} = {
			ascending: 'asc',
			descending: 'desc'
		};

	public static readonly staticLayoutSizes:
	{
		calendarInputIcon: number;
		collapsedContextMenuWidth: number;
		drawerWidth: number;
		expandedContextMenuWidth: number;
		expandedNavigationMenuWidth: number;
		fontSize: number;
		headerBottomRightIconWidth: number;
		helpIconPadding: number;
		helpIconWidth: number;
		operationMenuItemHeight: number;
		mobileHeaderHeight: number;
		nestedContentPadding: number;
		parameterButtonSetHeight: number;
		parameterContainerTitleHeight: number;
		circularChartInformationBottomMargin: number;
		circularChartInformationCardTopMargin: number;
		circularChartSquareCardBottomMargin: number;
		scrollPanelOverlayHeightPercent: number;
		slimMenuWidth: number;
		smallPadding: number;
		standardPadding: number;
		standardCommonTableWidgetHeight: string;
		standardContentPadding: number;
		summaryCardChartBottomMargin: number;
		squareCardChartBottomMargin: number;
		radialGaugeChartBottomPadding: number;
		radialGaugeChartCircumference: number;
		radialGaugeChartInformationCardBottomMargin: number;
		radialGaugeChartRotation: number;
		radialGaugeChartStandardCutOut: string;
		radialGaugeChartTopPadding: number;
		tableSearchHeight: number;
		utilityMenuWidth: number;
		tinyPadding: number;
	} = {
			calendarInputIcon: 32,
			collapsedContextMenuWidth: 35,
			drawerWidth: 360,
			expandedContextMenuWidth: 235,
			expandedNavigationMenuWidth: 235,
			fontSize: 14,
			headerBottomRightIconWidth: 32,
			helpIconPadding: 10,
			helpIconWidth: 35,
			// matches overrides/_layout_variables.scss.
			operationMenuItemHeight: 41,
			mobileHeaderHeight: 45,
			nestedContentPadding: 14,
			parameterButtonSetHeight: 70,
			parameterContainerTitleHeight: 42,
			circularChartInformationBottomMargin: 260,
			circularChartInformationCardTopMargin: -1,
			circularChartSquareCardBottomMargin: 250,
			scrollPanelOverlayHeightPercent: .42,
			slimMenuWidth: 45,
			smallPadding: 3.5,
			standardPadding: 7,
			standardCommonTableWidgetHeight: '235',
			standardContentPadding: 14,
			summaryCardChartBottomMargin: 108, // matches chart.Component.scss
			squareCardChartBottomMargin: 102, // matches chart.Component.scss
			radialGaugeChartBottomPadding: 28,
			radialGaugeChartCircumference: 180,
			radialGaugeChartInformationCardBottomMargin: 240,
			radialGaugeChartRotation: 270,
			radialGaugeChartStandardCutOut: '68%',
			radialGaugeChartTopPadding: 22,
			tableSearchHeight: 55,
			utilityMenuWidth: 35,
			tinyPadding: 1.75
		};

	public static readonly statusIconCssClasses:
	{
		success: string;
		error: string;
		warning: string;
	} = {
			success: 'success-color fa fa-check-circle',
			error: 'error-color fa fa-exclamation-circle',
			warning: 'warning-color fa fa-exclamation-circle',
		};

	public static readonly storage:
	{
		securityTokenKey: string;
		securityUserKey: string;
		securityMultiFactorAuthenticationEnabledKey: string;
		securityMultiFactorAuthenticationMethodKey: string;
		securityExpiryKey: string;
		sessionValidKey: string;
		sessionExpiryKey: string;
		sessionIdKey: string;
		systemSettings: string;
		systemTimeZone: string;
	} = {
			securityTokenKey: 'Root-Security-Token',
			securityUserKey: 'Root-Security-User',
			securityMultiFactorAuthenticationEnabledKey:
				'Root-Security-MultiFactorAuthenticationEnabled',
			securityMultiFactorAuthenticationMethodKey:
				'Root-Security-MultiFactorAuthenticationMethod',
			securityExpiryKey: 'Root-Security-Expiry',
			sessionValidKey: 'Root-Session-Valid',
			sessionExpiryKey: 'Root-Session-Expiry',
			sessionIdKey: 'Root-Session-Id',
			systemSettings: 'System-Settings',
			systemTimeZone: 'System-Time-Zone'
		};

	public static readonly systemEngines:
	{
		workflowEngine: string;
	} = {
			workflowEngine: 'Workflow Engine'
		};

	public static readonly systemId: string = '1';

	public static readonly time:
	{
		fiveMilliseconds: number;
		tenMilliseconds: number;
		twentyFiveMilliseconds: number;
		fiftyMilliseconds: number;
		oneHundredMilliseconds: number;
		quarterSecond: number;
		threeHundredMilliSeconds: number;
		halfSecond: number;
		threeQuarterSecond: number;
		oneSecond: number;
		oneThirdSecond: number;
		twoSeconds: number;
		threeSeconds: number;
		fourSeconds: number;
		fiveSeconds: number;
		oneMinute: number;
		fiveMinutes: number;
		thirtyMinutes: number;
	} = {
			fiveMilliseconds: 5,
			tenMilliseconds: 10,
			twentyFiveMilliseconds: 25,
			fiftyMilliseconds: 50,
			oneHundredMilliseconds: 100,
			quarterSecond: 250,
			threeHundredMilliSeconds: 300,
			halfSecond: 500,
			threeQuarterSecond: 750,
			oneSecond: 1000,
			oneThirdSecond: 333,
			twoSeconds: 2000,
			threeSeconds: 3000,
			fourSeconds: 4000,
			fiveSeconds: 5000,
			oneMinute: 60000,
			fiveMinutes: 300000,
			thirtyMinutes: 1800000
		};

	public static readonly days:
	{
		sevenDays: number;
		thirtyDays: number;
	} = {
			sevenDays: 7,
			thirtyDays: 30
		};

	public static readonly timeSpan:
	{
		zeroMinutes: string;
	} = {
			zeroMinutes: '0:0.0'
		};

	public static readonly maxRetries:
	{
		oneHundred: number;
	} = {
			oneHundred: 100
		};

	public static readonly scriptTypes:
	{
		javascript: string;
	} = {
			javascript: 'text/javascript'
		};

	public static readonly typeGroups:
	{
		companies: string;
		managingGeneralAgencies: string;
		systems: string;
		users: string;
		workItemQueues: string;
	} = {
			companies: 'Test.Organization.CompaniesV2',
			managingGeneralAgencies:
				'Test.Organization.ManagingGeneralAgenciesV2',
			systems: 'Systems',
			users: 'Users',
			workItemQueues: 'WorkItemQueues'
		};

	public static readonly typeNames:
	{
		user: string;
		workItemQueue: string;
	} = {
			user: 'User',
			workItemQueue: 'WorkItemQueue'
		};

	public static readonly undefined: string = 'undefined';

	public static readonly unitsOfMeasurement:
	{
		imperial: string;
		metric: string;
		standard: string;
	} = {
			imperial: 'imperial',
			metric: 'metric',
			standard: 'standard'
		};

	public static readonly urlParameters:
	{
		routeData: string;
	} = {
			routeData: 'routeData'
		};

	public static readonly systems:
	{
		nautix: string;
	} = {
			nautix: 'Nautix'
		};

	public static readonly variableTypes:
	{
		boolean: string;
		date: string;
		string: string;
		number: string;
		object: string;
	} = {
			boolean: 'boolean',
			date: 'Date',
			string: 'string',
			number: 'number',
			object: 'object'
		};

	public static readonly viewTypes:
	{
		view: string;
		edit: string;
		search: string;
		create: string;
		direct: string;
		delete: string;
	} = {
			view: 'view',
			edit: 'edit',
			search: 'search',
			create: 'create',
			direct: 'direct',
			delete: 'delete'
		};

	public static readonly windowTargets:
	{
		blank: string;
	} = {
			blank: '_blank'
		};

	public static readonly webApi:
	{
		authorizationKey: string;
		tokenKey: string;
		multiFactorKey: string;
		tokenExpiryKey: string;
		eTag: string;
		ifMatch: string;
		ifNoneMatch: string;
	} = {
			authorizationKey: 'authorization',
			tokenKey: 'token',
			multiFactorKey: 'multi-factor',
			tokenExpiryKey: 'token-expiry',
			eTag: 'etag',
			ifMatch: 'if-match',
			ifNoneMatch: 'if-none-match'
		};

	public static readonly workflowActions:
	{
		create: string;
		externalReportConfiguration: string;
		fileDownload: string;
		fileUpload: string;
		killAllSessions: string;
		reallySimpleSyndicationFeed: string;
		runBusinessRules: string;
	} = {
			create: 'create',
			externalReportConfiguration: 'ExternalReportConfiguration',
			fileDownload: 'FileDownload',
			fileUpload: 'FileUpload',
			killAllSessions: 'KillAllSessions',
			reallySimpleSyndicationFeed: 'ReallySimpleSyndicationFeed',
			runBusinessRules: 'RunBusinessRules'
		};

	public static readonly workflowRequisiteTypes:
	{
		preRequisite: string;
		postRequisite: string;
	} = {
			preRequisite: 'PreRequisite',
			postRequisite: 'PostRequisite'
		};

	public static readonly displayMode:
	{
		view: string;
		create: string;
		update: string;
		delete: string;
		list: string;
		timeline: string;
		keyDates: string;
		secondaryList: string;
		secondaryView: string;
	} = {
			view: 'View',
			create: 'Create',
			update: 'Update',
			delete: 'Delete',
			list: 'List',
			timeline: 'Timeline',
			keyDates: 'KeyDates',
			secondaryList: 'SecondaryList',
			secondaryView: 'SecondaryView'
		};

	public static readonly mutationRecordTypes:
	{
		childList: string;
	} =	{
			childList: 'childList'
		};

	/**
	 * Gets the class based identifiers used for form control overlays
	 * that will ensure these are handled accurately for display.
	 * @note p-calendar, p-dropdown, p-multiselect, and third party modal
	 * cases are covered.
	 *
	 * @type {string[]}
	 * @memberof AppConstants
	 */
	public static readonly clickOutsidePanelControl: string[] =
		[
			'p-calendar',
			'p-datepicker-buttonbar',
			'p-datepicker-calendar',
			'p-datepicker-group-container',
			'p-datepicker-group',
			'p-datepicker-header',
			'p-dropdown-filter',
			'p-dropdown-filter-icon',
			'p-dropdown-header',
			'p-dropdown-item',
			'p-dropdown-items',
			'p-multiselect-filter',
			'p-multiselect-filter-icon',
			'p-multiselect-header',
			'p-multiselect-item',
			'p-multiselect-items',
			'stn-wdgt',
			'stn-widget-buttons-wrapper',
			'stn-widget-content',
			'stn-widget-loader',
			'stn-widget-wrapper'
		];

	/**
	 * Gets the wildcard identifier (".*").
	 *
	 * @type {string}
	 * @memberof AppConstants
	 */
	public static readonly wildcardIdentifier: string =
		this.characters.period + this.characters.asterisk;

	/**
	 * Gets the allowed passwords special characters.
	 *
	 * @type {string}
	 * @memberof AppConstants
	 */
	public static readonly passwordSpecialCharacters: string =
		'[#*$-+?_&=!%{}/]';
}