/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IChartContext
} from '@shared/interfaces/dynamic-interfaces/chart-context.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	ChartTransform
} from '@shared/transforms/chart-transform';
import {
	ChartConfiguration,
	ChartData,
	ChartDataset
} from 'chart.js';
import 'chartjs-adapter-luxon';
import {
	Subject,
	debounceTime
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: [
		'./chart.component.scss'
	]
})

/**
 * A component representing an instance of a chart display.
 * @note This component utilizes ChartJs and further information
 * on the available API can be found at:
 * https://www.chartjs.org/docs/latest/
 *
 * @export
 * @class ChartComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
export class ChartComponent implements OnInit, OnChanges, OnDestroy
{
	/**
	 * Initializes a new instance of a chart display component.
	 *
	 * @param {ChartTransform} chartTransform
	 * The class used to transform existing chart configurations into
	 * desired outputs.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used to define responsive
	 * layout variables.
	 * @memberof ChartComponent
	 */
	public constructor(
		public chartTransform: ChartTransform,
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 * If sent this will override the input parameter values.
	 *
	 * @type {IDynamicComponentContext<Component, IChartContext}
	 * @memberof ChartComponent
	 */
	@Input() public context:
		IDynamicComponentContext<Component, IChartContext<IAggregate[]>>;

	/**
	 * Gets or sets a truthy defining if this chart will be displayed in
	 * a summary card.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	@Input() public summaryCardDisplay: boolean;

	/**
	 * Gets or sets a truthy defining if this chart will be displayed in
	 * a info chart.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	@Input() public squareCardDisplay: boolean;

	/**
	 * Gets or sets a boolean signifying if this chart should streth to fill
	 * content or maintain an aspect ratio.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	@Input() public maintainAspectRatio: boolean = true;

	/**
	 * Gets or sets the chart configuration displayed in this component.
	 *
	 * @type {ChartConfiguration}
	 * @memberof ChartComponent
	 */
	@Input() public chartConfiguration: ChartConfiguration;

	/**
	 * The property key based value to display in the charts y value. This will
	 * be the x pivot property. Note: Time based pivots are not required.
	 *
	 * @type {string}
	 * @memberof ChartComponent
	 */
	@Input() public pivotProperty: string;

	/**
	 * The group of multiple pivot properties to display in the charts values.
	 * This is required when multiple properties/values are to be
	 * displayed in the chart.
	 *
	 * @type {string}
	 * @memberof ChartComponent
	 */
	@Input() public pivotProperties: string[];

	/**
	 * The property key based value to display in the charts y value. This will
	 * be the x pivot property. Note: Time based pivots are not required.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	@Input() public radialGaugeChart: boolean;

	/**
	 * Gets or sets the data promise used to gather data to display in
	 * this chart.
	 *
	 * @type {Promise<IAggregate[]>}
	 * @memberof ChartComponent
	 */
	@Input() public dataPromise: Promise<IAggregate[]>;

	/**
	 * Gets or sets the array of colors used for this component.
	 * The available definitions are found in ChartConstants.themeColors.
	 *
	 * @type {string[]}
	 * @memberof ChartComponent
	 */
	@Input() public chartColors: string[] = ['primary'];

	/**
	 * Gets or sets the truthy defining if this component should fill
	 * missing data sets. This value will alter line charts from a
	 * trend line to a data value point line via filling in missing
	 * data with zero values.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	@Input() public fillMissingDataSets: boolean = true;

	/**
	 * Gets or sets the truthy defining if this component should display
	 * a count grouping. This is used for count values grouped by
	 * distinct properties.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	@Input() public groupByCount: boolean = false;

	/**
	 * Gets or sets the data value directly as opposed to utilizing
	 * the existing data promise. This overload allows for direct
	 * data interaction.
	 *
	 * @type {IAggregate[]}
	 * @memberof ChartComponent
	 */
	@Input() public data: IAggregate[];

	/**
	 * Gets or sets the chart container div element reference.
	 *
	 * @type {ElementRef}
	 * @memberof ChartComponent
	 */
	@ViewChild('ChartContainer') public chartContainer: ElementRef;

	/**
	 * Gets or sets the color swatch element reference.
	 *
	 * @type {ElementRef}
	 * @memberof ChartComponent
	 */
	@ViewChild('ColorSwatch') public colorSwatch: ElementRef;

	/**
	 * Gets or sets a truthy defining if a data promise is currently being
	 * loaded.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a storage variable used to hold chart data until it is fully
	 * configured and ready for display.
	 *
	 * @type {ChartData}
	 * @memberof ChartComponent
	 */
	public chartData: ChartData;

	/**
	 * Gets or sets a boolean signifying if a no data value output should
	 * display.
	 *
	 * @type {boolean}
	 * @memberof ChartComponent
	 */
	public displayMissingDataMessage: boolean = false;

	/**
	 * Gets or sets the observer that will capture display configured values
	 * debounced.
	 *
	 * @type {Subject<void>}
	 * @memberof ChartComponent
	 */
	public displayConfigured: Subject<void> = new Subject();

	/**
	 * Gets the delay between chart redraws to ensure that animations
	 * remain smooth and only display desired configured outputs.
	 *
	 * @type {number}
	 * @memberof ChartComponent
	 */
	private readonly changeDebounceDelay: number = 500;

	/**
	 * Handles the theme change event.
	 * This redraws the chart via updated colors to match the
	 * current theme.
	 *
	 * @memberof ChartComponent
	 */
	@HostListener(
		AppEventConstants.themeChangedEvent)
	public themeChanged(): void
	{
		this.displayConfigured.next();
	}

	/**
	 * Handles the on initialization event.
	 * This will set the display value correctly for it's desired output
	 * and set up a debounce subscription for drawing configuration
	 * and data changes.
	 *
	 * @async
	 * @memberof ChartComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setChartContext();

		if (this.summaryCardDisplay === true)
		{
			this.chartConfiguration.options =
				await this.chartTransform.toSummaryChart(
					this.chartConfiguration,
					this.radialGaugeChart);
		}

		if (this.radialGaugeChart === true
			&& this.summaryCardDisplay !== true)
		{
			this.chartConfiguration.options =
				await this.chartTransform.toRadialGaugeChart(
					this.chartConfiguration);
		}

		if (this.squareCardDisplay === true)
		{
			this.chartConfiguration.options =
				await this.chartTransform.toSquareChart(
					this.chartConfiguration);
		}

		this.displayConfigured.pipe(
			debounceTime(this.changeDebounceDelay))
			.subscribe(
				async() =>
				{
					if (AnyHelper.isNull(this.colorSwatch))
					{
						this.displayConfigured.next();

						return;
					}

					this.chartConfiguration.data =
						this.chartData;

					const xAxes: any =
						this.chartConfiguration.options.scales?.x;

					this.chartConfiguration.data =
						await this.chartTransform.toThemeColor(
							this.chartConfiguration,
							this.chartColors,
							this.chartConfiguration.type ===
								ChartConstants.chartTypes.pie
								|| this.chartConfiguration.type ===
									ChartConstants.chartTypes.doughnut
								|| (this.chartConfiguration.type ===
									ChartConstants.chartTypes.bar
									&& xAxes?.stacked !== true)
								? 0
								: null);

					this.displayMissingDataMessage =
						(this.chartConfiguration.type ===
							ChartConstants.chartTypes.pie
							|| this.chartConfiguration.type ===
								ChartConstants.chartTypes.doughnut)
							&& this.chartConfiguration.data.datasets.filter(
								(dataSet: ChartDataset) =>
								{
									for (const item of dataSet.data)
									{
										if (parseInt(
											item.toString(),
											AppConstants.parseRadix) > 0)
										{
											return true;
										}
									}

									return false;
								})
								.length === 0;

					this.chartData =
						this.chartTransform
							.cloneChartData(
								this.chartConfiguration);

					this.loading = false;
					this.changeDetector.detectChanges();
				});

		// Handle cases of stored configuration properties.
		if (this.chartConfiguration.data?.datasets[0]?.data?.length > 0)
		{
			setTimeout(
				() =>
				{
					if (this.loading === true
						&& !AnyHelper.isNull(this.colorSwatch))
					{
						this.loading = false;
						this.changeDetector.detectChanges();
					}
				},
				AppConstants.time.threeQuarterSecond);
		}
	}

	/**
	 * Handles the on changes event.
	 * This will watch for data promise and direct data input value changes
	 * and update the chart data appropriately along with a redraw.
	 *
	 * @async
	 * @memberof ChartComponent
	 */
	public async ngOnChanges(
		changes: SimpleChanges): Promise<void>
	{
		if (changes.dataPromise?.currentValue
			!==	changes.dataPromise?.previousValue
			|| changes.data?.currentValue
				!== changes.data?.previousValue)
		{
			this.loading = true;

			if (!AnyHelper.isNull(changes.dataPromise))
			{
				this.dataPromise.then(
					async(data: IAggregate[]) =>
					{
						this.handleChartData(data);
					});
			}
			else
			{
				this.handleChartData(this.data);
			}
		}
	}

	/**
	 * Handles the on destroy event.
	 * This will complete any existing display configured observers
	 * and free memory.
	 *
	 * @memberof ChartComponent
	 */
	public ngOnDestroy(): void
	{
		this.displayConfigured.complete();
	}

	/**
	 * Handles setting local parameter input values from a dynamic component
	 * context if sent and initializing chart based inputs.
	 * This will fall back to local default values if not sent.
	 *
	 * @memberof ChartComponent
	 */
	public async setChartContext(): Promise<void>
	{
		if (!AnyHelper.isNull(this.context))
		{
			this.loading = true;

			const chartContext: IChartContext<IAggregate[]> =
				this.context.data;

			const chartDefinition: IChartDefinition<IAggregate[]> =
				this.context.data.chartDefinition;

			this.summaryCardDisplay =
				chartContext.summaryCardDisplay;
			this.squareCardDisplay =
				chartContext.squareCardDisplay;
			this.maintainAspectRatio =
			chartContext.maintainAspectRatio === true
				|| (chartContext.summaryCardDisplay === false
					&& chartContext
						.chartDefinition.chartConfiguration.type !==
							ChartConstants.chartTypes.pie
					&& chartContext
						.chartDefinition.chartConfiguration.type !==
							ChartConstants.chartTypes.doughnut);
			this.fillMissingDataSets =
				chartContext.fillMissingDataSets;

			this.chartColors =
				chartDefinition.chartColors;
			this.chartConfiguration =
				chartDefinition.chartConfiguration;
			this.dataPromise =
				chartDefinition.dataPromise;
			this.groupByCount =
				chartDefinition.groupByCount;
			this.pivotProperty =
				chartDefinition.chartPivotProperty;

			this.dataPromise.then(
				async (data: IAggregate[]) =>
				{
					this.data = [
						...chartContext.data || [],
						...data
					];

					await this.handleChartData(this.data);
				});
		}

		this.chartConfiguration.options
			.maintainAspectRatio =
				this.maintainAspectRatio;
	}

	/**
	 * Handles the input of chart data via calculating and grouping
	 * available data into a chart display. This will set the display
	 * mode to label only until all configurations are complete and
	 * ready to be drawn.
	 *
	 * @async
	 * @memberof ChartComponent
	 */
	private async handleChartData(
		data: IAggregate[]): Promise<void>
	{
		this.chartData =
			await this.chartTransform
				.handleAggregateData(
					this.chartConfiguration,
					data,
					this.pivotProperty,
					this.pivotProperties,
					this.radialGaugeChart,
					this.groupByCount,
					this.fillMissingDataSets);

		this.chartConfiguration.data =
			{
				labels: this.chartData.labels,
				datasets: this.chartConfiguration.data.datasets
			};

		this.displayConfigured.next();
		this.changeDetector.detectChanges();
	}
}