/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HttpEvent
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	IQueryParameters
} from '@api/interfaces/common/queryparameters.interface';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	IEntityTypeDto
} from '@api/interfaces/entities/entity-type.dto.interface';
import {
	IActionResponseDto
} from '@api/interfaces/workflow/action-response.dto.interface';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FileCategory
} from '@dynamicComponents/files/file-category/file-category';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EmbeddedFileEncoding
} from '@shared/constants/enums/embedded-file-encoding.enum';
import {
	FileProgressType
} from '@shared/constants/enums/file-progress-type.enum';
import {
	FileState
} from '@shared/constants/enums/file-state.enum';
import {
	StorageType
} from '@shared/constants/enums/storage-type.enum';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	IFileProgress
} from '@shared/interfaces/files/file-progress.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	FileProgressReporter
} from '@shared/services/files/file-progress-reporter';
import {
	Observable,
	Subscriber
} from 'rxjs';

/**
 * A class representing the logic and services for files.
 *
 * @export
 * @class
 */
@Injectable(
	{
		providedIn: 'root'
	}
)
export class FileService
{
	/**
	 * Initializes a enw instance of this class.
	 *
	 * @param {EntityInstanceApiService} instanceService
	 * Provides access to the Entity Instances.
	 * @param {EntityTypeApiService} typeApiService
	 * Provides access to the entity types.
	 * @memberof FileService
	 */
	public constructor(
		public instanceService: EntityInstanceApiService,
		private readonly typeApiService: EntityTypeApiService,
		private readonly definitionApiService: EntityDefinitionApiService)
	{
	}

	/**
	 * The default entity instance type group.
	 *
	 * @type {string}
	 * @memberof FileService
	 */
	public defaultTypeGroup: 'Files';

	/**
	 * The default entity instance type.
	 *
	 * @type {string}
	 * @memberof FileService
	 */
	public defaultType: string = AppConstants
		.fileBaseType;

	/**
	 * Uploads a new file and creates a file entity instance.
	 *
	 * @param {StorageType} storageType
	 * The type of storage for the file.
	 * @param {number} parentId
	 * The id for the parent entity.
	 * @param {string} parentTypeGroup
	 * The entity type gorup of the parent.
	 * @param {string} referenceUrl
	 * The url to the file being refernced.
	 * @param {EmbeddedFileEncoding} encoding
	 * The type of encoding to use if embedding the file.
	 * @param {File} file
	 * The file to upload.
	 * @param {string} name
	 * Optional new file name.
	 * @param {string} description
	 * An optional file description.
	 * @param {string} fileEntityType
	 * The entity instance type of the file instance to create.
	 * @param {string} fileEntityGroup
	 * The entity instance type group of the file instance to create.
	 * @param {string} fileSubType
	 * The sub type of the file instance to create.
	 * @param {FileState} fileState
	 * The file state of the file instance to create.
	 * @returns {Observable<IFileProgress>}
	 * A observable progress report of the operation, including any returned
	 * values, headers, body, statuses, etc...
	 * @memberof FileService
	 */
	public addNewFile(
		storageType: StorageType,
		parentId?: number,
		parentTypeGroup?: string,
		referenceUrl?: string,
		encoding?: EmbeddedFileEncoding,
		file?: File,
		name?: string,
		description?: string,
		fileEntityType: string = this.defaultType,
		fileEntityGroup: string = this.defaultTypeGroup,
		fileSubType: string = null,
		fileState: FileState = FileState.Active):
		Observable<IFileProgress>
	{
		const entityName: string = AnyHelper
			.isNullOrEmpty(name)
			? file?.name
			: name;

		const fileInstance: IEntityInstanceDto =
			this.generateEntity(
				storageType,
				entityName,
				description,
				referenceUrl,
				encoding,
				fileEntityType,
				fileSubType,
				fileState);

		let formData: FormData = new FormData();
		if (!AnyHelper.isNullOrEmpty(file))
		{
			formData
				.append(
					'newFile',
					file);
		}
		else
		{
			formData = null;
		}

		return new Observable<IFileProgress>(
			(progressSubscriber: Subscriber<IFileProgress>) =>
			{
				progressSubscriber.next(
					<IFileProgress>
					{
						type: FileProgressType.CreatingEntity,
						message: 'Creating file entity'
					});

				this.instanceService
					.entityInstanceTypeGroup = fileEntityGroup;

				this.instanceService
					.createEntityInstance(
						fileInstance,
						parentTypeGroup,
						parentId)
					.then(
						(id: number) =>
						{
							progressSubscriber
								.next(
									<IFileProgress>
									{
										type: FileProgressType.EntityCreated,
										message: 'File entity created',
										value: id
									});

							const reporter: FileProgressReporter =
								new FileProgressReporter();

							this.instanceService
								.entityInstanceTypeGroup = fileEntityGroup;

							this.instanceService
								.uploadFile(
									id,
									formData)
								.subscribe(
									{
										next(event:
											HttpEvent<IActionResponseDto>)
										{
											reporter.reportEvent(
												event,
												progressSubscriber);
										},
										async error(error: any)
										{
											progressSubscriber.next(
												<IFileProgress>
												{
													type:
														FileProgressType.Error,
													message:
														'An error occured. '
															+ 'See value.',
													value: error
												});
											progressSubscriber.complete();
											progressSubscriber.unsubscribe();
										},
										complete()
										{
											if (!progressSubscriber.closed)
											{
												progressSubscriber.next(
													<IFileProgress>
													{
														type:
															FileProgressType
																.Complete,
														message:
															'File action '
																+ 'completed '
																+ 'successfully'
													});
												progressSubscriber
													.complete();
												progressSubscriber
													.unsubscribe();
											}
										}
									});
						})
					.catch(error =>
					{
						progressSubscriber.next(
						<IFileProgress>
						{
							type: FileProgressType.Error,
							message: 'Error creating entity. No file was '
								+ 'uploaded. See value for error.',
							value: error
						});

						progressSubscriber.complete();
						progressSubscriber.unsubscribe();
					});
			});
	}

	/**
	 * Downloads a file.
	 *
	 * @param {number} id
	 * The file id.
	 * @param {string} typeGroup
	 * The entity type group of the file.
	 * @param {string} downloadAction
	 * The download action name.
	 * @returns {Observable<HttpEvent<Blob>>}
	 * An observable of Http events which, if successful, will
	 * complete with a response event containing a file.
	 * @memberof FileService
	 */
	public download(
		id: number,
		typeGroup: string,
		downloadAction: string = AppConstants
			.workflowActions.fileDownload): Observable<HttpEvent<Blob>>
	{
		this.instanceService
			.entityInstanceTypeGroup = typeGroup;

		return this.instanceService
			.downloadFile(
				id,
				downloadAction);
	}

	/**
	 * Gets a list of all available file type.
	 *
	 * @returns {Promise<IEntityType[]>}
	 * The resulting set of entity types.
	 * @memberof FileService
	 */
	public async getSupportedFileTypes(
		fileParentEntityDefinition: IEntityDefinition): Promise<IEntityType[]>
	{
		const supportedTypes: string[] =
			new EntityDefinition(fileParentEntityDefinition)
				.supportedChildTypes
				.filter(
					(type: string) =>
						type === AppConstants.fileBaseType
						|| type.startsWith(`${AppConstants.fileBaseType}.`));

		const fileEntityTypes: IEntityType[] = await  this.typeApiService
			.query(
				`name.StartsWith('${AppConstants.fileBaseType}') eq true`,
				'name');

		return fileEntityTypes
			.filter(
				(entityType: IEntityType) =>
					supportedTypes.includes(entityType.name));
	}

	/**
	 * Gets the categories/types the user is authorized to access
	 * (read and download).
	 *
	 * @param {number} parentId
	 * the files parent.
	 * @param {string} parentTypeGroup
	 * the files parent type group.
	 * @returns {Promise<FileCategory[]>}
	 * The file categories the user is authorized to read and download.
	 * @memberof FileService
	 */
	public async getAuthorizedCategories(
		parentId: number,
		parentTypeGroup: string,
		fileParentEntityDefinition: IEntityDefinition): Promise<FileCategory[]>
	{
		const categories: FileCategory[] = [];

		const permissions: ISecurityEntityTypeDefinition[] =
			await this.getSupportedFilePermissions(
				parentId,
				parentTypeGroup,
				fileParentEntityDefinition);

		if (AnyHelper.isNullOrEmptyArray(permissions))
		{
			return categories;
		}

		const typeFilter: string =
			ApiFilterHelper.getEnumerationFilter(
				AppConstants.commonProperties.typeId,
				permissions.map(
					(permission: ISecurityEntityTypeDefinition) =>
						`${permission.entityTypeId}`));

		const definitions: EntityDefinition[] =
			(await this.definitionApiService
				.query(
					typeFilter,
					'Id'))
				.map(dto => new EntityDefinition(dto));

		permissions
			.forEach((permission: ISecurityEntityTypeDefinition) =>
			{
				const matchingDefinition: EntityDefinition =
					definitions.find(item =>
						item.typeId === permission.entityTypeId);

				categories
					.push(
						new FileCategory(
							<IEntityTypeDto>
							{
								id: permission.entityTypeId,
								name: permission.entityTypeName,
								group: permission.entityTypeGroup
							},
							matchingDefinition,
							permission));
			});

		return Promise
			.resolve(categories);
	}

	/**
	 * Gets the categories/types the user is authorized to access
	 * (read and download).
	 *
	 * @param {number} parentId
	 * the files parent.
	 * @param {string} parentTypeGroup
	 * the files parent type group.
	 * * @param {IEntityDefinition} entityDefinition
	 * The parent entity definition.
	 * @returns {Promise<ISecurityEntityTypeDefinition[]>}
	 * The file categories the user is authorized to read and download.
	 * @memberof FileService
	 */
	public async getSupportedFilePermissions(
		parentId: number,
		parentTypeGroup: string,
		entityDefinition: IEntityDefinition):
		Promise<ISecurityEntityTypeDefinition[]>
	{
		const availableTypes: IEntityType[] =
			await this.getSupportedFileTypes(entityDefinition);

		this.instanceService.entityInstanceTypeGroup = parentTypeGroup;

		const permissions: ISecurityEntityTypeDefinition[] =
			await this.instanceService
				.getHierarchyPermissions(
					parentId,
					availableTypes.map(
						(entityType: IEntityType) =>
							entityType.group));

		return permissions
			.filter(
				(definition: ISecurityEntityTypeDefinition) =>
					definition.entityTypeName === AppConstants.fileBaseType
					|| definition.entityTypeName
						.startsWith(`${AppConstants.fileBaseType}.`))
			.map(permission =>
				<any>
				{
					...permission,
					entityType: availableTypes
						.find((entityType: IEntityType) =>
							entityType.id === permission.entityTypeId)
				});
	}

	/**
	 * Gets a list of all files.
	 *
	 * @param {string} filter
	 * A string representing the filter for the query.
	 * @param {string} orderBy
	 * A string representing the order to list the files.
	 * @param {number} limit
	 * A number representing the top limit count.
	 * @param {number} offset
	 * A number representing the skip offset (used for paging).
	 * @param {number} last
	 * A number representing the last count.
	 * @param {number} parentId
	 * The id of the parent entity that the file belongs to.
	 * @param {string} fileTypeGroup
	 * The type group of the file. The defaut is "Files."
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * The resulting file instances form the query.
	 * @memberof FileService
	 */
	public getFiles(
		parameters: IQueryParameters,
		parentId: number,
		parentTypeGroup: string,
		fileTypeGroups?: string[]): Observable<IEntityInstanceDto[]>
	{
		this.instanceService
			.entityInstanceTypeGroup = parentTypeGroup;

		if (AnyHelper.isNull(fileTypeGroups))
		{
			return this.getFilesOfAllTypes(
				parameters,
				parentId,
				parentTypeGroup);
		}

		const observable: Observable<IEntityInstanceDto[]> =
			new Observable<IEntityInstanceDto[]>(
				(subscriber: Subscriber<IEntityInstanceDto[]>) =>
				{
					let count: number = 0;

					fileTypeGroups
						.forEach(
							(typeGroup: string) =>
							{
								this.instanceService
									.getChildren(
										parentId,
										parameters.filter,
										parameters.orderBy,
										parameters.offset,
										parameters.limit,
										typeGroup)
									.then(
										(instances: IEntityInstanceDto[]) =>
										{
											subscriber.next(instances);
										})
									.catch(
										(error: any) =>
										{
											subscriber.error(error);
										})
									.finally(
										() =>
										{
											if (++count ===
												fileTypeGroups.length)
											{
												subscriber.complete();
												subscriber.unsubscribe();
											}
										});
							});
				});

		return observable;
	}

	/**
	 * Update a file.
	 *
	 * @param  {IFileEntity} modifiedEntity
	 * The modified entity.
	 * @param {string} typeGroup
	 * The entity type group.
	 * @param {File} file
	 * An optional replacement file
	 * @param {boolean} storageTypeChanged
	 * An optional value indicating whetherthe storage type has changed.
	 * @param {boolean} fileChanged
	 * An optional value indicating whetherthe storage type has changed.
	 * @memberof FileService
	 * @returns {Observable<IFileProgress>}
	 * An observable of file progress with http events.
	 */
	public updateFile(
		modifiedEntity: IFileEntity,
		typeGroup: string,
		file?: File,
		storageTypeChanged: boolean = false,
		fileChanged: boolean = false): Observable<IFileProgress>
	{
		const successMessage: string =
			'File action completed successfully';
		let formData: FormData = new FormData();

		if (!AnyHelper.isNullOrEmpty(file))
		{
			formData
				.append(
					'updateFile',
					file);
		}
		else
		{
			formData = null;
		}

		return new Observable<IFileProgress>(
			(progressSubscriber: Subscriber<IFileProgress>) =>
			{
				progressSubscriber.next(
				<IFileProgress>
				{
					type: FileProgressType.UpdatingEntity,
					message: 'Updating file Data.'
				});

				this.instanceService
					.entityInstanceTypeGroup = typeGroup;

				this.instanceService
					.update(
						modifiedEntity.id,
						<IEntityInstanceDto>
						{
							id: modifiedEntity.id,
							resourceIdentifier: modifiedEntity
								.resourceIdentifier,
							versionNumber: modifiedEntity.versionNumber,
							data: modifiedEntity.data,
							entityType: modifiedEntity.entityType,
						})
					.then(
						(response: object) =>
						{
							progressSubscriber
								.next(
									<IFileProgress>
									{
										type: FileProgressType.EntityUpdated,
										message: 'File data updated.',
										value: response
									});

							const reporter: FileProgressReporter =
								new FileProgressReporter();

							if (!fileChanged
								&& !storageTypeChanged)
							{
								progressSubscriber.next(
									<IFileProgress>
									{
										type: FileProgressType.Complete,
										message: successMessage
									});
								progressSubscriber.complete();
								progressSubscriber.unsubscribe();
							}
							else
							{
								const instanceService:
									EntityInstanceApiService =
										this.instanceService;
								this.instanceService
									.entityInstanceTypeGroup = typeGroup;
								this.instanceService
									.uploadFile(
										modifiedEntity.id,
										formData)
									.subscribe(
										{
											next(event: HttpEvent<
												IActionResponseDto>)
											{
												reporter.reportEvent(
													event,
													progressSubscriber);
											},
											error(error: any)
											{
												progressSubscriber.next(
													<IFileProgress>
													{
														type:
															FileProgressType
																.Error,
														message:
															'An error occured. '
																+ 'See value.',
														value: error
													});

												progressSubscriber
													.complete();

												progressSubscriber
													.unsubscribe();
											},
											complete()
											{
												// Refresh the cache for this
												// uploaded instance.
												instanceService
													.entityInstanceTypeGroup =
														typeGroup;
												instanceService
													.get(
														modifiedEntity.id);

												if (!progressSubscriber.closed)
												{
													progressSubscriber.next(
														<IFileProgress>
														{
															type:
																FileProgressType
																	.Complete,
															message:
																successMessage
														});

													progressSubscriber
														.complete();
													progressSubscriber
														.unsubscribe();
												}
											}
										});
							}
						})
					.catch(error =>
					{
						progressSubscriber.next(
							<IFileProgress>
							{
								type: FileProgressType.Error,
								message: 'Error modifying entity. '
									+ 'See value for error.',
								value: error
							});

						progressSubscriber.complete();
						progressSubscriber.unsubscribe();
					});
			});
	}

	/**
	 * Changes a file's status to "Removed"
	 *
	 * @param {IFileEntity} fileEntity
	 * the file entity to remove.
	 * @param {string} reason
	 * The reason for the removal.
	 * @param {string} typeGroup
	 * The entity type group.
	 * @returns {Observable<IFileProgress>}
	 * An observable that reporst the removal progress.
	 * @memberof FileService
	 */
	public remove(
		fileEntity: IFileEntity,
		reason: string,
		typeGroup: string): Observable<IFileProgress>
	{
		fileEntity.data
			.status
			.state = FileState.Removed;

		fileEntity.data
			.status
			.stateChangeReason = reason;

		return this.updateFile(
			fileEntity,
			typeGroup);
	}

	/**
	 * Gets all file entity types (categories) for supplied parent.
	 *
	 * @param {IQueryParameters} parameters
	 * The query parameters for the search.
	 * @param {number} parentId
	 * The id of the file's parent entity
	 * @param {string} parentTypeGroup
	 * The entity type group of the file's parent.
	 * @memberof FileService
	 */
	private getFilesOfAllTypes(
		parameters: IQueryParameters,
		parentId: number,
		parentTypeGroup: string): Observable<IEntityInstanceDto[]>
	{
		this.instanceService
			.entityInstanceTypeGroup = parentTypeGroup;

		return new Observable<IEntityInstanceDto[]>(
			(subscriber: Subscriber<IEntityInstanceDto[]>) =>
			{
				this.instanceService
					.getChildren(
						parentId,
						parameters.filter,
						parameters.orderBy,
						parameters.offset,
						parameters.limit,
						null)
					.then(
						(instances: IEntityInstanceDto[]) =>
						{
							subscriber.next(instances);
						})
					.catch(
						(error: any) =>
						{
							subscriber.error(error);
						})
					.finally(
						() =>
						{
							subscriber.complete();
							subscriber.unsubscribe();
						});
			});
	}

	/**
	 * Generates a file entity instance.
	 *
	 * @param {StorageType} storageType
	 * The type of storage for the file.
	 * @param {string} name
	 * Optional new file name.
	 * @param {string} description
	 * An optional file description.
	 * @param {string} referenceUrl
	 * The url to the file being refernced.
	 * @param {EmbeddedFileEncoding} encoding
	 * The type of encoding to use if embedding the file.
	 * @param {string} fileEntityType
	 * The entity instance type of the file instance to create.
	 * @param {string} subType
	 * The subtype of the file instance to create.
	 * @param {FileState} fileState
	 * The file state of the file instance to create.
	 * @returns {IEntityInstanceDto}
	 * An instance of the file type requested.
	 * @memberof FileService
	 */
	private generateEntity(
		storageType: StorageType,
		name: string,
		description: string,
		referenceUrl?: string,
		encoding?: EmbeddedFileEncoding,
		fileEntityType: string = this.defaultType,
		subType: string = null,
		fileState: FileState = FileState.Active): IEntityInstanceDto
	{
		switch (storageType)
		{
			case StorageType.Persisted:
				return <IEntityInstanceDto>
				{
					id: 0,
					versionNumber: 1,
					data: {
						name: name,
						subType: subType,
						description: description,
						status: {
							state: fileState
						},
						storage: {
							storageType: storageType
						}
					},
					entityType: fileEntityType
				};
			case StorageType.Embedded:
				return <IEntityInstanceDto>
				{
					id: 0,
					versionNumber: 1,
					data: {
						name: name,
						subType: subType,
						description: description,
						status: {
							state: fileState
						},
						storage: {
							storageType: storageType,
							encoding: encoding
						}
					},
					entityType: fileEntityType
				};
			case StorageType.Referenced:
				return <IEntityInstanceDto>
				{
					id: 0,
					versionNumber: 1,
					data: {
						name: name,
						subType: subType,
						description: description,
						status: {
							state: fileState
						},
						storage: {
							storageType: storageType,
							location: referenceUrl
						}
					},
					entityType: fileEntityType
				};
			default:
				return null;
		}
	}
}