/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';

/**
 * A representing a business logic entity
 *
 * @export
 * @class BusinessLogicEntity
 */
export class BusinessLogicEntity
implements IEntityInstance
{
	/**
	 * Initializes a new instance of a BusinessLogicEntity
	 *
	 * @param {IEntityInstance} entityInstance
	 * The underlying EntityInstance.
	 * @param {ResolverService} resolverService
	 * The resolver service.
	 * @memberof BusinessLogicEntity
	 */
	public constructor(
		entityInstance: IEntityInstance,
		resolverService: ResolverService)
	{
		this.entityInstance = entityInstance;
		this.resourceIdentifier = entityInstance.resourceIdentifier;
		this.versionNumber = entityInstance.versionNumber;
		this.entityType = entityInstance.entityType;
		this.changedById = entityInstance.changedById;
		this.changeDate = entityInstance.changeDate;
		this.createDate = entityInstance.createDate;
		this.id = entityInstance.id;
		this.data = entityInstance.data;

		this.displayName =
			StringHelper.beforeCapitalSpaces(
				StringHelper.getLastSplitValue(
					this.entityType,
					AppConstants.characters.period));

		this.entityInstanceApiService =
			<EntityInstanceApiService>
			resolverService.resolveApiService('EntityInstanceApiService');

		this.entityTypeApiService =
			<EntityTypeApiService>
			resolverService.resolveApiService('EntityTypeApiService');

		this.activityService =
			<ActivityService>
			resolverService.resolveShared('ActivityService');

		this.sessionService =
			<SessionService>
			resolverService.resolveShared('SessionService');

		this.entityService =
			<EntityService>
				resolverService.resolveShared('EntityService');
	}

	/**
	 * Gets or sets the id.
	 *
	 * @type {number}
	 * @memberof BusinessLogicEntity
	 */
	public id?: number;

	/**
	 * Gets or sets the resourceIdentifier.
	 *
	 * @type {string}
	 * @memberof BusinessLogicEntity
	 */
	public resourceIdentifier?: string;

	/**
	 * Gets or sets the versionNumber.
	 *
	 * @type {number}
	 * @memberof BusinessLogicEntity
	 */
	public versionNumber: number;

	/**
	 * Gets or sets the data.
	 *
	 * @type {any}
	 * @memberof BusinessLogicEntity
	 */
	public data: any;

	/**
	 * Gets or sets the entityType.
	 *
	 * @type {string}
	 * @memberof BusinessLogicEntity
	 */
	public entityType: string;

	/**
	 * Gets or sets the changed by entity id.
	 *
	 * @type {number}
	 * @memberof BusinessLogicEntity
	 */
	public changedById?: number;

	/**
	 * Gets or sets the change date.
	 *
	 * @type {string}
	 * @memberof BusinessLogicEntity
	 */
	public changeDate?: string;

	/**
	 * Gets or sets the create date.
	 *
	 * @type {string}
	 * @memberof BusinessLogicEntity
	 */
	public createDate?: string;

	/**
	 * Gets or sets the mapped display name.
	 *
	 * @type {string}
	 * @memberof BusinessLogicEntity
	 */
	public displayName: string;

	/**
	 * Gets or sets the entity instance api service.
	 *
	 * @type {EntityInstanceApiService}
	 * @memberof BusinessLogicEntity
	 */
	public readonly entityInstanceApiService: EntityInstanceApiService;

	/**
	 * Gets or sets the entity typeapi service
	 *
	 * @type {EntityTypeApiService}
	 * @memberof BusinessLogicEntity
	 */
	public readonly entityTypeApiService: EntityTypeApiService;

	/**
	 * Gets or sets the entity service
	 *
	 * @type {EntityService}
	 * @memberof BusinessLogicEntity
	 */
	public readonly entityService: EntityService;

	/**
	 * Gets or sets the activity service.
	 *
	 * @type {ActivityService}
	 * @memberof BusinessLogicEntity
	 */
	public readonly activityService: ActivityService;

	/**
	 * Gets or sets the backing entity instance.
	 *
	 * @type {EntityInstanceApiService}
	 * @memberof BusinessLogicEntity
	 */
	private readonly entityInstance: IEntityInstance;

	/**
	 * Gets or sets the session service.
	 *
	 * @type {SessionService}
	 * @memberof BusinessLogicEntity
	 */
	private readonly sessionService: SessionService;

	/**
	 * Gets the entity type
	 *
	 * @returns {Promise<IEntityType>}
	 * The entity type
	 * @memberof BusinessLogicEntity
	 */
	public getEntityTypeAsync(): Promise<IEntityType>
	{
		return this
			.entityTypeApiService
			.getSingleQueryResult(
				`Name.Equals("${this.entityType}")`,
				'Id',
				false);
	}

	/**
	 * Returns the underlying entity instance
	 *
	 * @returns {IEntityInstance}
	 * The entity type.
	 * @memberof BusinessLogicEntity
	 */
	public toEntityInstance(): IEntityInstance
	{
		return this.entityInstance;
	}

	/**
	 * Saves (updates) the entity instance
	 *
	 * @async
	 * @returns {Promise<void>}
	 * The empty promise.
	 * @memberof BusinessLogicEntity
	 */
	public async save(): Promise<void>
	{
		const entityType: IEntityType =
			await this.getEntityTypeAsync();

		this.entityInstanceApiService
			.entityInstanceTypeGroup = entityType.group;

		// refresh cache
		await this.entityInstanceApiService.get(this.id);

		const updatePromise: Promise<any> =
			this.entityInstanceApiService
				.update(
					this.id,
					this.entityInstance);

		const updateDisplayName: string =
			this.displayName
				+ ' entity';

		await this.activityService
			.handleActivity<object>(
				new Activity<object>(
					updatePromise,
					`<strong>Updating</strong> ${updateDisplayName}`,
					`<strong>Updated</strong> ${updateDisplayName}`,
					`${updateDisplayName} was updated.`,
					`${updateDisplayName} was not updated.`));
	}

	/**
	 * Executes a workflow action.
	 *
	 * @returns {Promise<void>}
	 * The empty promise.
	 * @memberof BusinessLogicEntity
	 */
	public async executeWorkflow(
		actionName: string): Promise<void>
	{
		const entityType: IEntityType =
			await this.getEntityTypeAsync();

		this.entityInstanceApiService
			.entityInstanceTypeGroup = entityType.group;

		// refresh cache
		await this.entityInstanceApiService.get(this.id);

		const updatePromise: Promise<any> =
			this.entityInstanceApiService
				.executeAction(
					this.id,
					actionName);

		await this.activityService
			.handleActivity<object>(
				new Activity<object>(
					updatePromise,
					`<strong>Executing</strong> ${actionName} action`,
					`<strong>Executed</strong> ${actionName} action`,
					`${actionName} action was executed.`,
					`${actionName} was not executed.`));
	}

	/**
	 * Gets the current user of this entity.
	 *
	 * @returns {IUser}
	 * The user.
	 * @memberof BusinessLogicEntity
	 */
	public currentUser(): IUser
	{
		return this
			.sessionService
			.user;
	}

	/**
	 * Add or updates a property.
	 *
	 * @param {object} item
	 * The object on which to add or update the property.
	 * @param {object} key
	 * The property key/name.
	 * @param {object} value
	 * The new property value.
	 * @returns {BusinessLogicEntity}
	 * This business logic entity with added or updated property.
	 * @memberof BusinessLogicEntity
	 */
	public addOrUpdateProperty(
		item: object,
		key: string,
		value: any): BusinessLogicEntity
	{
		ObjectHelper.addOrUpdateProperty(
			item,
			key,
			value);

		return this;
	}

	/**
	 * Add or updates a group of properties.
	 *
	 * @param {object} item
	 * The object on which to add or update the propertie.
	 * @param {object} properties
	 * The properties and values to add or update.
	 * @returns {BusinessLogicEntity}
	 * This business logic entity with added or updated properties.
	 * @memberof BusinessLogicEntity
	 */
	public addOrUpdateProperties(
		item: object,
		properties: object): BusinessLogicEntity
	{
		ObjectHelper
			.addOrUpdateProperties(
				item,
				properties);

		return this;
	}

	/**
	 * Gest parents of a certain type.
	 *
	 * @async
	 * @param {string} parentType
	 * The object on which to add or update the property.
	 * @param {string[]} excludedParentTypes
	 * The list of parent entity type groups to exclude from the query.
	 * @returns {Promise<IEntityInstance[]>}
	 * This business logic entity of which to get the parents.
	 * @memberof BusinessLogicEntity
	 */
	public async getParents(
		parentType: string,
		excludedParentTypes: string[] = []): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			(await this.getEntityTypeAsync()).group;

		const excludedParents: EntityType[] =
			await this.entityService.getEntityTypesFromNameList(
				excludedParentTypes);
		const filter: string =
			ApiFilterHelper.getEntityTypeFilter(
				excludedParents,
				true);

		return this.entityInstanceApiService.getParents(
			this.id,
			filter,
			null,
			null,
			null,
			parentType);
	}
}