/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-work-items',
	templateUrl: './work-items.component.html',
	styleUrls: [
		'./work-items.component.scss'
	]
})

/**
 * A component representing context level work items.
 *
 * @export
 * @class WorkItemsComponent
 * @extends {DrawerListDirective<IEntityInstance>}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class WorkItemsComponent
	extends DrawerListDirective<IEntityInstance>
	implements OnInit, IDynamicComponent<Component, any>,
		IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the work items component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @memberof WorkItemsComponent
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public sessionService: SessionService,
		public location: Location)
	{
		super();
	}

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof WorkItemsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.entityDisplayName = 'Work Item';
		this.wildcardChildFilter = 'WorkItem';

		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.resources =
				[
					'WorkItem.*'
				];
			this.clientMessage =
				'Access is required to at least one work item '
					+ 'entity type and version.';
			this.isOwnershipAllowed = false;

			return;
		}

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		this.context.data =
			{
				...this.context.data,
				drawerComponent: this
			};

		await this.setSecurityDefinitions(
			entityInstanceComponent.id,
			entityInstanceComponent.entityType.group,
			entityInstanceComponent.entityDefinition,
			entityInstanceComponent.entityInstanceApiService,
			entityInstanceComponent.entityTypeApiService);

		this.initialCreateData =
			{
				type:
					entityInstanceComponent
						.entityType.name,
				references: [
					{
						type: WorkItemConstants.workItemIdentifiers
							.parentNavigationEntityIdentifer,
						identifier:
							entityInstanceComponent
								.entityInstance
								.id
					},
					{
						type: WorkItemConstants.workItemIdentifiers
							.parentNavigationEntityType,
						identifier:
							entityInstanceComponent
								.entityInstance
								.entityType
					}
				]
			};
	}

	/**
	* Implements the ownership guard interface.
	* This will calculate drawer ownership permissions.
	*
	* @async
	* @returns {Promise<boolean>}
	* A value signifying whether or not access is allowed to this drawer.
	* @memberof WorkItemsComponent
	*/
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		await this.entityService.setStoredVariables();

		const workItemTypes: IEntityType[] =
			this.entityService
				.entityTypes
				.filter(
					(entityType: IEntityType) =>
						entityType.group.indexOf(
							WorkItemConstants.workItemEntityTypePrefix) !== -1);

		if (workItemTypes.length === 0)
		{
			return false;
		}

		const initialPromiseArray: Promise<any>[] = [];
		workItemTypes.forEach(
			(entityType: IEntityType) =>
			{
				initialPromiseArray.push(
					this.entityService.verifyEntityTypeAccess(
						entityType));
			});

		const allowedEntities: boolean[] =
		await Promise.all(initialPromiseArray);

		return allowedEntities.some(
			(allowed: boolean) =>
				allowed === true);
	}

	/**
	 * Handles the entity altered event which is sent to this component when
	 * the drawer entity update or entity create components have altered this
	 * lists data. This method will refresh the badge promise to match the
	 * updated information.
	 *
	 * @memberof WorkItemsComponent
	 */
	public entityAltered()
	{
		EventHelper.dispatchRefreshBadgePromiseEvent(
			DynamicComponentLookup.supportedTypes.workItemsComponent,
			DynamicComponentLookup.targetComponents.utilityMenuComponent);
	}
}