<div class="base-page-container {{siteLayoutService.contentCssClass}} {{basePageCssClass}}">
	<div class="base-page-context-menu-container"
		[ngClass]="{
			'base-page-context-menu-expanded-container': contextMenuExpanded
		}">
		<app-context-menu (menuExpansion)="contextMenuExpansion($event)"
			[pageContext]="pageContext"
			[pageContextOperationGroupName]="pageContextOperationGroupName">
		</app-context-menu>
	</div>

	<div class="base-page"
		[ngClass]="{
			'base-page-collapsed': contextMenuExpanded
		}">

		<div class="ui-g-12 page-header"
			id="base-page-header">

			<div class="ui-g-12"
				[ngClass]="{
					'ui-lg-6': headerExpanded === true
						&& (additionalHeaderContent != null
							|| (reserveHeaderBottomRight === true
								&& operationButtonBarDisplayed === false))
						&& siteLayoutService.contentWidth > twoColumnHeaderBreakpoint
				}">
				<div *ngIf="loading"
					[@contentAnimation]="state">
					<div class="panel-title ellipsis-text">
						<div class="panel-title-text">
							{{loadingPageTitle}}
						</div>
					</div>
				</div>
				<div *ngIf="!loading"
					[@contentAnimation]="state">
					<div class="ellipsis-text title-container"
						[ngClass]="{
							'shortened-text': headerExpanded === true
								&& additionalHeaderContent == null
								&& reserveHeaderBottomRight === true
								&& operationButtonBarDisplayed === false
								&& siteLayoutService.contentWidth <= twoColumnHeaderBreakpoint
						}">
						<span *ngIf="additionalHeaderContent != null
							|| headerContextContent != null
							|| operationButtonBarDisplayed === true"
							class="page-header-expand-icon-container"
							(click)="headerExpansion()">
							<a class="fa fa-angle-up"
								[ngClass]="{
									'fa-angle-down': !headerExpanded,
									'fa-angle-up': headerExpanded
								}">
							</a>
						</span>
						<span class="panel-title">
							<span class="panel-title-text">
								{{pageTitle}}
							</span>
						</span>
					</div>
					<div class="button-bar-container"
						[ngClass]="{
							'display-none': (headerExpanded === false)
						}"
						*ngIf="operationGroupExists === true && operationGroupRedraw === false">
						<div class="operation-group-container">
							<operation-button-bar
								[displayName]="operationGroupDisplayName || 'actions'"
								[pageContext]="pageContext"
								[operationGroupName]="operationGroupName"
								[reserveHeaderBottomRight]="reserveHeaderBottomRight === true
									|| (headerExpanded === true
										&& additionalHeaderContent != null
										&& siteLayoutService.contentWidth >
											twoColumnHeaderBreakpoint)"
								[initialModel]="operationGroupInitialModel"
								(loadedOperationsCountChanged)="buttonBarItemCountChanged($event)">
							</operation-button-bar>
						</div>
					</div>
					<div class="button-bar-container"
						[ngClass]="{
							'display-none': (headerExpanded === false)
						}"
						*ngIf="operationGroupExists === true && operationGroupRedraw === true">
						<div class="operation-group-container-placeholder">
							<br/>
							<br/>
						</div>
					</div>
				</div>
			</div>
			<div class="ui-g-12 additional-header-content no-horizontal-padding"
				[ngClass]="{
					'ui-lg-6': (additionalHeaderContent != null
						&& headerExpanded === true
						&& siteLayoutService.contentWidth > twoColumnHeaderBreakpoint),
					'display-none': (headerExpanded === false)
				}">
				<ng-content select="[additional-header-content]">
				</ng-content>
			</div>
			<div class="ui-g-12 header-context-content no-vertical-padding"
				[ngClass]="{
					'display-none': (headerExpanded === false)
				}">
				<ng-content select="[header-context-content]">
				</ng-content>
			</div>
		</div>

		<div class="ui-g-12 page-content no-horizontal-padding">
			<div id="base-page-content-top">
			</div>
			<ng-content>
			</ng-content>
		</div>

	</div>

</div>

<div class="context-layout-mask">
</div>
<div class="drawer-layout-mask">
</div>