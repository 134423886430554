/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IPolicyTermHierarchy
} from '@insurance/interfaces/policy-term-hierarchy';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FileState
} from '@shared/constants/enums/file-state.enum';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	SessionService
} from '@shared/services/session.service';

/**
 * A class representing a common interface to gather fully populated
 * work item information.
 *
 * @export
 * @class WorkItemService
 */
@Injectable()
export class WorkItemService
{
	/**
	 * Creates an instance of a WorkItemService.
	 *
	 * @param {CacheService} cacheService
	 * The cache service used for local storage.
	 * @param {ActivityService} activityService
	 * The activity service used for service actions.
	 * @param {SessionService} sessionService
	 * The sessionService service used for user actions.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used for lookups in this service.
	 * @param {EntityService} entityService
	 * The entity service used for lookups in this service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service used for lookups in this service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity type service for this component.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The resolver service for this component.
	 * @memberof WorkItemService
	 */
	public constructor(
		public cacheService: CacheService,
		public activityService: ActivityService,
		public sessionService: SessionService,
		public insuranceService: InsuranceService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public securityGroupApiService: SecurityGroupApiService)
	{
	}

	/**
	 * Retrieves the work item queue with the sent queue name. If no match
	 * is found, null is returned.
	 *
	 * @async
	 * @param {string} queueName
	 * The work item queue name to search for.
	 * @returns {Promise<ISecurityGroup[]>}
	 * A promise that resolves with the work item holding this unique name,
	 * or null if no match is found.
	 * @memberof WorkItemService
	 */
	public async getQueueByName(
		queueName: string): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.workItemQueues;

		return this.entityInstanceApiService
			.getSingleQueryResult(
				`${AppConstants.commonProperties.name} eq '${queueName}'`,
				AppConstants.commonProperties.name,
				true);
	}

	/**
	 * For each work item type, this will gather the full set of work items
	 * assigned to the user and not 'Done' or 'Ignored'.
	 *
	 * @async
	 * @param {boolean} includeReminders
	 * A flag to signify if reminders should be included in the results.
	 * @param {string} customFilter
	 * If sent this will be used over the default filter. The default filter
	 * includes only those items that are not Done, Ignored, and are assigned
	 * to the current user.
	 * @returns {Promise<IEntityInstance[]>}
	 * A promise that resolves with the full set of work items not that are
	 * assigned to the user and need to be worked.
	 * @memberof WorkItemService
	 */
	public async getCurrentUserWorkItems(
		includeReminders: boolean = true,
		customFilter: string = null): Promise<IEntityInstance[]>
	{
		const reminderFilter: string =
			includeReminders === true
				? AppConstants.empty
				: ` and ${AppConstants.commonProperties.name} `
					+ 'ne \'WorkItem.Reminder\'';
		const entityTypes: IEntityType[] =
			await this.entityTypeApiService.query(
				`${AppConstants.commonProperties.name}.StartsWith('`
					+ WorkItemConstants.workItemEntityTypePrefix
					+ '\') eq true'
					+ reminderFilter,
				AppConstants.commonProperties.name);
		const workItemFilter: string =
			!AnyHelper.isNullOrWhitespace(customFilter)
				? customFilter
				: `${AppConstants.commonProperties.status} ne `
					+ `'${WorkItemConstants.workItemStatus.done}' and `
					+ `${AppConstants.commonProperties.status} ne `
					+ `'${WorkItemConstants.workItemStatus.ignored}' and `
					+ `${AppConstants.commonProperties.assignedTo} eq `
					+ `'${this.sessionService.user.data.userName}'`;

		const workItemPromises: Promise<IEntityInstance[]>[] = [];
		for (const entityType of entityTypes)
		{
			workItemPromises.push(
				ApiHelper.getFullDataSet(
					this.entityInstanceApiService,
					workItemFilter,
					AppConstants.empty,
					entityType.group));
		}
		const workItemCollections: IEntityInstance[][] =
			await Promise.all(workItemPromises);

		const combinedWorkItems: IEntityInstance[] =
			workItemCollections.flat();

		return combinedWorkItems.map(
			(item: IEntityInstance) =>
			{
				(<any>item).dueDate =
					DateHelper.fromUtcIso(item.data.keyDates.dueDate);
				(<any>item).doneDate =
					!AnyHelper.isNullOrWhitespace(item.data.keyDates.doneDate)
						? DateHelper.fromUtcIso(item.data.keyDates.doneDate)
						: null;
				(<any>item).workItemDisplayName =
					StringHelper.getLongEntityDisplayName(
						item.entityType,
						WorkItemConstants.workItemEntityTypePrefix);

				return item;
			}
		);
	}

	/**
	 * Given a queue, user, and a set of security groups that the user should
	 * be in, this will add or remove queue security groups so that the user
	 * is set accurately matching the sent queue security groups only.
	 *
	 * @async
	 * @param {IEntityInstance} queue
	 * The queue to set security group users on.
	 * @param {IEntityInstance} user
	 * The user to set security groups for.
	 * @param {ISecurityGroup[]} securityGroups
	 * The security groups to set for the user in the queue.
	 * @memberof WorkItemService
	 */
	public async setQueueUserSecurityGroups(
		queue: IEntityInstance,
		user: IEntityInstance,
		securityGroups: ISecurityGroup[]): Promise<void>
	{
		const allowedSecurityGroups: ISecurityGroup[] =
			await this.getQueueSecurityGroups(
				queue);

		const sharedAddMessage: string =
			`queue permissions for ${user.data.firstName} `
				+ `${user.data.lastName}.`;
		const pendingMessage: string =
			`Setting ${sharedAddMessage}`;
		const completeMessage: string =
			`Set ${sharedAddMessage}`;

		const promiseArray: Promise<any>[] = [];
		const addedSecurityGroups: ISecurityGroup[] = [];
		const removedSecurityGroups: ISecurityGroup[] = [];
		for(const securityGroup of allowedSecurityGroups)
		{
			const users: number[] =
				await this.securityGroupApiService
					.getSecurityGroupUsers(
						securityGroup.id);

			const userIsInGroup: boolean =
				users.indexOf(user.id) !== -1;
			const addSecurity: boolean =
				securityGroups.some(
					(group: ISecurityGroup) =>
						group.id === securityGroup.id);
			const removeSecurity: boolean = !addSecurity;

			if (userIsInGroup === false
				&& addSecurity === true)
			{
				promiseArray.push(
					this.securityGroupApiService
						.createSecurityGroupUser(
							securityGroup.id,
							user.id));
				addedSecurityGroups.push(
					securityGroup);
			}

			if (userIsInGroup === true
				&& removeSecurity === true)
			{
				promiseArray.push(
					this.securityGroupApiService
						.deleteSecurityGroupUser(
							securityGroup.id,
							user.id));
				removedSecurityGroups.push(
					securityGroup);
			}
		}

		const detailMessages:
			{
				success: string;
				failure: string;
			} = this.getDetailMessages(
				queue,
				addedSecurityGroups,
				removedSecurityGroups);
		const successDetails: string = detailMessages.success;
		const failureDetails: string = detailMessages.failure;

		this.activityService.handleActivity(
			new Activity(
				new Promise<void>(
					async(resolve: any) =>
					{
						await Promise.all(promiseArray);

						this.syncCurrentUserSecurityGroups(
							user,
							addedSecurityGroups,
							[]);
						this.syncCurrentUserSecurityGroups(
							user,
							[],
							removedSecurityGroups);

						for(const securityGroup of allowedSecurityGroups)
						{
							const url: string =
								this.securityGroupApiService.getNestedUrl(
									securityGroup.id,
									AppConstants.nestedRouteTypes.users);
							this.cacheService.clearExistingStartsWithResponses(
								url);
						}

						resolve();
					}),
				`<strong>${pendingMessage}</strong>`,
				`<strong>${completeMessage}</strong>`,
				successDetails,
				failureDetails),
			AppConstants.activityStatus.complete);
	}

	/**
	 * Given a queue and a user, this will remove all queue level security
	 * groups that the user is in.
	 *
	 * @async
	 * @param {IEntityInstance} queue
	 * The queue to remove security group users.
	 * @param {IEntityInstance} user
	 * The user to remove security groups from.
	 * @memberof WorkItemService
	 */
	public async deleteUserQueueSecurityGroups(
		queue: IEntityInstance,
		user: IEntityInstance): Promise<void>
	{
		const securityGroups: ISecurityGroup[] =
			await this.getQueueSecurityGroups(
				queue);

		const sharedAddMessage: string =
			`queue permissions from ${user.data.firstName} `
				+ `${user.data.lastName}.`;
		const pendingMessage: string =
			`Removing ${sharedAddMessage}`;
		const completeMessage: string =
			`Removed ${sharedAddMessage}`;

		const promiseArray: Promise<object>[] = [];
		const removedSecurityGroups: ISecurityGroup[] = [];
		for(const securityGroup of securityGroups)
		{
			const users: number[] =
				await this.securityGroupApiService
					.getSecurityGroupUsers(
						securityGroup.id);

			const userIsInGroup: boolean =
				users.indexOf(user.id) !== -1;
			if (userIsInGroup === true)
			{
				promiseArray.push(
					this.securityGroupApiService
						.deleteSecurityGroupUser(
							securityGroup.id,
							user.id));

				removedSecurityGroups.push(securityGroup);
			}
		}

		const sharedDetailMessage: string =
			this.getSharedDetailMessage(
				queue.data.name,
				removedSecurityGroups,
				true);
		const successDetails: string =
			`Removed ${sharedDetailMessage}`;
		const failureDetails: string =
			`Failed to remove ${sharedDetailMessage}`;

		this.activityService.handleActivity(
			new Activity(
				new Promise<void>(
					async(resolve: any) =>
					{
						await Promise.all(promiseArray);

						this.syncCurrentUserSecurityGroups(
							user,
							[],
							removedSecurityGroups);

						for(const securityGroup of removedSecurityGroups)
						{
							const url: string =
								this.securityGroupApiService.getNestedUrl(
									securityGroup.id,
									AppConstants.nestedRouteTypes.users);
							this.cacheService.clearExistingStartsWithResponses(
								url);
						}

						resolve();
					}),
				`<strong>${pendingMessage}</strong>`,
				`<strong>${completeMessage}</strong>`,
				successDetails,
				failureDetails),
			AppConstants.activityStatus.complete);
	}

	/**
	 * Retrieves the security groups associated with a work item queue.
	 *
	 * @async
	 * @param {IEntityInstance} workItemQueue
	 * The work item queue for which to retrieve security groups.
	 * @returns {Promise<ISecurityGroup[]>}
	 * A promise that resolves with an array of security groups associated
	 * to the sent work item queue.
	 * @memberof WorkItemService
	 */
	public async getQueueSecurityGroups(
		workItemQueue: IEntityInstance): Promise<ISecurityGroup[]>
	{
		const queueName: string =
			workItemQueue.data.name
				.replace(
					/\s/g,
					AppConstants.empty);

		const filter: string =
			`${AppConstants.commonProperties.name}.StartsWith(`
				+ `'${queueName}${AppConstants.characters.underscore}') `
				+ 'and ('
				+ `${AppConstants.commonProperties.name}.EndsWith(`
				+ `'${AppConstants.characters.underscore}`
				+ `${WorkItemConstants.securityGroups.view}')`
				+ ' or '
				+ `${AppConstants.commonProperties.name}.EndsWith(`
				+ `'${AppConstants.characters.underscore}`
				+ `${WorkItemConstants.securityGroups.manage}')`
				+ ')';

		const groups: ISecurityGroup[] =
			await ApiHelper.getFullDataSet(
				this.securityGroupApiService,
				filter,
				AppConstants.commonProperties.name);

		return groups.map(
			(group: ISecurityGroup) =>
				(
					{
						...group,
						displayName: StringHelper
							.beforeCapitalSpaces(
								StringHelper
									.getLastSplitValue(
										group.name,
										AppConstants.characters.underscore))
					}
				));
	}

	/**
	 * Retrieves the users associated with a work item queue.
	 *
	 * @async
	 * @param {IEntityInstance} workItemQueue
	 * The work item queue for which to retrieve users.
	 * @param {ISecurityGroup[]} [securityGroups=null]
	 * The security groups associated with the work item queue.
	 * @param {string} [nameFilter=null]
	 * The name filter to limit users by first or last name containing the
	 * sent name filter. If no value is sent users will not be be filtered by
	 * name.
	 * @returns {Promise<IEntityInstance[]>}
	 * A promise that resolves with an array of users associated to the
	 * sent work item queue.
	 * @memberof WorkItemService
	 */
	public async getQueueUsers(
		workItemQueue: IEntityInstance,
		securityGroups: ISecurityGroup[] = null,
		nameFilter: string = null): Promise<IEntityInstance[]>
	{
		const mappedSecurityGroups: ISecurityGroup[] =
			securityGroups
				?? await this.getQueueSecurityGroups(workItemQueue);

		const promiseArray: Promise<number[]>[] = [];
		for(const group of mappedSecurityGroups)
		{
			promiseArray.push(
				this.securityGroupApiService.getSecurityGroupUsers(
					group.id));
		}

		const results: number[][] =
			await Promise.all(promiseArray);
		const flattenedResults: number[] =
			results.flat();

		if (flattenedResults.length === 0)
		{
			return [];
		}

		const userIdFilter: string =
			AppConstants.commonProperties.id
				+ ' in ('
				+ ApiFilterHelper.commaSeparatedStringValues(
					[...new Set(
						flattenedResults.map(
							(result: number) =>
								result.toString()))],
					AppConstants.empty)
				+ ')';
		const combinedFilter: string =
			AnyHelper.isNullOrWhitespace(nameFilter)
				? userIdFilter
				: `${userIdFilter} and (${nameFilter})`;

		return ApiHelper.getFullDataSet(
			this.entityInstanceApiService,
			combinedFilter,
			AppConstants.commonProperties.firstName,
			AppConstants.typeGroups.users);
	}

	/**
	 * Retrieves the queues that support the sent work item entity type.
	 *
	 * @async
	 * @param {IEntityInstance} workItem
	 * The work item for which to retrieve queues that have this work item
	 * as a supported work item type.
	 * @returns {Promise<IEntityInstance[]>}
	 * A promise that resolves with an array of queues supporting the
	 * sent work item type.
	 * @memberof WorkItemService
	 */
	public async  getAvailableWorkItemQueues(
		workItem: IEntityInstance): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.workItemQueues;
		const availableQueues: IEntityInstance[] =
			await ApiHelper.getFullDataSet(
				this.entityInstanceApiService,
				AppConstants.empty,
				AppConstants.commonProperties.name);

		return availableQueues
			.filter((item: IEntityInstance) =>
				item.data.supportedWorkItemTypes.indexOf(
					workItem.entityType) !== -1);
	}

	/**
	 * Retrieves the users that are in the queue if the queue name matches an
	 * existing queue and filters by the name filter if sent. This will always
	 * include the sent selected user name to ensure it is available in the
	 * options list.
	 *
	 * @async
	 * @param {string} queueName
	 * The queue name to limit users by, if no matching queue is found this
	 * will return all users matching the filter.
	 * @param {string} [nameFilter=null]
	 * The name filter to limit users by first or last name containing the
	 * sent name filter. If no value is sent users will not be be filtered by
	 * name.
	 * @returns {Promise<IEntityInstance[]>}
	 * A promise that resolves with an array of users in the sent work item
	 * queue or all users if no queue is found. This result will be filtered
	 * by the name filter if sent.
	 * @memberof WorkItemService
	 */
	public async getAvailableAssignedToUsers(
		queueName: string,
		selectedUserName: string,
		nameFilter: string = null): Promise<IEntityInstance[]>
	{
		let filter: string =
			this.getSplitNameFilter(nameFilter);

		if (!AnyHelper.isNullOrWhitespace(queueName))
		{
			const workItemQueue: IEntityInstance =
				await this.getQueueByName(queueName);

			if (!AnyHelper.isNull(workItemQueue))
			{
				return this.getQueueUsers(
					workItemQueue,
					null,
					filter);
			}
		}

		let availableUsers: IEntityInstance[] = [];
		if (!AnyHelper.isNullOrWhitespace(selectedUserName))
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				AppConstants.typeGroups.users;
			const selectedUser: IEntityInstance =
				await this.entityInstanceApiService.getSingleQueryResult(
					`${AppConstants.commonProperties.userName} eq `
						+ `'${selectedUserName}'`,
					AppConstants.empty,
					true);

			if (!AnyHelper.isNull(selectedUser)
				&& (AnyHelper.isNullOrWhitespace(filter)
					|| (`${selectedUser.data.firstName} `
						+ selectedUser.data.lastName)
						.indexOf(filter) !== -1))
			{
				availableUsers.push(selectedUser);
			}
		}

		const filterAddition: string =
			AnyHelper.isNullOrWhitespace(filter)
				? AppConstants.empty
				: `and (${filter})`;
		filter =
			availableUsers.length > 0
				? `(${AppConstants.commonProperties.userName} ne `
					+ `'${availableUsers[0].data.userName}') `
					+ filterAddition
				: filter;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		const filteredUsers: IEntityInstance[] =
			await this.entityInstanceApiService.query(
				filter,
				AppConstants.commonProperties.firstName);
		availableUsers =
			availableUsers.concat(filteredUsers);

		return availableUsers.sort((
			userOne: IEntityInstance,
			userTwo: IEntityInstance) =>
			ObjectHelper.sortByPropertyValue(
				userOne,
				userTwo,
				`${AppConstants.nestedDataIdentifier}.`
					+ AppConstants.commonProperties.firstName));
	}

	/**
	 * Given an entity instance id, this will look for a matching work
	 * item as a child of this instance.
	 *
	 * @async
	 * @param {number} instanceId
	 * The instance id to be checked for an active child work item.
	 * @param {string} instanceEntityType
	 * The instance entity type to be checked for an active child work item.
	 * @param {string} workItemInstanceEntityType
	 * The work item instance type to check for a child instance match.
	 * @param {string[]} allowedStatusValues
	 * The status values that should be allowed as a specific match.
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not the sent work item exists as a child
	 * of this instance and is active.
	 * @memberof WorkItemService
	 */
	public async workItemChildExists(
		instanceId: number,
		instanceEntityType: string,
		workItemInstanceEntityType: string,
		allowedStatusValues: string[] = [
			WorkItemConstants.workItemStatus.active
		]): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			instanceEntityType;
		const workItemInstances: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				instanceId,
				null,
				null,
				null,
				AppConstants.dataLimits.large,
				workItemInstanceEntityType);

		return workItemInstances.length > 0
			&& workItemInstances.filter(
				(workItemInstance: IEntityInstance) =>
					allowedStatusValues
						.indexOf(workItemInstance.data.status) !== -1)
				.length > 0;
	}

	/**
	 * This will check the product settings associated to the sent parent
	 * entity type of a term or transaction, and define whether or not
	 * adding this file type and subtype will fire a review work item.
	 *
	 * @async
	 * @param {number} parentId
	 * The id for the parent entity.
	 * @param {string} parentTypeGroup
	 * The entity type group of the parent.
	 * @param {string} fileEntityType
	 * The entity instance type of the file instance to check.
	 * @param {string} fileSubtype
	 * The subtype of the file instance to check.
	 * @returns {Promise<boolean>}
	 * A promise that resolves with a true or false value that signifies that
	 * the sent entity type and sub type requires a review as defined in
	 * an insurance product.
	 * @memberof WorkItemService
	 */
	public async policyFileRequiresReview(
		parentId: number,
		parentTypeGroup: string,
		fileEntityType: string,
		fileSubtype: string): Promise<boolean>
	{
		if (AnyHelper.isNull(parentId)
			|| AnyHelper.isNullOrWhitespace(parentTypeGroup)
			|| (parentTypeGroup !==
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms)
				&& parentTypeGroup.indexOf(
					InsuranceConstants.policyTermTransactionPrefix) === -1)
		{
			return false;
		}

		const policyTerm: IEntityInstance =
			parentTypeGroup ===
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms
				? await this.insuranceService.getPolicyTerm(
					parentId)
				: await this.insuranceService.getPolicyTermByTransaction(
					parentId,
					parentTypeGroup);

		const product: IEntityInstance =
			await this.insuranceService.getProductByName(
				policyTerm.data.productName);
		const reviewFileDefinition: any =
			product.data.workItems.requiredFileReviews
				.find(
					(item: any) =>
						item.entityType === fileEntityType
							&& item.subtypes.indexOf(fileSubtype) !== -1);

		return !AnyHelper.isNull(reviewFileDefinition);
	}

	/**
	 * Given a work item, this will return the policy lifecycle files that
	 * are required for the sent work item.
	 *
	 * @async
	 * @param {IEntityInstance} workItem
	 * The work item for which to retrieve required policy lifecycle files.
	 * @returns {Promise<string[]>}
	 * A promise that resolves with an array of required policy lifecycle files
	 * for the sent work item. If the require all files flag is set to true,
	 * the first item will be 'Required Files: ' otherwise 'Allowed Files: '.
	 * @memberof WorkItemService
	 */
	public async getRequiredPolicyLifecycleFiles(
		workItem: IEntityInstance): Promise<string[]>
	{
		if (AnyHelper.isNullOrWhitespace(
			workItem.data.metadata?.policyLifecycle))
		{
			return [];
		}

		const parentEntityId: number =
			workItem.data.references.find(
				(item: any) =>
					item.type ===
						WorkItemConstants.workItemIdentifiers
							.parentNavigationEntityIdentifer).identifier;
		const parentEntityType: string =
			workItem.data.references.find(
				(item: any) =>
					item.type ===
						WorkItemConstants.workItemIdentifiers
							.parentNavigationEntityType).identifier;

		const policyTerm: IEntityInstance =
			parentEntityType ===
				InsuranceConstants.insuranceEntityTypes.policyTerm
				? await this.insuranceService.getPolicyTerm(
					parentEntityId)
				: await this.insuranceService.getPolicyTermByTransaction(
					parentEntityId,
					parentEntityType);
		const product: IEntityInstance =
			await this.insuranceService.getProductByName(
				policyTerm.data.productName);

		const policyLifecycleSettings: any =
			product.data.policyLifecycleSettings[
				workItem.data.metadata.policyLifecycle];

		if (policyLifecycleSettings.requiredFiles.length === 0)
		{
			return [];
		}

		const policyTermHierarchy: IPolicyTermHierarchy =
			await this.insuranceService.getPolicyTermHierarchy(
				policyTerm.id);
		const hierarchyFiles: IEntityInstance[] =
			await this.insuranceService.getHierarchyFiles(
				policyTermHierarchy);
		const hierarchyRules: IEntityInstanceRuleViolation[] =
			await this.insuranceService.getHierarchyRuleViolations(
				policyTermHierarchy);

		policyLifecycleSettings.requiredFiles
			.sort(
				(itemOne: any,
					itemTwo: any) =>
					ObjectHelper.sortByPropertyValue(
						itemOne,
						itemTwo,
						AppConstants.commonProperties.subtype));

		const requiredFileDescriptions: string[] = [];
		requiredFileDescriptions.push(
			policyLifecycleSettings.requireAllFiles === true
				? 'Required Files: '
				: 'Allowed Files: ');

		for (const requiredFileDefinition
			of policyLifecycleSettings.requiredFiles)
		{
			requiredFileDescriptions.push(
				this.getRequiredFileDefinitionDescription(
					requiredFileDefinition.entityType,
					requiredFileDefinition.subtype,
					requiredFileDefinition.requiredRule,
					requiredFileDefinition.allowedStatuses,
					hierarchyFiles,
					hierarchyRules));
		}

		return requiredFileDescriptions
			.filter(
				(item: string) =>
					!AnyHelper.isNullOrWhitespace(item));
	}

	/**
	 * Calculates aggregates associated with all completed work items and
	 * aggregates these by counts overall and the length of availability
	 * as a work item. This will return an aggregate array ready for a
	 * chart display.
	 *
	 * @async
	 * @returns {Promise<IAggregate[]>}
	 * An array of aggregates representing the monthly average of each
	 * work item type completed.
	 * @memberof WorkItemService
	 */
	public async aggregateMonthlyAverages(): Promise<IAggregate[]>
	{
		const entityTypes: IEntityType[] =
			await ApiHelper.getFullDataSet(
				this.entityTypeApiService,
				`${AppConstants.commonProperties.name}.StartsWith('`
					+ WorkItemConstants.workItemEntityTypePrefix
					+ '\') eq true',
				AppConstants.empty);

		const dataArray: any[] = [];
		for (const entityType of entityTypes)
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				entityType.group;
			const firstInstance: IEntityInstance =
				await this.entityInstanceApiService
					.getSingleQueryResult(
						AppConstants.empty,
						`${AppConstants.commonProperties.doneDate} asc`,
						true);

			this.entityInstanceApiService.entityInstanceTypeGroup =
				entityType.group;
			const results: IAggregate[] =
				await this.entityInstanceApiService
					.aggregate(
						AppConstants.aggregateMethods.count,
						null,
						`${AppConstants.commonProperties.typeId} eq `
							+ entityType.id
							+ ` AND ${AppConstants.commonProperties.status} `
							+ `eq '${WorkItemConstants.workItemStatus.done}'`);
			const monthsElapsed: number =
				!AnyHelper.isNull(firstInstance)
					? Math.ceil(
						DateHelper.getSystemDateTime()
							.diff(
								DateHelper.fromUtcIso(
									firstInstance.createDate),
								<any>DateHelper.timeUnits.month)
							.toObject().months)
					: 0;

			dataArray.push(
				{
					entityType:
						StringHelper.getLongEntityDisplayName(
							entityType.name,
							WorkItemConstants.workItemEntityTypePrefix)
							.replace(
								' Date',
								AppConstants.empty)
							.replace(
								'Issued Status',
								'Status'),
					averagePerMonth:
						results[0].value
							/ (monthsElapsed > 0
								? monthsElapsed
								: 1)
				});
		}

		const promiseResults: any[] =
			await Promise.all(
				dataArray);

		promiseResults
			.sort((mapResultOne, mapResultTwo) =>
				mapResultOne.entityType.localeCompare(
					mapResultTwo.entityType));

		return promiseResults.map(
			(mapResult: any) =>
			{
				const newAggregate: IAggregate =
					{
						key: {
							entityType: mapResult.entityType
						},
						value: mapResult.averagePerMonth
					};

				return newAggregate;
			});
	}

	/**
	 * Retrieves the data for completed and remaining work items.
	 *
	 * @async
	 * @returns {Promise<any>}
	 * A promise that resolves with the monthly averages for completed and
	 * remaining work items.
	 * @memberof WorkItemService
	 **/
	public async getCompletedPercent():
		Promise<any>
	{
		const completedWorkItems: IAggregate[] =
			await this.entityService.getAccumulatedAggregateByType(
				WorkItemConstants.workItemEntityTypePrefix,
				AppConstants.aggregateMethods.count,
				AppConstants.empty,
				`${AppConstants.commonProperties.status} `
					+ `${AppConstants.filterQueryOperators.equal} `
					+ `'${WorkItemConstants.workItemStatus.done}'`);
		const remainingWorkItems: IAggregate[] =
			await this.entityService.getAccumulatedAggregateByType(
				WorkItemConstants.workItemEntityTypePrefix,
				AppConstants.aggregateMethods.count,
				AppConstants.empty,
				`${AppConstants.commonProperties.status} `
					+ `${AppConstants.filterQueryOperators.notEqual} `
					+ `'${WorkItemConstants.workItemStatus.done}' `
					+ `and ${AppConstants.commonProperties.status} `
					+ `${AppConstants.filterQueryOperators.notEqual} `
					+ `'${WorkItemConstants.workItemStatus.ignored}'`);

		 const totalCompleted: number =
			completedWorkItems[0].value;
		const totalRemaining =
			remainingWorkItems[0].value;

		const completedPercent: number =
			(totalCompleted /
				(totalCompleted + totalRemaining));

		const formattedCompletedPercent =
			!isNaN(completedPercent)
				? StringHelper.format(
					completedPercent.toString(),
					'percent' )
				: 'No Data';

		const completedData: any =
			{
				data: {
					completed: totalCompleted,
					remaining: totalRemaining,
					completedPercent: formattedCompletedPercent
				}
			};

		return completedData;
	}

	/**
	 * When adding or removing security group users from the queue, this will
	 * calculate and return the shared message for activity displays.
	 *
	 * @param {string} queueName
	 * The name of the queue being modified.
	 * @param {ISecurityGroup[]} securityGroups
	 * The security groups being added or removed.
	 * @param {boolean} [deleteMessage=false]
	 * A flag to signify if the message is for adding or removing users.
	 * @returns {string}
	 * The shared message for adding or removing security group users.
	 * @memberof WorkItemService
	 */
	private getSharedDetailMessage(
		queueName: string,
		securityGroups: ISecurityGroup[],
		deleteMessage: boolean = false): string
	{
		const clearingViewPermissions: boolean =
			securityGroups.some(
				(group: ISecurityGroup) =>
					group.name.indexOf(
						WorkItemConstants.securityGroups.view) !== -1);
		const clearingManagePermissions: boolean =
			securityGroups.some(
				(group: ISecurityGroup) =>
					group.name.indexOf(
						WorkItemConstants.securityGroups.manage) !== -1);

		let groupPermissionDetails: string = AppConstants.empty;

		switch (true)
		{
			case clearingViewPermissions && clearingManagePermissions:
				groupPermissionDetails = 'view and manage';
				break;
			case clearingViewPermissions:
				groupPermissionDetails = 'view';
				break;
			case clearingManagePermissions:
				groupPermissionDetails = 'manage';
				break;
		}

		return `${groupPermissionDetails} permissions `
			+ `${deleteMessage ? 'from' : 'for'} the `
			+ `${queueName} queue.`;
	}

	/**
	 * Given a queue, added security groups, and removed security groups, this
	 * will return the success and failure detail messages for the activity
	 * display.
	 *
	 * @param {IEntityInstance} queue
	 * The queue to set security group users on.
	 * @param {ISecurityGroup[]} addedSecurityGroups
	 * The security groups to add to the user in the queue.
	 * @param {ISecurityGroup[]} removedSecurityGroups
	 * The security groups to remove from the user in the queue.
	 * @returns {{ success: string; failure: string }}
	 * The success and failure detail messages for the activity display.
	 * @memberof WorkItemService
	 */
	private getDetailMessages(
		queue: IEntityInstance,
		addedSecurityGroups: ISecurityGroup[],
		removedSecurityGroups: ISecurityGroup[]):
		{ success: string; failure: string }
	{
		let sharedDetailMessage: string = AppConstants.empty;
		let successDetails: string = AppConstants.empty;
		let failureDetails: string = AppConstants.empty;

		if (addedSecurityGroups.length > 0)
		{
			sharedDetailMessage =
				this.getSharedDetailMessage(
					queue.data.name,
					addedSecurityGroups);
			successDetails +=
				`Added ${sharedDetailMessage}`;
			failureDetails +=
				`Failed to add ${sharedDetailMessage}`;
		}

		if (removedSecurityGroups.length > 0)
		{
			sharedDetailMessage =
				this.getSharedDetailMessage(
					queue.data.name,
					removedSecurityGroups,
					true);
			successDetails +=
				(AnyHelper.isNullOrWhitespace(successDetails)
					? AppConstants.empty
					: AppConstants.characters.space)
					+ `Removed ${sharedDetailMessage}`;
			failureDetails +=
				(AnyHelper.isNullOrWhitespace(failureDetails)
					? AppConstants.empty
					: AppConstants.characters.space)
					+ `Failed to remove ${sharedDetailMessage}`;
		}

		if (addedSecurityGroups.length === 0
			&& removedSecurityGroups.length > 0)
		{
			sharedDetailMessage =
				`permissions for the ${queue.data.name} queue.`;
			successDetails +=
				`Set ${sharedDetailMessage}`;
			failureDetails +=
				`Failed to set ${sharedDetailMessage}`;
		}

		const detailMessages: { success: string; failure: string } =
			{
				success: successDetails,
				failure: failureDetails
			};

		return detailMessages;
	}

	/**
	 * Given a set of added security groups and removed security groups, this
	 * will synchronize the user security groups with the sent security groups.
	 *
	 * @param {IEntityInstance} user
	 * The user that had security groups altered.
	 * @param {ISecurityGroup[]} addedSecurityGroups
	 * The security groups that were added to the user.
	 * @param {ISecurityGroup[]} removedSecurityGroups
	 * The security groups that were removed from the user.
	 * @memberof WorkItemService
	 */
	private syncCurrentUserSecurityGroups(
		user: IEntityInstance,
		addedSecurityGroups: ISecurityGroup[],
		removedSecurityGroups: ISecurityGroup[]): void
	{
		if (this.sessionService.user.id !== user.id)
		{
			return;
		}

		const sessionServiceUser: IUser = this.sessionService.user;
		for (const securityGroup of addedSecurityGroups)
		{
			this.addCurrentUserSecurityGroup(
				sessionServiceUser.membershipSecurityGroups,
				securityGroup);
		}

		for (const securityGroup of removedSecurityGroups)
		{
			this.removeCurrentUserSecurityGroup(
				sessionServiceUser.membershipSecurityGroups,
				securityGroup);
		}

		this.sessionService.user = sessionServiceUser;
	}

	/**
	 * Given a security group, this will add the security group to the current
	 * user's security groups if it is not already present.
	 *
	 * @param {ISecurityGroup[]} currentSecurityGroups
	 * The current user's security groups.
	 * @param {ISecurityGroup} securityGroupToAdd
	 * The security group to add to the current user's security groups.
	 * @memberof WorkItemService
	 */
	private addCurrentUserSecurityGroup(
		currentSecurityGroups: ISecurityGroup[],
		securityGroupToAdd: ISecurityGroup): void
	{
		const groupIndex: number =
			currentSecurityGroups
				.findIndex(
					(group: ISecurityGroup) =>
						group.id === securityGroupToAdd.id);

		if (groupIndex === -1)
		{
			currentSecurityGroups.push(
				securityGroupToAdd);
		}
	}

	/**
	 * Given a security group, this will remove the security group from the
	 * current user's security groups if it is present.
	 *
	 * @param {ISecurityGroup[]} currentSecurityGroups
	 * The current user's security groups.
	 * @param {ISecurityGroup} securityGroupToRemove
	 * The security group to remove from the current user's security groups.
	 * @memberof WorkItemService
	 */
	private removeCurrentUserSecurityGroup(
		currentSecurityGroups: ISecurityGroup[],
		securityGroupToRemove: ISecurityGroup): void
	{
		const groupIndex: number =
			currentSecurityGroups
				.findIndex(
					(group: ISecurityGroup) =>
						group.id === securityGroupToRemove.id);

		if (groupIndex !== -1)
		{
			currentSecurityGroups.splice(
				groupIndex,
				1);
		}
	}

	/**
	 * Given a work item, this will return the policy lifecycle files that
	 * are required for the sent work item.
	 *
	 * @param {string} fileEntityType
	 * The file entity type to search for.
	 * @param {string} fileSubtype
	 * The file subtype to search for.
	 * @param {string} conditionalRuleName
	 * If sent, this requires that the rule has been violated for this file
	 * to be required.
	 * @param {string} allowedStatuses
	 * The set of allowed statuses for this file. IE: Active, Approved, etc.
	 * @param {IEntityInstance[]} hierarchyFiles
	 * The full set of hierarchy files existing for the term hierarchy.
	 * @param {IEntityInstanceRuleViolation[]} hierarchyRules
	 * The full set of hierarchy rules existing for the term hierarchy.
	 * @returns {string}
	 * A string that represents the files existence and current state. If
	 * the matching conditional rule does not exist, this will return
	 * an empty	string signifying that it is not required.
	 * @memberof WorkItemService
	 */
	private getRequiredFileDefinitionDescription(
		fileEntityType: string,
		fileSubtype: string,
		conditionalRuleName: string,
		allowedStatuses: string[],
		hierarchyFiles: IEntityInstance[],
		hierarchyRules: IEntityInstanceRuleViolation[]): string
	{
		const approvalRequired: boolean =
			!allowedStatuses.some(
				(status: string) =>
					status === FileState.Active);
		const matchingFiles: IEntityInstance[] =
			hierarchyFiles.filter(
				(file: IEntityInstance) =>
					file.entityType === fileEntityType
						&& file.data.subType === fileSubtype);
		const matchingApprovedFile: IEntityInstance =
			matchingFiles.find(
				(file: IEntityInstance) =>
					file.data.status.state === FileState.Approved);
		const matchingRule: boolean =
			AnyHelper.isNullOrWhitespace(conditionalRuleName)
				? true
				: hierarchyRules.some(
					(rule: IEntityInstanceRuleViolation) =>
						rule.ruleDefinition.name === conditionalRuleName);

		if (matchingRule === false)
		{
			return AppConstants.empty;
		}

		if (!AnyHelper.isNull(matchingApprovedFile))
		{
			return fileSubtype
				+ ` - ${FileState.Approved}`;
		}

		if (matchingFiles.length > 0
			&& matchingFiles.every(
				file =>
					file.data.status.state === FileState.Rejected))
		{
			return fileSubtype
				+ ` - ${FileState.Rejected}`;
		}

		const fileState: string =
			approvalRequired === true
				? 'Awaiting approval'
				: 'Active';
		const fileStatus: string =
			matchingFiles.length !== 0
				? fileState
				: 'Not attached';

		return fileSubtype
			+ ` - ${fileStatus}`;
	}

	/**
	 * Given a name filter, this will split the filter on spaces and return
	 * a filter that searches for each split word in the keywords on
	 * entity instance users.
	 *
	 * @async
	 * @param {string} nameFilter
	 * The name filter to deconstruct.
	 * @returns {string}
	 * A filter string ready for use in querying for users by keyword matches.
	 * @memberof WorkItemService
	 */
	private getSplitNameFilter(
		nameFilter: string): string
	{
		if (AnyHelper.isNullOrWhitespace(nameFilter))
		{
			return AppConstants.empty;
		}

		let filter: string = AppConstants.empty;
		const splitNameFilter: string[] =
			nameFilter.split(AppConstants.characters.space);

		for (const splitName of splitNameFilter)
		{
			const formattedName = splitName.trim();
			if (AnyHelper.isNullOrWhitespace(formattedName))
			{
				continue;
			}

			filter +=
				(AnyHelper.isNullOrWhitespace(filter)
					? AppConstants.empty
					: ` ${AppConstants.filterQueryOperators.and} `)
					+ `${AppConstants.commonProperties.keywords}.Contains('`
						+ `${splitName}') eq true`;
		}

		filter = `(${filter})`;

		return filter;
	}
}
