<div *ngIf="loading"
	class="ui-g-{{summaryCardConfiguration.width ?? 6}}">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div *ngIf="!loading"
	class="ui-g-{{summaryCardConfiguration.width ?? 6}} content-h content-h-{{summaryCardConfiguration.height ?? 6}}">
	<app-summary-card
		[summaryTemplate]="summaryCardConfiguration.summaryTemplate"
		[summaryPromise]="summaryCardConfiguration.summaryPromise"
		[summaryFunction]="summaryCardConfiguration.summaryFunction"
		[titleTemplate]="summaryCardConfiguration.titleTemplate"
		[titlePromise]="summaryCardConfiguration.titlePromise"
		[dataPromise]="dataPromise">
	</app-summary-card>
</div>