/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	Location
} from '@angular/common';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	IQueryParameters
} from '@api/interfaces/common/queryparameters.interface';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FileCategory
} from '@dynamicComponents/files/file-category/file-category';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FileState
} from '@shared/constants/enums/file-state.enum';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	FileDirective
} from '@shared/directives/file.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	FileHelper
} from '@shared/helpers/file.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	FileService
} from '@shared/services/files/file.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	Observable,
	Subscriber,
	Subscription
} from 'rxjs';

/* eslint-enable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
	selector: 'app-files',
	templateUrl: './files.component.html',
	styleUrls: [
		'./files.component.scss'
	]
})

/**
 * A component representing context level files.
 *
 * @export
 * @class FilesComponent
 * @extends FileDirective
 * @implements {IDynamicComponent<Component, any>}
 */
export class FilesComponent
	extends FileDirective
	implements IDynamicComponent<Component, any>, OnInit
{
	/**
	 * Initializes a new instance of the Files component.
	 *
	 * @param {FileService} fileService
	 * The file service injected into this component.
	 * @param {ActivityService}
	 * Handles activities and banners.
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	* @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof FilesComponent
	 */
	public constructor(
		private readonly fileService: FileService,
		private readonly activityService: ActivityService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public sessionService: SessionService,
		public location: Location,
		public entityInstanceApiService: EntityInstanceApiService,
		public resolver: ResolverService)
	{
		super(
			entityTypeApiService,
			entityInstanceApiService,
			resolver);
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof FilesComponent
	 */
	@Input()
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets a value representing the common list context to be loaded
	 * during list mode.
	 *
	 * @type {IDynamicComponentContext<
	 * 		FilesComponent,
	 * 		ICommonListContext<IFileEntity>>}
	* @memberof FilesComponent
	*/
	public commonListContext: IDynamicComponentContext<
		FilesComponent,
		ICommonListContext<IFileEntity>>;

	/**
	 * Gets or sets a value indicating whether the list is loading.
	 *
	 * @type {boolean}
	 * @memberof FilesComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a collection of enabled filters.
	 *
	 * @type {ICommonListFilter[]}
	 * @memberof FilesComponent
	 */
	public enabledFilters: ICommonListFilter[] = [];

	/**
	 * Gets or sets the enabled sorter.
	 *
	 * @type {ICommonListSort}
	 * @memberof FilesComponent
	 */
	public sorter: ICommonListSort =
		<ICommonListSort>
		{
			name: 'Create Date',
			value: 'CreateDate',
			direction: AppConstants.sortDirections.descending,
			iconAsc: 'fa fa-sort-amount-asc',
			iconDesc: 'fa fa-sort-amount-desc'
		};

	/**
	 * Gets or sets the view that is shown.
	 *
	 * @type {string}
	 * @memberof FilesComponent
	 */
	public view: string = this.viewModes.list;

	/**
	 * The file that has been selected in the common list
	 *
	 * @type {IFileEntity}
	 * @memberof FilesComponent
	 */
	public fileSelected: IFileEntity;

	/**
	 * The parent entity instance id.
	 *
	 * @type {number}
	 * @memberof FilesComponent
	 */
	public parentId: number;

	/**
	 * The parent entity type group.
	 *
	 * @type {string}
	 * @memberof FilesComponent
	 */
	public parentTypeGroup: string;

	/**
	 * Gets or sets the data subscription for files.
	 *
	 * @type {Subscription}
	 * @memberof FilesComponent
	 */
	public dataSubscription: Subscription;

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {string[]}
	 * @memberof FilesComponent
	 */
	public isOwnershipAllowed: boolean = true;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof FilesComponent
	 */
	public sessionIdentifier: string = AppConstants.empty;

	/**
	 * Gets or sets the access denied url.
	 *
	 * @type {string}
	 * @memberof FilesComponent
	 */
	public accessDeniedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {string[]}
	 * @memberof FilesComponent
	 */
	public resources: string[] = [];

	/**
	 * Gets or sets the client message if insufficient resources exist.
	 *
	 * @type {string}
	 * @memberof FilesComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets the categories/entity types that the user is authorized
	 * to create.
	 *
	 * @type {FileCategory[]}
	 * @memberof FilesComponent
	 */
	public creatableCategories: FileCategory[] = [];

	/**
	 * A method run upon initialization of this component.
	 *
	 * @memberof FilesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (this.context?.source instanceof EntityInstanceComponent)
		{
			if (!await this.isPageOwnershipAllowed())
			{
				this.accessDeniedUrl = this.location.path();
				this.sessionIdentifier = this.sessionService.sessionId;
				this.resources =
					[
						'File.*'
					];
				this.clientMessage =
					'Access is required to at least one file '
						+ 'entity type and version.';
				this.isOwnershipAllowed = false;

				return;
			}

			this.parentId = this.context
				.source
				.id;

			this.parentTypeGroup = this.context
				.source
				.entityTypeGroup;

			const fileParentEntityDefinition = this.context
				.source
				.entityDefinition;

			await this.fileService
				.getAuthorizedCategories(
					this.parentId,
					this.parentTypeGroup,
					fileParentEntityDefinition)
				.then(
					(categories: FileCategory[]) =>
					{
						this.categories =
							this.getReadableCategories(categories);

						this.creatableCategories =
							this.getCreatableTypes(categories);
					})
				.catch(
					(error: any) => {
						throw new Error(error);
					});

			const entityInstanceComponent: EntityInstanceComponent =
				<EntityInstanceComponent>this.context.source;

			await this.setSecurityDefinitions(
				entityInstanceComponent.id,
				entityInstanceComponent.entityType.group,
				entityInstanceComponent.entityDefinition,
				entityInstanceComponent.entityInstanceApiService,
				entityInstanceComponent.entityTypeApiService);

			this.commonListContext =
				{
					data: this.generateCommonListContext(),
					source: this
				};

			this.refreshCommonListContext(
				await this.loadFiles(
					this.enabledFilters,
					this.sorter),
				this);
		}
	}

	/**
	 * Gets a value indicating whether the view is valid.
	 *
	 * @returns {boolean}
	 * A boolean value representing if the view is valid.
	 * @memberof FilesComponent
	 */
	public isValid(): boolean
	{
		return !AnyHelper.isNull(this.context?.source)
			&& (this.context.source instanceof EntityInstanceComponent);
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate drawer ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this drawer.
	 * @memberof FilesComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const fileTypes: IEntityType[] =
			await this.entityTypeApiService.query(
				AppConstants.commonProperties.group
					+ '.StartsWith(\'File.\') eq true '
					+ `or ${AppConstants.commonProperties.name}`
					+ ' eq \'File\'',
				AppConstants.empty);

		if (fileTypes.length === 0)
		{
			return false;
		}

		const initialPromiseArray: Promise<any>[] = [];
		fileTypes.forEach(
			(entityType: IEntityType) =>
			{
				initialPromiseArray.push(
					this.entityService.verifyEntityTypeAccess(
						entityType));
			});

		const allowedEntities: boolean[] =
			await Promise.all(initialPromiseArray);

		return allowedEntities.some(
			(allowed: boolean) =>
				allowed === true);
	}

	/**
	 * Used in the common list commands to handle
	 * the action click event.
	 *
	 * @param {number} fileId
	 * The instance id of the file selected.
	 * @param {string} viewMode
	 * The view to show.
	 * @memberof FilesComponent
	 */
	public actionClick(
		fileId: number,
		viewMode: string): void
	{
		this.view = viewMode;

		this.fileSelected =
			this.commonListContext
				.data
				.data
				.find((item: ICommonListItem<IFileEntity>) =>
					item.item.id === fileId)
				.item;
	}

	/**
	 * Handles the file add or updated events.
	 * document component.
	 *
	 * @memberof FilesComponent
	 */
	 public async filesModified(): Promise<void>
	 {
		 this.refreshCommonListContext(
			await this.loadFiles(
				 this.enabledFilters,
				 this.sorter),
			 this);
	 }

	/**
	 * Handles the document removed event from the remove
	 * document component.
	 *
	 * @param {IFileEntity} fileInstance
	 * The file that was removed.
	 * @memberof FilesComponent
	 */
	public fileRemoved(fileInstance: IFileEntity): void
	{
		const indexToRemove: number =
			this.commonListContext.data.data
				.findIndex(item =>
					item.item.id === fileInstance.id);

		this.commonListContext.data.data
			.splice(indexToRemove, 1);

		this.sortList();
	}

	/**
	 * A method that handles any sorter change and refreshes data.
	 *
	 * @param {any} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListSort} sorter
	 * A value representing the enabled and selected sorter.
	 * @memberof FilesComponent
	 */
	public async handleSortingChange(
		source: any,
		sorter: ICommonListSort): Promise<void>
	{
		this.sorter = sorter;
		this.refreshCommonListContext(
			await this.loadFiles(
				this.enabledFilters,
				this.sorter),
			source);
	}

	/**
	 * A method that handles any filter change and refreshes data.
	 *
	 * @param {any} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListFilter[]} filters
	 * A collection of enabled and active filters.
	 * @memberof FilesComponent
	 */
	public async handleFilterChange(
		source: any,
		filters: ICommonListFilter[]): Promise<void>
	{
		this.enabledFilters = filters;

		this.refreshCommonListContext(
			await this.loadFiles(
				this.enabledFilters,
				this.sorter),
			source);
	}

	/**
	 * A method to generate the proper data promise for loading files data.
	 *
	 * @private
	 * @param {ICommonListFilter[]} [filters]
	 * A value representing the enabled filters to apply to the data promise
	 * query.
	 * @param {ICommonListSort} [sorter]
	 * A value representing the enabled sorter to apply to the data promise
	 * query.
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * A data promise with applied filters and sorter.
	 * @memberof FilesComponent
	 */
	private async loadFiles(
		filters?: ICommonListFilter[],
		sorter?: ICommonListSort): Promise<Observable<IEntityInstanceDto[]>>
	{
		if (this.categories.length === 0)
		{
			return new Observable<IEntityInstanceDto[]>(
				(subscriber: Subscriber<IEntityInstanceDto[]>) =>
				{
					subscriber.next([]);
					subscriber.complete();
					subscriber.unsubscribe();
				});
		}

		const fileGroups: EntityType[] = [];

		let idFilter: string = AppConstants.empty;

		let filter: string;
		const userIds: string[] = [];
		if (!AnyHelper.isNull(filters))
		{
			for (const item of filters)
			{
				const category: FileCategory = this.categories
					.find(_category => _category.value === item.value);

				try
				{
					this.entityInstanceApiService.entityInstanceTypeGroup =
						AppConstants.typeGroups.users;
					const userInstance: IEntityInstance =
						await this.entityInstanceApiService
							.getSingleQueryResult(
								`${AppConstants.commonProperties.userName}`
									+ ` eq '${item.name}'`,
								null);

					userIds.push(userInstance.id.toString());
				}
				catch
				{
					if (AnyHelper.isNull(category))
					{
						filter = AnyHelper.isNullOrWhitespace(filter)
							? item.value
							: `(${filter}) AND (${item.value})`;
					}
					else
					{
						fileGroups.push(<EntityType>category.entityType);
					}
				}
			}
		}

		// return empty if fileGroups > 1
		// Zero files can be in two groups at the same time
		if (fileGroups.length > 1)
		{
			return new Observable<IEntityInstanceDto[]>(
				(subscriber: Subscriber<IEntityInstanceDto[]>) =>
				{
					subscriber.next([]);
					subscriber.complete();
					subscriber.unsubscribe();
				});
		}

		idFilter = fileGroups.length === 0
			?	ApiFilterHelper.getEntityTypeFilter(
				this.categories.map((category: FileCategory) =>
					new EntityType(category.entityType)))
			: ApiFilterHelper
				.getEntityTypeFilter(fileGroups);

		idFilter = `(${idFilter})`;

		const stateFilter = `(status.state != "${FileState.Removed}")`;

		const baseFilter: string = fileGroups.length === 0
			? `${idFilter} AND ${stateFilter}`
			: stateFilter;

		const filterCriteria: string =
			ApiFilterHelper.getInQueryFilter(
				userIds,
				AppConstants.commonProperties.createdById,
				filter);

		let orderBy: string;
		if (AnyHelper.isNull(sorter) === false)
		{
			orderBy = `${sorter.value} ${sorter.direction}`;
		}

		const parameters: IQueryParameters =
			<IQueryParameters>
			{
				filter: AnyHelper.isNullOrEmpty(filterCriteria) === true
					? baseFilter
					: `${baseFilter} AND ${filterCriteria}`,
				orderBy: orderBy,
				limit: 100,
				last: null
			};

		return this.fileService
			.getFiles(
				parameters,
				this.parentId,
				this.parentTypeGroup,
				fileGroups.length > 0
					? fileGroups.map((item: EntityType) => item.group)
					: null);
	}

	/**
	 * sorts the common list data so a trip
	 * to the server can be avoided.
	 *
	 * @memberof FilesComponent
	 */
	private sortList(): void
	{
		const direction = this.sorter.direction;

		this.commonListContext.data.data
			.sort(
				(itemA: ICommonListItem<IFileEntity>,
					itemB: ICommonListItem<IFileEntity>) =>
				{
					if (itemA.item.createDate > itemB.item.createDate)
					{
						return direction ===
							AppConstants.sortDirections.descending
							? -1
							: 1;
					}

					return direction ===
						AppConstants.sortDirections.descending
						? 1
						: -1;
				});
	}

	/**
	 * A method to either create or refresh the common list context.
	 *
	 * @param {Observable<IEntityInstanceDto[]>} observable
	 * A value representing the observable that will load data into
	 * the context.
	 * @param {any} source
	 * A value representing the source component we are refreshing the context
	 * from.
	 * @memberof FilesComponent
	 */
	private refreshCommonListContext(
		observable: Observable<IEntityInstanceDto[]>,
		source: any): void
	{
		source.loading = true;
		let tripCount: number = 0;

		if (this.dataSubscription != null)
		{
			this.dataSubscription.unsubscribe();
		}

		this.dataSubscription = observable
			.subscribe({
				next:
					(list: IFileEntity[]) =>
					{
						const data: ICommonListItem<IFileEntity>[]
							= list?.map((item) => this.mapToListItem(item));
						if (++tripCount === 1)
						{
							this.commonListContext
								.data
								.data = data;
						}
						else
						{
							this.commonListContext
								.data
								.data
								.push(...data);
						}

						if (this.commonListContext
							.data
							.data
							.length > 0)
						{
							source.loading = false;

							if (AnyHelper.isFunction(
								source.performPostLoadActions))
							{
								source.performPostLoadActions();
							}
						}
					},
				error:
					(error: any) =>
					{
						this.activityService.generateActivityBanner(
							new Activity<void>(
								Promise.reject(error),
								null,
								null,
								null,
								error),
							AppConstants.activityStatus.error,
							'Error Loading Files');

					},
				complete:
					() =>
					{
						if (tripCount > 1)
						{
							this.sortList();
						}

						source.loading = false;
					}
			});
	}

	/**
	 * A method that generates the files common list context.
	 *
	 * @private
	 * @memberof FilesComponent
	 * @returns {ICommonListContext<IEntityInstanceDto>}
	 * A value representing the common list context for first load.
	 */
	private generateCommonListContext(): ICommonListContext<IFileEntity>
	{
		const actions: ISecureMenuItem[] = [];

		if (this.creatableCategories.length > 0)
		{
			actions.push(
				<ISecureMenuItem>
				{
					icon: 'fa fa-plus-circle',
					label: 'Add',
					command: (event: any) => {
						this.view = 'add';
						event.stopPropagation();
					},
					securityRightCategory: SecurityRightCategory.Action,
					securityRightPath: AppConstants.apiMethods.create
				});
		}

		const generatedCommonListContext: ICommonListContext<IFileEntity> =
			<ICommonListContext<IFileEntity>>
			{
				data: [],
				searchable: true,
				sortable: true,
				supportMarkdown: true,
				sorters: [
					this.sorter
				],
				searchFilterFormat:
					'((name.Contains(\'${inputValue}\') eq true)'
					+ ' OR (description.Contains(\'${inputValue}\') eq true)'
					+ ' OR (subType.Contains(\'${inputValue}\') eq true))',
				actions: actions,
				filters: <ICommonListFilter[]>this.categories,
				onFilterChange: (source, filters) =>
					this.handleFilterChange(source, filters),
				onSortChange: (source, sorter) =>
					this.handleSortingChange(source, sorter)
			};

		return generatedCommonListContext;
	}

	/**
	 * A method that maps entity instance data to a common list item.
	 *
	 * @private
	 * @param {IFileEntity} file
	 * A value representing the entity instance to map to common list item.
	 * @returns {ICommonListItem<IEntityInstanceDto>}
	 * A value representing the mapped common list item from the entity
	 * instance.
	 * @memberof FilesComponent
	 */
	private mapToListItem(file: IFileEntity): ICommonListItem<IFileEntity>
	{
		if (file.changedById !== 1)
		{
			this.fileService.instanceService
				.entityInstanceTypeGroup = AppConstants.typeGroups.users;

			this.fileService.instanceService
				.get(file.changedById)
				.then(
					(user: any) =>
					{
						if (AnyHelper.isNullOrWhitespace(
							user.data.userName) === false)
						{
							(<any>file.data).userName =
								<string>user.data.userName;
						}
					});
		}

		// Entity data as any so we can access/modify and also
		// populate entity data with extra decorators.
		const data: any = file.data;
		data.userName = file.changedById === 1
			? 'System'
			: 'User ' + file.changedById;

		data.changeDateTime = DateHelper
			.fromUtcIso(file.changeDate)
			.toISO();

		const category: FileCategory =
			this.categories
				.find(item =>
					item.entityType.name === file.entityType);

		const categoryName = AnyHelper.isNull(category)
			? 'Uncategorized'
			: category.name;

		const subType: string =
			AnyHelper.isNullOrWhitespace(file.data.subType)
				? AppConstants.empty
				: ` - ${file.data.subType}`;
		const status: string =
			AnyHelper.isNullOrWhitespace(file.data.status?.state)
				? AppConstants.empty
				: ` - <span class="font-bold">${file.data.status.state}`
					+ '</span>';
		const descriptionLine: string =
			'${item.data.name}<br>'
				+ '<span class="font-smaller theme-color-gray">'
				+ categoryName
				+ subType
				+ status
				+ '</span>';

		const listItem: ICommonListItem<IFileEntity> =
		{
			item: file,
			descriptionLineFormat: descriptionLine,
			informationLineRelativeDateTime:
				'${item.data.changeDateTime}',
			informationLineFormat:
				' by ${item.data.userName}',
			informationIcons: [],
			actions: []
		};

		const iconClass = AnyHelper
			.isNull(file
				.data
				.metadata
				.mimeType)
			? 'fa fa-exclamation theme-color-grey'
			: 'fa ' + FileHelper
				.getFileIconClass(file
					.data
					.metadata
					.mimeType);

		listItem.informationIcons
			.push(iconClass);

		listItem.actions.push(<ISecureMenuItem>{
			icon: 'fa fa-cloud-download',
			command: (event: any) => {
				this.actionClick(file.id, this.viewModes.download);
				event.stopPropagation();
			},
			securityRightCategory: SecurityRightCategory.Action,
			securityRightPath: AppConstants.workflowActions.fileDownload
		});

		listItem.actions.push(<ISecureMenuItem>{
			icon: 'fa fa-info-circle',
			command: (event: any) => {
				this.actionClick(file.id, this.viewModes.details);
				event.stopPropagation();
			},
			securityRightCategory: SecurityRightCategory.TopLevelRight,
			securityRightType: SecurityRightType.read
		});

		// if (you have create rights and you are the creator)
		// OR if (you have edit rights)
		listItem.actions.push(<ISecureMenuItem>{
			icon: 'fa fa-pencil',
			command: (event: any) => {
				this.actionClick(file.id, this.viewModes.edit);
				event.stopPropagation();
			},
			securityRightCategory: SecurityRightCategory.Action,
			securityRightPath: AppConstants.apiMethods.update
		});

		// if (you have create rights and you are the creator)
		// OR if (you have delete rights)
		listItem.actions.push(<ISecureMenuItem>{
			icon: 'fa fa-trash',
			command: (event: any) => {
				this.actionClick(file.id, this.viewModes.remove);
				event.stopPropagation();
			},
			securityRightCategory: SecurityRightCategory.Action,
			securityRightPath: AppConstants.apiMethods.delete
		});

		listItem.actions = SecurityHelper
			.scrubMenuItems(
				listItem.actions,
				file.entityType,
				this.securityDefinitions);

		return listItem;
	}

	/**
	 * Gets the creatable types within the supported types/categories.
	 *
	 * @private
	 * @param {FileCategory[]} supportedCategories
	 * The supported categories/types to look through
	 * @returns {FileCategory[]}
	 * The types/categories that user is allowed to create.
	 * @memberof FilesComponent
	 */
	private getCreatableTypes(
		supportedCategories: FileCategory[]): FileCategory[]
	{
		return supportedCategories
			.filter(
				(category: FileCategory) =>
					category
						.securityDefinition
						.rights
						.create === true
						&& SecurityHelper
							.getActionSecurityRights(
								AppConstants.workflowActions.fileUpload,
								category.securityDefinition.actions)
							.rights
							.execute === true
						&& SecurityHelper
							.getActionSecurityRights(
								'Create',
								category.securityDefinition.actions)
							.rights
							.execute === true);
	}

	/**
	 * Gets the readable and downloadable types within
	 * the supported types/categories.
	 *
	 * @private
	 * @param {FileCategory[]} supportedCategories
	 * The supported categories/types to look through
	 * @returns {FileCategory[]}
	 * The types/categories that user is allowed to read.
	 * @memberof FilesComponent
	 */
	private getReadableCategories(
		supportedCategories: FileCategory[]): FileCategory[]
	{
		return supportedCategories
			.filter(
				(category: FileCategory) =>
					category
						.securityDefinition
						.rights
						.read
				&& SecurityHelper
					.getActionSecurityRights(
						AppConstants.workflowActions.fileDownload,
						category.securityDefinition.actions)
					.rights
					.execute === true);
	}
}