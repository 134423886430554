/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	Component,
	OnDestroy,
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Params
} from '@angular/router';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	Subscription
} from 'rxjs';

@Component({
	selector: 'app-entity-layout-definition',
	templateUrl: './entity-layout-definition.component.html',
	styleUrls: [
		'./entity-layout-definition.component.scss'
	]
})

/**
 * A component representing an instance of the entity layout definitions
 * component.
 *
 * @export
 * @class EntityLayoutDefinitionComponent
 * @extends {EntityManagerDirective}
 * @implements {OnDestroy}
 */
export class EntityLayoutDefinitionComponent
	extends EntityManagerDirective
	implements OnDestroy
{
	/**
	 * Creates an instance of an EntityLayoutDefinitionComponent.
	 *
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The api service used to get the entity layout data.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The api service used to get the entity layout type data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity instance service used to populate the entity version data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityTypeApiService: EntityTypeApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets or sets the entity layout data.
	 *
	 * @type {IEntityLayout}
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public entityLayout: IEntityLayout;

	/**
	 * Gets or sets the entity layout type data.
	 *
	 * @type {IEntityLayoutType}
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public entityLayoutType: IEntityLayoutType;

	/**
	 * Gets or sets the entity layout id.
	 *
	 * @type {number}
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public layoutId: number;

	/**
	 * Gets or sets the subscriptions used in this component.
	 *
	 * @type {Subscription}
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public subscriptions: Subscription = new Subscription();

	/**
	 * Gets the layout id query parameter.
	 *
	 * @type {string}
	 * @memberof EntityLayoutDefinitionComponent
	 */
	private readonly layoutIdQueryParameter: string =
		AppConstants.commonProperties.layoutId;

	/**
	 * Gets the set of custom controls that do not require a key value.
	 *
	 * @type {string[]}
	 * @memberof EntityLayoutDefinitionComponent
	 */
	private readonly nonDataBasedControls: string[] =
		[
			FormlyConstants.customControls.customMessage,
			FormlyConstants.customControls.customSectionTitle,
			FormlyConstants.customControls.customDashboard,
			FormlyConstants.customControls.customTableDisplay,
			FormlyConstants.customControls.customSummaryCard
		];

	/**
	 * Saves the updated entity data.
	 *
	 * @async
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public async saveAction(): Promise<void>
	{
		const entityLayoutDataObject: IEntityLayout =
			{
				id: this.entityLayout.id,
				layoutTypeId: this.entityLayout.layoutTypeId,
				typeId: this.entityLayout.typeId,
				versionId: this.entityLayout.versionId,
				jsonData: this.contextData.data.definition,
				createDate: this.entityLayout.createDate,
				startDate: this.entityLayout.startDate,
				endDate: this.entityLayout.endDate
			};

		await this.entityLayoutApiService
			.update(
				this.entityLayout.id,
				entityLayoutDataObject);
	}

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.subscriptions.add(
			this.route.queryParams.subscribe(
				(parameters: Params) =>
				{
					const mappedRouteData: any =
						ObjectHelper.mapFromRouteData(
							parameters);

					this.layoutId =
						AnyHelper.isNullOrEmpty(
							mappedRouteData[this.layoutIdQueryParameter])
							? AppConstants.empty
							: mappedRouteData[this.layoutIdQueryParameter];
				}));

		this.entityLayout =
			await this.entityLayoutApiService
				.get(this.layoutId);

		this.entityLayoutType =
			await this.entityLayoutTypeApiService
				.get(this.entityLayout.layoutTypeId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityLayout.typeId);

		this.contextData = {
			data: {
				id: this.entityLayout.id,
				definition: this.entityLayout.jsonData
			}
		};

		this.saveTitle = 'Layout Definition';
		this.saveContent = `Layout ${this.entityLayoutType.name} Definition`;
	}

	/**
	 * On component destroy event.
	 * This method is used to remove any subscriptions.
	 *
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public ngOnDestroy(): void
	{
		this.subscriptions.unsubscribe();
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public setLayoutFields(): void
	{
		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.definition',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Definition',
						rows: FormlyConstants.textAreaRowSizes.fullSize,
						required: true
					},
					validators: {
						validDefinition: {
							expression: ((
								control: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.definitionValidator(
									control,
									field)),
							message:
								AppConstants.empty
						}
					}
				}
			];
	}

	/**
	 * Validates the definition is a correct input.
	 *
	 * @param {FormControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public definitionValidator(
		control: UntypedFormControl,
		field: FormlyFieldConfig): boolean
	{
		let jsonValue: FormlyFieldConfig[];

		// Checks if entry is an object type.
		try
		{
			jsonValue = JSON.parse(control.value);
		}
		catch
		{
			field.validators.validDefinition.message =
				'Not a valid Layout Object.';

			return false;
		}

		// Checks if entry is an array of layout objects.
		if (AnyHelper.isNullOrEmpty(jsonValue[0]))
		{
			field.validators.validDefinition.message =
				'Must be an array of layout objects.';

			return false;
		}

		// Checks if required properties are entered and the correct format
		// type.
		for (let index = 0; index < jsonValue.length ; index++)
		{
			if (AnyHelper.isNullOrEmpty(jsonValue[index].type))
			{
				if (AnyHelper.isNullOrEmpty(jsonValue[index].wrappers)
					|| jsonValue[index].wrappers[0] !== 'custom-tab-content')
				{
					field.validators.validDefinition.message =
						`'Type' is required at position ${[index]}`;

					return false;
				}
			}
			else
			{
				if (AnyHelper.isNullOrEmpty(
					jsonValue[index].props))
				{
					field.validators.validDefinition.message =
						'\'Template Options\' are required at position '
							+ `${index}`;

					return false;
				}
				else
				{
					if (typeof jsonValue[index].props !==
						AppConstants.propertyTypes.object)
					{
						field.validators.validDefinition.message =
							'\'Template Options\' must be format '
								+ AppConstants.propertyTypes.object
								+ ` at position ${index}`;

						return false;
					}
				}

				if (!this.nonDataBasedControls.includes(
					jsonValue[index].type.toString()))
				{
					if (AnyHelper.isNullOrEmpty(jsonValue[index].key))
					{
						field.validators.validDefinition.message =
							`'Key' is required at position ${index}`;

						return false;
					}
					else if (typeof jsonValue[index].key !==
						AppConstants.propertyTypes.string)
					{
						field.validators.validDefinition.message =
							'\'Key\' must be format '
								+ AppConstants.propertyTypes.string
								+ ` at position ${index}`;

						return false;
					}
				}

				break;
			}
		}

		return true;
	}
}