/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	cloneDeep
} from 'lodash-es';
import {
	OperationGroupComponent
} from './operation-group-expand/operation-group.component';
import { InsuranceService } from '@insurance/services/insurance.service';

/* eslint-disable max-len */

@Component({
	selector: 'app-operation-groups',
	templateUrl: './operation-groups.component.html',
	styleUrls: [
		'./operation-groups.component.scss'
	]
})

/**
 * A component representing an instance of the user interface operation
 * groups component.
 *
 * @export
 * @class OperationGroupsComponent
 * @extends {CommonTablePageDirective}
 */
export class OperationGroupsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the operation group component and sets up
	 * the services to be utilized within the component.
	 *
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load the operation group data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service to be displayed in the Activity List Component.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @param {InsuranceService} insuranceService
	 * The inusrance service.
	 * @memberof OperationGroupsComponent
	 */
	public constructor(
		public operationGroupApiService: OperationGroupApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService,
		private readonly insuranceService: InsuranceService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationGroupsComponent
	 */
	public operationGroupsTableDefinitions: ICommonTable;

	/**
	 * Gets the formly validator debounce time.
	 *
	 * @type {number}
	 * @memberof OperationGroupsComponent
	 */
	private readonly validatorDebounceTime: number = 250;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUserComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;

		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'label',
					columnHeader: 'Label',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'icon',
					columnHeader: 'Icon',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the list column definitions for the current
	 * operation definition object list.
	 *
	 * @async
	 * @memberof OperationGroupsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions(
				await this.insuranceService.getMasterSecurityGroups());

		const itemLayout: FormlyFieldConfig[] =
			[
				{
					key: 'name',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Name',
						description: 'Name',
						required: true,
						maxLength: 64
					},
					asyncValidators: {
						uniqueName: {
							expression: async(control: UntypedFormControl) =>
								this.allowToUpdateName(control.value),
							message: 'Existing Name.'
						}
					}
				},
				{
					key: 'label',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Label',
						description: 'Label',
						maxLength: 64
					}
				},
				{
					key: 'icon',
					type: FormlyConstants.customControls.customIconInput,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Icon',
						description: 'Icon',
						externalLinkTooltip: 'Font Awesome Icons',
						externalLink: AppConstants
							.externalUrls.fontAwesomeIcons,
						maxLength: 256
					}
				},
				{
					key: 'description',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Description',
						description: 'Description',
						required: true,
						maxLength: 256
					}
				},
				{
					...CommonFormlyFieldConstants
						.publicField
				},
				{
					...CommonFormlyFieldConstants
						.ownershipSecurityGroupField,
					props: {
						...CommonFormlyFieldConstants
							.ownershipSecurityGroupField
							.props,
						label: 'Group Owner(s)',
						options: securityGroupOptions,
						appendTo: FormlyConstants.appendToTargets.body
					}
				}
			];

		const createLayout: FormlyFieldConfig[] =
			[
				...cloneDeep(itemLayout).map(
					(layoutItem: FormlyFieldConfig) =>
						layoutItem.key === 'name'
							? {
								...layoutItem,
								asyncValidators: {
									uniqueName: {
										expression: (
											control: UntypedFormControl) =>
											this.isExistingName(control.value),
										message: 'Existing Name.'
									}
								}
							}
							: layoutItem)
			];

		this.operationGroupsTableDefinitions =
			{
				tableTitle: 'Groups',
				objectSearch:
					{
						filter: AppConstants.empty,
						orderBy: `Id ${AppConstants.sortDirections.descending}`,
						offset: 0,
						limit: AppConstants.dataLimits.large,
						virtualIndex: 0,
						virtualPageSize: this.tableRowCount
					},
				apiPromise:
					(objectSearch: IObjectSearch) =>
						this.operationGroupApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Operation Group'),
				commonTableContext:
					(commonTableContext:
						IDynamicComponentContext<CommonTableComponent, any>) =>
					{
						this.commonTableContext = commonTableContext;
					},
				actions: {
					create: {
						layout: createLayout,
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.createOperationGroup()
							}]
					},
					view: {
						component: OperationGroupComponent,
						layout:
							FormlyHelper.disableAllFields(
								[
									...cloneDeep(itemLayout)
								]),
						items: []
					},
					update: {
						component: OperationGroupComponent,
						layout:
							FormlyHelper.disableAllFields(
								[
									...cloneDeep(itemLayout)
								],
								false),
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.updateOperationGroup()
							}]
					},
					delete: {
						items: [
							{
								label: 'Confirm',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								disabled: false,
								command: () => this.deleteOperationGroup()
							}],
						deleteStatement: () => this.deleteStatementLogic(),
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates an operation definition.
	 *
	 * @async
	 * @memberof OperationGroupsComponent
	 */
	private async createOperationGroup(): Promise<void>
	{
		const operationGroup: IOperationGroup =
			<IOperationGroup>this.commonTableContext.source.selectedItem;

		const createOperationGroup: () => Promise<void> =
			async () =>
			{
				operationGroup.id =
					await this.operationGroupApiService
						.create(
							operationGroup);

				this.commonTableContext.source.selectedItem =
					await this.operationGroupApiService
						.get(operationGroup.id);

				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createOperationGroup(),
				'<strong>Creating Operation Group</strong>',
				'<strong>Created Operation Group</strong>',
				`Operation Group ${operationGroup.name}
					was successfully created.`,
				`Operation Group ${operationGroup.name}
					was not created.`));
	}

	/**
	 * Updates an operation definition.
	 *
	 * @async
	 * @memberof OperationGroupsComponent
	 */
	private async updateOperationGroup(): Promise<void>
	{
		const operationGroup: IOperationGroup =
			<IOperationGroup>this.commonTableContext.source.selectedItem;

		const updateOperationGroup: () => Promise<void> =
			async () =>
			{
				await this.operationGroupApiService
					.update(
						operationGroup.id,
						operationGroup);

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateOperationGroup(),
				'<strong>Updating Operation Group</strong>',
				'<strong>Updated Operation Group</strong>',
				`Operation Group ${operationGroup.id}
					was updated.`,
				`Operation Group ${operationGroup.id}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Deletes an operation groups.
	 *
	 * @async
	 * @memberof OperationGroupsComponent
	 */
	private async deleteOperationGroup(): Promise<void>
	{
		const operationGroup: IOperationGroup =
			<IOperationGroup>this.commonTableContext.source.selectedItem;

		const deleteOperationGroup: () => Promise<void> =
			async () =>
			{
				await this.operationGroupApiService
					.delete(operationGroup.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteOperationGroup(),
				'<strong>Deleting Operation Group</strong>',
				'<strong>Deleted Operation Group</strong>',
				`Operation Group ${operationGroup.id} ` +
					'was deleted.',
				`Operation Group ${operationGroup.id} ` +
					'was not deleted.'));
	}

	/**
	 * Defines and validates if the value is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The Name value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationGroupsComponent
	 */
	private async isExistingName(value: string): Promise<boolean>
	{
		const operationGroup =
			await this.operationGroupApiService
				.query(
					`Name eq '${value}'`,
					AppConstants.empty);

		return new Promise(
			(resolve) =>
			{
				setTimeout(
					() => resolve(operationGroup.length === 0),
					this.validatorDebounceTime);
			});
	}

	/**
	 * Defines and validates if the value is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The Name value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationGroupsComponent
	 */
	private async allowToUpdateName(
		value: string): Promise<boolean>
	{
		let  allowedToUpdate: boolean = true;
		const persistedGroup: any =
			await this.operationGroupApiService
				.get(this.commonTableContext.source.selectedItem.id);

		if (persistedGroup.name !== value)
		{
			const operationGroup =
				await this.operationGroupApiService
					.query(
						`Name eq '${value}'`,
						AppConstants.empty);

			allowedToUpdate = operationGroup.length <= 0;
		}

		return new Promise(
			(resolve) =>
			{
				setTimeout(
					() => resolve(allowedToUpdate),
					this.validatorDebounceTime);
			});
	}

	/**
	 * Returns a delete statement string
	 * based on if there are any children relationship.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement to be displayed on te component.
	 * @memberof OperationGroupsComponent
	 */
	private async deleteStatementLogic(): Promise<string>
	{
		const childRelationship = await this.operationGroupApiService
			.getChildren(this.commonTableContext.source.selectedItem.id);

		this.operationGroupsTableDefinitions.actions.delete.items[0]
			.disabled = childRelationship.length > 0;

		return childRelationship.length > 0
			? `Unable to delete Group
				${this.commonTableContext.source.selectedItem.id}
				${this.commonTableContext.source.selectedItem.name}
				due to child relationships.`
			: `Confirm you are about to delete Group
				${this.commonTableContext.source.selectedItem.id}
				${this.commonTableContext.source.selectedItem.name}.`;
	}
}