/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';

/**
 * A class representing a factory that can be used for common dropdown option
 * creation in user interface displays.
 *
 * @export
 * @class OptionsFactory
 */
@Injectable({
	providedIn: 'root'
})
export class OptionsFactory
{
	/**
	 * Initializes a new instance of an options factory.
	 *
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The security group api server.
	 * @memberof OptionsFactory
	 */
	public constructor(
		private readonly securityGroupApiService: SecurityGroupApiService)
	{
	}

	/**
	 * Creates and returns a set of dropdown options that represent available
	 * security groups.
	 *
	 * @async
	 * @param {ISecurityGroup[]} [securityGroups=null]
	 * An optional set of security groups to use for the dropdown options.
	 * @returns {Promise<IDropdownOption[]}
	 * An awaitable set of options for display in a dropdown.
	 * @memberof OptionsFactory
	 */
	public async getSecurityGroupOptions(
		securityGroups: ISecurityGroup[] = null): Promise<IDropdownOption[]>
	{
		const loadedSecurityGroups: ISecurityGroup[] =
			securityGroups
				?? await ApiHelper.getFullDataSet(
					this.securityGroupApiService,
					AppConstants.empty,
					AppConstants.commonProperties.name,
					null,
					AppConstants.dataLimits.maxResultSet);

		return loadedSecurityGroups
			.map(
				(item: ISecurityGroup) =>
					<IDropdownOption>
					{
						label: item.name,
						value: item.id
					});
	}
}