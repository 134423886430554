/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable,
	Injector
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	ApiTokenLookup
} from '@api/api-token.lookup';
import {
	BaseApiService
} from '@api/services/base/base.api.service';
import {
	ClaimsTokenLookup
} from '@claims/claims.token-lookup';
import {
	InsuranceHelper
} from '@insurance/helpers/insurance.helper';
import {
	InsuranceTokenLookup
} from '@insurance/insurance.token-lookup';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	ChartHelper
} from '@shared/helpers/chart.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	RuleHelper
} from '@shared/helpers/rule.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SharedTokenLookup
} from '@shared/shared-token.lookup';
import {
	WorkItemTokenLookup
} from '@workItems/work-item-token.lookup';

/**
 * A singleton service representing the ability to resolve providers
 * in the application by name.
 *
 * @export
 * @class ResolverService
 */
@Injectable({
	providedIn: 'root'
})
export class ResolverService
{
	/**
	 * Creates an instance of a resolver service.
	 *
	 * @param {Router} router
	 * The router available from the resolver service.
	 * @param {Injector} injector
	 * The injector used to instantiate and resolve dynamic providers.
	 * @memberof ResolverService
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		private readonly injector: Injector)
	{
	}

	/**
	 * Gets the list of defined and allowed helper classes available
	 * in this resolver service.
	 *
	 * @type {object}
	 * @memberof ResolverService
	 */
	public readonly helperClasses:
	{
		anyHelper: string;
		apiFilterHelper: string;
		apiHelper: string;
		chartHelper: string;
		dateHelper: string;
		formlyHelper: string;
		insuranceHelper: string;
		objectArrayHelper: string;
		objectHelper: string;
		ruleHelper: string;
		stringHelper: string;
		eventHelper: string;
	} = {
			anyHelper: 'AnyHelper',
			apiFilterHelper: 'ApiFilterHelper',
			apiHelper: 'ApiHelper',
			chartHelper: 'ChartHelper',
			dateHelper: 'DateHelper',
			formlyHelper: 'FormlyHelper',
			insuranceHelper: 'InsuranceHelper',
			objectArrayHelper: 'ObjectArrayHelper',
			objectHelper: 'ObjectHelper',
			ruleHelper: 'RuleHelper',
			stringHelper: 'StringHelper',
			eventHelper: 'EventHelper'
		};

	/**
	 * Resolves api services via the api service name.
	 *
	 * @param {string} providerName
	 * The api service to resolve.
	 * @returns {BaseApiService}
	 * An instance of the requested api service.
	 * @memberof ResolverService
	 */
	public resolveApiService(
		providerName: string): BaseApiService
	{
		return this.injector.get<BaseApiService>(
			ApiTokenLookup.tokens[providerName]);
	}

	/**
	 * Resolves a common router for use in the resolver.
	 *
	 * @returns {Router}
	 * The router to be used for resolver based navigation.
	 * @memberof ResolverService
	 */
	public resolveRouter(): Router
	{
		return this.router;
	}

	/**
	 * Resolves shared classes via the shared token lookup name.
	 *
	 * @param {string} providerName
	 * The shared class name to resolve.
	 * @returns {any}
	 * An instance of the requested shared class.
	 * @memberof ResolverService
	 */
	public resolveShared(
		providerName: string): any
	{
		return this.injector.get<any>(
			SharedTokenLookup.tokens[providerName]);
	}

	/**
	 * Resolves static helper methods via method name and
	 * sent parameters.
	 *
	 * @param {string} helperName
	 * The helper class to use.
	 * @param {string} helperMethod
	 * The method to call.
	 * @param {any[]} helperParameters
	 * The parameters to send to this method.
	 * @returns {any}
	 * The return value of the called static method.
	 * @memberof ResolverService
	 */
	public resolveStatic(
		helperName: string,
		helperMethod: string,
		helperParameters: any[]): any
	{
		switch (helperName)
		{
			case this.helperClasses.anyHelper:
				return AnyHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.apiFilterHelper:
				return ApiFilterHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.apiHelper:
				return ApiHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.chartHelper:
				return ChartHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.dateHelper:
				return DateHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.formlyHelper:
				return FormlyHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.insuranceHelper:
				return InsuranceHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.objectHelper:
				return ObjectHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.objectArrayHelper:
				return ObjectArrayHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.ruleHelper:
				return RuleHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.stringHelper:
				return StringHelper[helperMethod](
					...helperParameters);
			case this.helperClasses.eventHelper:
				return EventHelper[helperMethod](
					...helperParameters);
			default: throw new Error(
				`The ${helperName} is not yet available via the `
					+ 'resolver service.');
		}
	}

	/**
	 * Resolves an activity.
	 *
	 * @async
	 * @param {Promise<unknown>} activity
	 * The activity to handle by the Activity Service.
	 * @param {string} pendingMessage
	 * The pending message.
	 * @param {string} completeMessage
	 * The complete message.
	 * @param {string} successDetails
	 * The success details.
	 * @param {string} failureDetails
	 * The failure details.
	 * @memberof ResolverService
	 */
	public async resolveActivity(
		activity: Promise<unknown>,
		pendingMessage: string,
		completeMessage: string,
		successDetails: string,
		failureDetails: string,
		throwException: boolean = false): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				activity,
				`<strong>${pendingMessage}</strong>`,
				`<strong>${completeMessage}</strong>`,
				`${successDetails}`,
				`${failureDetails}`),
			AppConstants.activityStatus.complete,
			throwException);
	}

	/**
	 * Resolves the current user value as an IUser.
	 *
	 * @returns {IUser}
	 * The current application IUser.
	 * @memberof ResolverService
	 */
	public resolveCurrentUser(): IUser
	{
		return this.injector.get<SessionService>(SessionService).user;
	}

	/**
	 * Resolves insurance services via the service name.
	 *
	 * @param {string} providerName
	 * The insurance service to resolve.
	 * @returns {any}
	 * An instance of the requested insurance class.
	 * @memberof ResolverService
	 */
	public resolveInsurance(
		providerName: string): any
	{
		return this.injector.get<any>(
			InsuranceTokenLookup.tokens[providerName]);
	}

	/**
	 * Resolves claims services via the service name.
	 *
	 * @param {string} providerName
	 * The claims service to resolve.
	 * @returns {any}
	 * An instance of the requested claims class.
	 * @memberof ResolverService
	 */
	public resolveClaims(
		providerName: string): any
	{
		return this.injector.get<any>(
			ClaimsTokenLookup.tokens[providerName]);
	}

	/**
	 * Resolves work item services via the service name.
	 *
	 * @param {string} providerName
	 * The work item service to resolve.
	 * @returns {any}
	 * An instance of the requested work item class.
	 * @memberof ResolverService
	 */
	public resolveWorkItems(
		providerName: string): any
	{
		return this.injector.get<any>(
			WorkItemTokenLookup.tokens[providerName]);
	}
}