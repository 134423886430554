<p-scrollPanel styleClass="file-container">
	<div class="ui-g-12 text-left">
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				Type:
			</strong>
			{{category}}
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				Subtype:
			</strong>
			{{subType}}
		</div>
		<div
			*ngFor="let property of customMetadata"
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				{{property.key}}:
			</strong>
			{{property.value}}
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				Description:
			</strong>

			<div
				class="ui-g-12 no-padding panel-text">
				{{fileEntity.data.description}}
			</div>
		</div>
		<div
			class="ui-g-12 no-horizontal-padding theme-color-gray font-italic">
			{{sizeFormatted}}
			<br />
			Created on {{createDate}}
			<br />
			Modified on {{ fileEntity.changeDate | dateTimeFromIso | dateTimeToFormat:'ccc MMM dd yyyy' }} by {{fileEntity.data.userName}}
			<br />
			({{ fileEntity.changeDate | dateTimeFromIso | dateTimeToRelative:{ style: 'long' } }})
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: medium">
				Print and Mail History
			</strong>
		</div>
		<div class="ui-g-12 no-horizontal-padding">
			<div *ngIf="loadingTableDefinitions"
				class="text-center ui-g-12 no-horizontal-padding">
				<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
				</span>
			</div>
			<div *ngIf="!loadingTableDefinitions"
				class="ui-g-12 table-container no-horizontal-padding">
				<app-common-table
					[tableDefinitions]="printAndMailHistoryTableDefinitions">
				</app-common-table>
			</div>
		</div>
	</div>
</p-scrollPanel>