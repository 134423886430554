/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'custom-summary-card',
	templateUrl: './custom-summary-card.component.html',
	styleUrls: [
		'./custom-summary-card.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Summary Card.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomSummaryCardComponent
 * @extends {ExtendedCustomControlDirective}
 */
export class CustomSummaryCardComponent
	extends ExtendedCustomControlDirective
{
	/**
	 * Initializes a new instance of the CustomSummaryCardComponent. This
	 * explicit constructor was requested during an upgrade process and
	 * should be left as implemented.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomSummaryCardComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the summary card configuration.
	 *
	 * @type {any}
	 * @memberof CustomSummaryCardComponent
	 */
	public summaryCardConfiguration: any;

	/**
	 * Gets or sets the summary card
	 * data promise.
	 *
	 * @type {Promise<any>}
	 * @memberof CustomSummaryCardComponent
	 */
	public dataPromise: Promise<any>;

	/**
	 * Gets or sets the summary card
	 * loading status thruthy.
	 *
	 * @type {boolean}
	 * @memberof CustomSummaryCardComponent
	 */
	public loading: boolean = true;

	/**
	 * On component initialization event.
	 * This method is used to transform the card data string
	 * into a promise and to set the summary card configuration.
	 *
	 * @async
	 * @memberof CustomSummaryCardComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.dataPromise = StringHelper.transformToDataPromise(
			StringHelper.interpolate(
				this.field.props.summaryCardConfiguration.dataPromise,
				this.field.props.context),
			this.field.props.context)

		this.summaryCardConfiguration =
			this.field.props.summaryCardConfiguration;

		super.ngOnInit();

		this.loading = false;
	}
}