<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template #Content>
	<div class="ui-g-12 no-padding drill-in-entity-display">
		<ng-template
			[ngIf]="(supportedEntityTypes?.length || 0) === 0"
			[ngIfElse]="Panel">
			<div class="ui-g-12 text-center">
				No available {{entityDisplayName}} types to add.
			</div>
		</ng-template>

		<ng-template #Panel>
			<div class="ui-g-12 text-left no-padding">
				<div class="ui-g-12 nested-display-subtitle text-left">
					<div class="ellipsis-text">
						Add: {{entityDisplayName}}
					</div>
				</div>

				<div class="ui-g-12 custom-field-wrapper text-left type-wrapper"
					*ngIf="formlyEntityLayout == null"
					[@contentAnimation]="state">
					<div class="input-label">
						<label for="entityType">
							<strong>
								Type
							</strong>
						</label>
					</div>
					<p-dropdown
						styleClass="full-width"
						inputId="entityType"
						[options]="entityTypes"
						placeholder="Select a Creation Type"
						showClear=true
						[filter]="true"
						optionLabel="label"
						optionValue="value"
						filterBy="label"
						filterPlaceholder="Filter"
						[autoDisplayFirst]="false"
						[(ngModel)]="selectedEntityType"
						(onChange)="handleEntityTypeChange($event)"
						[disabled]="(supportedEntityTypes?.length || 1) === 1">
					</p-dropdown>
				</div>

				<p-scrollPanel styleClass="entity-container">
					<div class="ui-g-12 no-padding data-panel"
						*ngIf="(selectedEntityType != null)
							&& (formlyEntityLayout != null)"
						[@contentAnimation]="state">
						<app-dynamic-formly
							[dataSet]="entityData"
							[layoutSchema]="formlyEntityLayout"
							[context]="context"
							(validityChanged)="validDataChanged($event)">
						</app-dynamic-formly>
					</div>
				</p-scrollPanel>
			</div>

			<div class="ui-g-12 no-padding text-right button-set">
				<a
					class="text-link theme-color secondary-action-button"
					(click)="changeDisplayMode.emit('List')">
					Cancel
				</a>
				<button
					pButton type="button"
					label="Add"
					class="no-margin-right primary-action-button"
					(click)="createEntity()"
					[disabled]="isValid === false || saving === true">
				</button>
			</div>
		</ng-template>
	</div>

</ng-template>