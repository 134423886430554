/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';

@Component({
	selector: 'custom-input-number',
	templateUrl: './custom-input-number.component.html',
	styleUrls: [
		'./custom-input-number.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Input Number.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputNumberComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomInputNumberComponent
	extends ExtendedCustomControlDirective
	implements OnInit
{
	/** Initializes a new instance of the CustomInputNumberComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputNumberComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/** Gets or sets the display value for the percent input control.
	 *
	 * @type {string}
	 * @memberof CustomInputNumberComponent
	 */
	public percentDisplay: string = AppConstants.empty;

	/** Gets the default maximum percent value.
	 *
	 * @type {number}
	 * @memberof CustomInputNumberComponent
	 */
	private readonly defaultMaximumPercent: number = 100;

	/** Gets the default maximum percent decimal precision.
	 *
	 * @type {number}
	 * @memberof CustomInputNumberComponent
	 */
	private readonly defaultMaximumPercentDecimalPrecision: number = 2;

	/** Gets the decimal precision offset.
	 *
	 * @type {number}
	 * @memberof CustomInputNumberComponent
	 */
	private readonly decimalPrecisionOffset: number = 2;

	/** Implements the OnInit interface.
	 * This method Initializes the component for use percent based logic.
	 *
	 * @memberof CustomInputNumberComponent
	 */
	public ngOnInit(): void
	{
		if (this.field.props.usePercent !== true)
		{
			super.ngOnInit();

			return;
		}

		this.field.props.maxPercent =
			this.field.props.maxPercent ?? this.defaultMaximumPercent;
		this.field.props.maxPercentDecimalPrecision =
			this.field.props.maxPercentDecimalPrecision
				?? this.defaultMaximumPercentDecimalPrecision;
		this.field.props.setPercentageValue =
			this.setPercentageValue.bind(this);

		if (!AnyHelper.isNullOrWhitespace(this.field.formControl.value))
		{
			this.percentDisplay =
				(parseFloat(this.field.formControl.value) * 100)
					.toFixed(this.field.props.maxPercentDecimalPrecision)
					.toString();
		}

		super.ngOnInit();
	}

	/** Handles the keydown event sent from the input number control when
	 * using the percent display.
	 *
	 * @param {KeyboardEvent} event
	 * The event sent from the keydown handler.
	 * @param {string} explicitValue
	 * The explicit percentage value.
	 * @returns {boolean}
	 * If allowed to proceeed with percentage value update.
	 * @memberof CustomInputNumberComponent
	 */
	public onPercentKeyDown(
		event: KeyboardEvent,
		explicitValue: string = null): boolean
	{
		const inputElement: HTMLInputElement =
			event.target as HTMLInputElement;

		if (AnyHelper.isNull(event.key)
			&& AnyHelper.isNull(explicitValue))
		{
			return true;
		}

		if (!this.confirmAllowedInputValue(
			event.key ?? explicitValue))
		{
			return this.cancelFieldUpdate(event);
		}

		const newValue: string =
			!AnyHelper.isNullOrEmpty(event.key)
				? this.getKeyDownExpectedValue(
					inputElement,
					event)
				: explicitValue;
		const formattedValue: string =
			this.filterDigitsAndFirstPeriod(
				newValue);
		const parsedNumber: number =
			parseFloat(
				formattedValue);

		// Only allow valid numbers and decimals.
		if (isNaN(parsedNumber))
		{
			return this.cancelFieldUpdate(event);
		}

		// Only allow less than the max percent.
		if (parsedNumber > this.field.props.maxPercent)
		{
			return this.cancelFieldUpdate(event);
		}

		// Only allow a fixed decimal precision.
		if(parseFloat(
			parsedNumber
				.toFixed(
					this.field.props.maxPercentDecimalPrecision))
			.toString() !== parsedNumber.toString())
		{
			return this.cancelFieldUpdate(event);
		}

		return true;
	}

	/** Confirms the value being sent is an allowed
	 * input for percent displays.
	 *
	 * @param {string} inputValue
	 * The input value.
	 * @returns {boolean}
	 * Confirmation true or false if an allowed key press.
	 * @memberof CustomInputNumberComponent
	 */
	public confirmAllowedInputValue(
		inputValue: string): boolean
	{
		const allowedKeys: string[] =
			[
				'Backspace',
				'ArrowLeft',
				'ArrowRight',
				'Tab',
				'Delete'
			];
		const isDigit: boolean =
			inputValue >= '0' && inputValue <= '9';
		const isValidPeriod: boolean =
			inputValue === AppConstants.characters.period
				&& !this.percentDisplay.includes(
					AppConstants.characters.period);

		return isDigit
			|| isValidPeriod
			|| allowedKeys.includes(inputValue);
	}

	/** Calculates what the keydown event will set for an expected value. This
	 * is used to determine if the keydown event should be allowed.
	 *
	 * @param {HTMLInputElement} inputElement
	 * The input element to get the expected value.
	 * @param {KeyboardEvent} event
	 * The event sent from the keydown handler.
	 * @returns {string}
	 * The expected value after the keydown event.
	 * @memberof CustomInputNumberComponent
	 */
	public getKeyDownExpectedValue(
		inputElement: HTMLInputElement,
		event: KeyboardEvent): string
	{
		let newValue: string;

		// Replace highlighted text.
		if (inputElement.selectionStart > 0 && inputElement.selectionEnd > 0)
		{
			const start: number =
				inputElement.selectionStart;
			const end: number =
				inputElement.selectionEnd;
			const valueBefore: string =
				inputElement.value.substring(
					0,
					start);
			const valueAfter: string =
				inputElement.value.substring(
					end);
			newValue =
				valueBefore
					+ event.key
					+ valueAfter;
		}
		else
		{
			// Else concatenate the new character.
			newValue =
				inputElement.value
					+ event.key;
		}

		return newValue;
	}

	/** Handles the keyup event sent from the input number control when
	 * using the percent display. This will be used to set the data
	 * model value as a 0-1 based percent from the displayed whole number
	 * percent values.
	 *
	 * @param {KeyboardEvent} event
	 * The event sent from the keyup handler.
	 * @memberof CustomInputNumberComponent
	 */
	public setPercentDisplay(
		event: KeyboardEvent): void
	{
		this.field.formControl.setValue(
			!AnyHelper.isNullOrWhitespace(this.percentDisplay)
				? parseFloat((parseFloat(this.percentDisplay) / 100)
					.toFixed(this.field.props.maxPercentDecimalPrecision
						+ this.decimalPrecisionOffset))
				: null);

		this.validateControl();
		if (!AnyHelper.isNull(this.field.props.change))
		{
			this.field.props.change(
				this.field,
				event);
		}

		this.updateCustomDisplay();
	}

	/** Handles the onInput event sent from the input number control.
	 *
	 * @param {any} event
	 * The event sent from the onInput handler.
	 * @memberof CustomInputNumberComponent
	 */
	public onChange(
		event: any): void
	{
		if (event.originalEvent?.code ===
			AppConstants.keyBoardKeyConstants.backspace
				&& event.value === 0)
		{
			this.field.formControl.setValue(null);
		}
		else
		{
			this.field.formControl.setValue(event.value);
		}

		this.validateControl();

		if (!AnyHelper.isNull(this.field.props.change))
		{
			this.field.props.change(
				this.field,
				event);
		}
	}

	/** Handles the onBlur event when losing focus
	 * from the input number control.
	 *
	 * @memberof CustomInputNumberComponent
	 */
	public onBlur(): void
	{
		if (!AnyHelper.isNullOrEmpty(
			this.field.props?.roundToNearest))
		{
			const currentValue: number =
				this.field.formControl.value;

			const roundToNearest: number =
				this.field.props.roundToNearest;

			const roundedValue: number =
				Math.round(currentValue / roundToNearest) * roundToNearest;

			setTimeout(
				() =>
				{
					this.field.formControl.setValue(roundedValue);
					this.validateControl();
				},
				AppConstants.time.quarterSecond);
		}
	}

	/** Filters the digits and the first period from the value.
	 *
	 * @param {string} value
	 * The value to filter.
	 * @returns {string}
	 * The filtered value.
	 * @memberof CustomInputNumberComponent
	 */
	private filterDigitsAndFirstPeriod(
		value: string): string
	{
		// Remove all non-digit characters.
		let digitDecimalValue: string =
			value.replace(
				/[^\d.]/g,
				AppConstants.empty);
		// Remove all periods except the first one.
		digitDecimalValue =
			digitDecimalValue.replace(
				/(?!^)(?<=\..*)\./g,
				AppConstants.empty);

		return digitDecimalValue;
	}

	/** Handles the explicit percentage value set.
	 * This method will be called from external source
	 * and not html input interaction.
	 *
	 * @param {string} value
	 * The percentage value.
	 * @memberof CustomInputNumberComponent
	 */
	private setPercentageValue(
		value: string = null): void
	{
		if (!this.onPercentKeyDown(
			<KeyboardEvent>{},
			value))
		{
			return;
		}

		this.percentDisplay =
			!AnyHelper.isNull(value)
				? (parseFloat(value))
					.toFixed(this.field.props.maxPercentDecimalPrecision)
					.toString()
				: null;

		this.setPercentDisplay(
			<KeyboardEvent>{});
	}

	/** Handles the field update cancellation by
	 * preventing event propagation and returning false
	 * for any explicit value set.
	 *
	 * @param {KeyboardEvent} event
	 * The keyboard event.
	 * @returns {boolean}
	 * Cancels any field update.
	 * @memberof CustomInputNumberComponent
	 */
	private cancelFieldUpdate(
		event: KeyboardEvent): boolean
	{
		if(!AnyHelper.isNull(event.preventDefault))
		{
			event.preventDefault();
		}

		return false;
	}
}