/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	SecurityApplicationApiService
} from '@api/services/security/security-application.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecurityApplication
} from '@shared/interfaces/security/security-application.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	DateTime
} from 'luxon';

/* eslint-enable max-len */
@Component({
	selector: 'app-applications',
	templateUrl: './applications.component.html',
	styleUrls: [
		'./applications.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ApplicationsComponent
 * @extends {CommonTablePageDirective}
 */
export class ApplicationsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the ApplicationsComponent class.
	 *
	 * @param {SecurityApplicationApiService} securityApplicationApiService
	 * The api service used to load security application data.
	 * @param {SecuritySessionApiService} securitySessionApiService
	 * The api service used to load security session data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @param {InsuranceService} insuranceService
	 * The inusrance service.
	 * @memberof ApplicationsComponent
	 */
	public constructor(
		public securityApplicationApiService: SecurityApplicationApiService,
		public securitySessionsApiService: SecuritySessionApiService,
		public activityService: ActivityService,
		public optionsFactory: OptionsFactory,
		public resolver: ResolverService,
		public readonly insuranceService: InsuranceService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ApplicationsComponent
	 */
	public securityApplicationsTableDefinitions: ICommonTable;

	/**
	 * Sets up variables used in this admin page based filter.
	 *
	 * @memberof ApplicationsComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;

		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'apiKey',
					columnHeader: 'Api Key',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'enabled',
					columnHeader: 'Enabled',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof ApplicationsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions(
				await this.insuranceService.getMasterSecurityGroups());

		this.securityApplicationsTableDefinitions =
			{
				tableTitle: 'Applications',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Application'),
				objectSearch: {
					filter: this.tableFilterQuery,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise: (objectSearch: IObjectSearch) =>
					this.securityApplicationApiService
						.query(
							objectSearch.filter,
							objectSearch.orderBy,
							objectSearch.offset,
							objectSearch.limit),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						layout: [
							{
								key: 'name',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Name',
									disabled: false,
									required: true
								},
								asyncValidators: {
									uniqueName: {
										expression: (
											control: UntypedFormControl) =>
											this.uniqueNameValidator(control),
										message:
											'Application Name not available.'
									}
								}
							},
							{
								key: 'apiKey',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Api Key',
									disabled: false,
									required: true
								},
								asyncValidators: {
									uniqueKey: {
										expression: async (
											control: UntypedFormControl) =>
											this.uniqueKeyValidator(control),
										message:
											'Application Key not available.'
									}
								}
							},
							{
								key: 'enabled',
								type: FormlyConstants.customControls
									.customInputSwitch,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Enabled',
									default: false
								}
							},
							CommonFormlyFieldConstants.publicField,
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									appendTo:
										FormlyConstants.appendToTargets.body
								}
							}
						],
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.createApplication()

							}]
					},
					filter: {
						quickFilters:
							[
								{
									label: 'All Applications',
									value: AppConstants.empty
								},
								{
									label: 'Enabled Applications',
									value: 'Enabled eq true'
								},
								{
									label: 'Disabled Applications',
									value: 'Enabled eq false'
								}
							],
						selectedFilterValue: this.tableFilterQuery
					},
					view: {
						component: null,
						layout: [
							{
								key: 'name',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Name',
									disabled: true
								}
							},
							{
								key: 'apiKey',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Api Key',
									disabled: true
								}
							},
							{
								key: 'enabled',
								type: FormlyConstants.customControls
									.customInputSwitch,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Enabled',
									disabled: true
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									disabled: true
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									disabled: true
								}
							}
						],
						items: []
					},
					update: {
						layout: [
							{
								key: 'name',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Name',
									required: true
								},
								asyncValidators: {
									uniqueName: {
										expression:
											(control: UntypedFormControl) =>
												this.updateNameValidator(
													control),
										message:
											'Application Name not available.'
									}
								}
							},
							{
								key: 'enabled',
								type: FormlyConstants.customControls
									.customInputSwitch,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Enabled',
									default: false
								}
							},
							CommonFormlyFieldConstants.publicField,
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									appendTo:
										FormlyConstants.appendToTargets.body
								}
							}
						],
						definition: null,
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.updateApplication()
							}]
					},
					delete: {
						items: [
							{
								label: 'Confirm',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: () => this.deleteApplication(),
							}],
						deleteStatement: () => this.deleteStatement()
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Updates an existing application.
	 *
	 * @async
	 * @memberof ApplicationComponent
	 */
	public async updateApplication(): Promise<void>
	{
		const updateApplication: Function =
			async () =>
			{
				await this.securityApplicationApiService
					.update(
						this.commonTableContext.source.selectedItem.id,
						this.commonTableContext.source.selectedItem);

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateApplication(),
				'<strong>Updating Application</strong>',
				'<strong>Updated Application</strong>',
				`Application ${this.commonTableContext.source.selectedItem.id} `
					+ 'was updated.',
				`Application ${this.commonTableContext.source.selectedItem.id}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Validates if the the entered name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Formly form control.
	 * @return {Promise<any>}
	 * The validity to display or not a formly error and
	 * disable or enable the action.
	 * @memberof ApplicationComponent
	 */
	private async uniqueNameValidator(control: UntypedFormControl): Promise<any>
	{
		const applicationName:
			ISecurityApplication[] =
			await this.securityApplicationApiService
				.query(
					`Name eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(AnyHelper.isNullOrEmpty(applicationName[0]));
	}

	/**
	 * Validates if the the entered key is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Formly form control.
	 * @return {Promise<any>}
	 * The validity to display or not a formly error and
	 * disable or enable the action.
	 * @memberof ApplicationComponent
	 */
	private async uniqueKeyValidator(control: UntypedFormControl): Promise<any>
	{
		const applicationKey:
			ISecurityApplication[] =
			await this.securityApplicationApiService
				.query(
					`ApiKey eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(AnyHelper.isNullOrEmpty(applicationKey[0]));
	}

	/**
	 * Validates if the name is allowed to be saved.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Formly form control.
	 * @return {Promise<any>}
	 * The validity to display or not a formly error and
	 * disable or enable the action.
	 * @memberof ApplicationComponent
	 */
	private async updateNameValidator(control: UntypedFormControl): Promise<any>
	{
		const originalApplication:
			ISecurityApplication =
			await this.securityApplicationApiService
				.get(this.commonTableContext
					.source.selectedItem.id);

		const applicationName:
			ISecurityApplication[] =
			await this.securityApplicationApiService
				.query(
					`Name eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(
			control.value === originalApplication.name
				|| AnyHelper.isNullOrEmpty(applicationName[0]));
	}

	/**
	 * Create a new application.
	 *
	 * @async
	 * @memberof ApplicationComponent
	 */
	private async createApplication(): Promise<void>
	{
		const createApplication: Function =
			async () =>
			{
				const createdId: number =
					await this.securityApplicationApiService
						.create(
							this.commonTableContext.source.selectedItem);

				// Refresh this value for an accurate created by id.
				this.commonTableContext.source.selectedItem =
					await this.securityApplicationApiService
						.get(createdId);

				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createApplication(),
				'<strong>Creating Application</strong>',
				'<strong>Created Application</strong>',
				'Application was created.',
				'Application was not created.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Deletes an existing application.
	 *
	 * @async
	 * @memberof ApplicationComponent
	 */
	private async deleteApplication(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteApplication: Function =
			async () =>
			{
				await this.securityApplicationApiService
					.delete(selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteApplication(),
				'<strong>Deleting Application</strong>',
				'<strong>Deleted Application</strong>',
				`Application ${selectedItem.id} `
					+ 'was deleted.',
				`Application ${selectedItem.id}
					was not deleted.`));
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof ApplicationComponent
	 */
	private async deleteStatement(): Promise<string>
	{
		const currentIsoDateTime: string =
			DateTime.now()
				.toUTC()
				.toISO();

		const aMonthFromCurrentIsoDateTime: string =
			DateTime.now()
				.minus({ month: 1 })
				.toUTC()
				.toISO();

		const activeSessions =
			await this.securitySessionsApiService
				.aggregate(
					'Count',
					AppConstants.empty,
					'ApplicationId eq '
						+ `${this.commonTableContext.source.selectedItem?.id} `
						+ `AND ExpireDate < '${currentIsoDateTime}' AND `
						+ `ExpireDate > '${aMonthFromCurrentIsoDateTime}'`);

		return `Confirm you are about to delete Application
			${this.commonTableContext.source.selectedItem?.id}
			${(await this.securityApplicationApiService.get(
		this.commonTableContext.source.selectedItem?.id)).name}
			which had ${activeSessions[0].value}
			active sessions in the last 30 days.`;
	}
}