<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i
		class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template
	#Content>
		<div class="ui-g-12 text-left no-padding">
			<div
				class="ui-g-12 nested-display-subtitle text-left">
				<div class="ellipsis-text">
					{{displaySubTitle}}
				</div>
			</div>

			<div class="ui-g-12 no-padding profile-section">
				<app-dynamic-formly
					[dataSet]="productSettingsDataSet"
					[context]="context"
					[layoutSchema]="productSettingFormlyLayout"
					(validityChanged)="validDataChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>

		<div
			*ngIf="context.data.drawerComponent.displayMode != 'View'"
			class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="changeDisplayMode.emit('List')">
				Cancel
			</a>
			<button
				pButton
				type="button"
				[label]="actionButtonLabel"
				class="no-margin-right primary-action-button"
				(click)="executableAction()"
				[disabled]="isValid === false || updating === true">
			</button>
		</div>

</ng-template>