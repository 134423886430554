/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	DateTime,
	DurationUnit,
	Settings
} from 'luxon';

/**
 * A class containing static helper methods
 * for the date type.
 *
 * @export
 * @class DateHelper
 */
export class DateHelper
{
	/**
	 * Gets or sets preset formats to be used when accessing
	 * the date helper.
	 *
	 * @type {object}
	 * @memberof CommonListComponent
	 */
	public static readonly presetFormats:
	{
		dateAndHourFormat: string;
		dayOfWeekAndTimeFormat: string;
		internationalDateFormat: string;
		internationalFullDateFormat: string;
		longDateFormat: string;
		shortDateFormat: string;
	} = {
			dateAndHourFormat: 'D hh:mm a',
			dayOfWeekAndTimeFormat: 'ccc LLL d yyyy',
			internationalDateFormat: 'yyyy-MM-dd',
			internationalFullDateFormat: 'yyyy-MM-dd hh:mm:ss.ms',
			longDateFormat: 'D',
			shortDateFormat: 'M/d/yy'
		};

	/**
	 * Gets or sets some preset zones to be used when accessing
	 * the date helper.
	 *
	 * @type {object}
	 * @memberof CommonListComponent
	 */
	public static readonly presetZones:
	{
		utc: string;
	} = {
			utc: 'utc'
		};

	/**
	 * Gets or sets some preset time units as described by the Luxon
	 * documentation.
	 *
	 * @type {object}
	 * @memberof CommonListComponent
	 */
	public static readonly timeUnits:
	{
		year: string;
		month: string;
		day: string;
		hour: string;
		minute: string;
		second: string;
	} = {
			year: 'year',
			month: 'month',
			day: 'day',
			hour: 'hour',
			minute: 'minute',
			second: 'second'
		};

	/**
	 * Gets or sets some preset duration units as described by the Luxon
	 * documentation.
	 *
	 * @type {object}
	 * @memberof CommonListComponent
	 */
	public static readonly durationUnits:
	{
		years: DurationUnit;
		months: DurationUnit;
		days: DurationUnit;
		hours: DurationUnit;
		minutes: DurationUnit;
		seconds: DurationUnit;
	} = {
			years: 'years',
			months: 'months',
			days: 'days',
			hours: 'hours',
			minutes: 'minutes',
			seconds: 'seconds'
		};

	/**
	 * Returns a date object with the expected value added to the sent
	 * initial date.
	 * These are time units as defined by luxon as 'year', 'quarter', 'month',
	 * 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
	 *
	 * @static
	 * @param {DateTime} initialDate
	 * The initial date to alter by adding time units.
	 * @param {number} numberOfUnitsToAdd
	 * The number of time units to add to the sent date. If negative this will
	 * subtract those time units.
	 * @param {string} luxonTimeUnit
	 * This will add the number of time units in the sent time unit to the date
	 * value. This defaults to adding days.
	 * @returns {DateTime}
	 * A calculated date with the difference added to the initial date.
	 * @memberof DateHelper
	 */
	public static addTimeUnit(
		initialDate: DateTime,
		numberOfUnitsToAdd: number,
		luxonTimeUnit: string = this.timeUnits.day): DateTime
	{
		return initialDate
			.plus(
				JSON.parse(
					`{ "${luxonTimeUnit}": ${numberOfUnitsToAdd} }`));
	}

	/**
	 * Returns the time unit defined start value of the sent date.
	 * These are time units as defined by luxon as 'year', 'quarter', 'month',
	 * 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
	 *
	 * @static
	 * @param {DateTime} date
	 * The date value to get the time unit based start of.
	 * @param {string} luxonTimeUnit
	 * If sent this will calculate the differences in the sent time unit. This
	 * defaults to the start of the day.
	 * @returns {DateTime}
	 * A date value that has been set to the start of the sent time unit.
	 * @memberof DateHelper
	 */
	 public static startOf(
		date: DateTime,
		luxonTimeUnit: string = this.timeUnits.day): DateTime
	{
		return date.startOf(<any>luxonTimeUnit);
	}

	/**
	 * Returns the time unit defined end value of the sent date.
	 * These are time units as defined by luxon as 'year', 'quarter', 'month',
	 * 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
	 *
	 * @static
	 * @param {DateTime} date
	 * The date value to get the time unit based end of.
	 * @param {string} luxonTimeUnit
	 * If sent this will calculate the differences in the sent time unit. This
	 * defaults to the end of the day.
	 * @returns {DateTime}
	 * A date value that has been set to the luxon based end of the sent time
	 * unit.
	 * @memberof DateHelper
	 */
	public static endOf(
		date: DateTime,
		luxonTimeUnit: string = this.timeUnits.day): DateTime
	{
		return date.endOf(<any>luxonTimeUnit);
	}

	/**
	 * Returns a formatted date available as a string. This will format to
	 * the sent Luxon date format.
	 * These are date formats as defined by Luxon:
	 * https://github.com/moment/luxon/blob/master/docs/formatting.md
	 *
	 * @static
	 * @param {DateTime} date
	 * The date value to get the time unit based end of.
	 * @param {string} luxonDateFormat
	 * If sent this will return the date using the defined luxon date format.
	 * This defaults to a short date format.
	 * @returns {string}
	 * A formatted string value which represents the date input.
	 * @memberof DateHelper
	 */
	public static formatDate(
		date: DateTime,
		luxonDateFormat: string = this.presetFormats.shortDateFormat): string
	{
		return date.toFormat(luxonDateFormat);
	}

	/**
	 * Returns the differences in the sent time unit betwen the sent dates.
	 * These are time units as defined by luxon as 'year', 'quarter', 'month',
	 * 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
	 *
	 * @static
	 * @param {DateTime} startDate
	 * The start date for a time unit based difference.
	 * @param {DateTime} endDate
	 * The end date for a time unit based difference.
	 * @param {boolean} includeTimeUnit
	 * If sent and true, this will include the string representation of the
	 * time unit in the display. This defaults to false.
	 * @param {string} luxonTimeUnit
	 * If sent this will calculate the differences in the sent time unit. This
	 * defaults to the difference in days.
	 * @returns {string}
	 * A value for use in displaying a difference in dates.
	 * @memberof DateHelper
	 */
	public static getDateDifference(
		startDate: DateTime,
		endDate: DateTime,
		includeTimeUnit: boolean = false,
		luxonTimeUnit: string = this.timeUnits.day): string
	{
		const multipleTimeUnit: string =
			`${luxonTimeUnit}s`;

		const dateDifference: number =
			endDate.diff(
				startDate,
				<DurationUnit>multipleTimeUnit)
				.get(<DurationUnit>multipleTimeUnit);

		const timeUnit: string = dateDifference > 1
			? ` ${StringHelper.toProperCase(multipleTimeUnit)}`
			: ` ${StringHelper.toProperCase(luxonTimeUnit)}`;

		return dateDifference
			+ (includeTimeUnit === true
				? timeUnit
				: AppConstants.empty);
	}

	/**
	 * Gets the days from now.
	 * @param {string} isoString
	 * The ISO date string.
	 * @returns
	 * the days from now. (Negative if in the past).
	 */
	public static getDaysFromNow(
		isoString: string): number
	{
		const dateToCheck: DateTime =
			DateTime.fromISO(isoString);

		const diffString: string = this
			.getDateDifference(
				DateTime.now(),
				dateToCheck);

		return Number(diffString);
	}

	/**
	 * Returns the short date based string to display the date range of two
	 * dates in string format.
	 *
	 * @static
	 * @param {DateTime} startDate
	 * The start date for a date range value.
	 * @param {DateTime} endDate
	 * The end date for a date range value.
	 * @returns {string}
	 * A formatted string for use in displaying a range of dates.
	 * ie: '1/1/20 to 3/1/20'.
	 * @memberof DateHelper
	 */
	public static getDateRange(
		startDate: DateTime,
		endDate: DateTime): string
	{
		return `${startDate.toFormat(this.presetFormats.shortDateFormat)} to `
			+ `${endDate.toFormat(this.presetFormats.shortDateFormat)}`;
	}

	/**
	 * Converts any unix date format into utc.
	 *
	 * @static
	 * @param {number} unixDateTime
	 * The date value in unix format to get the time unit in utc format.
	 * @returns {DateTime}
	 * A date value from the sent unix milliseconds since 1970.
	 * @memberof DateHelper
	*/
	public static convertUnixToUTCDateTime(
		unixDateTime: number): DateTime
	{
		if (AnyHelper.isNull(unixDateTime))
		{
			return null;
		}

		return DateTime.fromSeconds(
			unixDateTime,
			{
				zone: this.getLocalTimeZone()
			});
	}

	/**
	 * Calculates and returns a number of business days from two DateTimes.
	 *
	 * @static
	 * @param {DateTime} startDate
	 * The start date used to calculate a number of business days.
	 * @param {DateTime} startDate
	 * The end date used to calculate a number of business days.
	 * @returns {number}
	 * The number of business days.
	 * @memberof DateHelper
	*/
	public static getBusinessDaysFromRange(
		startDate: DateTime,
		endDate: DateTime): number
	{
		// Note: At this time, we are only removing weekends from business days.
		// Holidays will be handled at a future time from system settings.
		let workDays: number = 0;
		let currentDate: DateTime = startDate;
		while (currentDate < endDate)
		{
			if (currentDate.weekday !== 6 && currentDate.weekday !== 7)
			{
				workDays++;
			}

			currentDate = currentDate.plus({ day: 1 });
		}

		return workDays;
	}

	/**
	 * Calculates and returns a system based DateTime from a sent ISO compliant
	 * string that holds a utc valid format.
	 *
	 * @static
	 * @param {string} isoString
	 * The ISO compliant string.
	 * @returns {DateTime}
	 * The system DateTime object representing the sent utc ISO string.
	 * @memberof DateHelper
	*/
	public static fromUtcIso(
		isoString: string): DateTime
	{
		return DateTime.fromISO(
			isoString,
			<any>
			{
				zone: DateHelper.presetZones.utc
			})
			.toLocal();
	}

	/**
	 * Calculates and returns the current local system time zone based DateTime.
	 *
	 * @static
	 * @param {boolean} convertToTrueLocal
	 * If sent and true, this will further convert the current date time to
	 * match true local time. This value defaults to false.
	 * @returns {DateTime}
	 * The DateTime object representing the current system time.
	 * @memberof DateHelper
	*/
	public static getSystemDateTime(
		convertToTrueLocal: boolean = false): DateTime
	{
		const date: DateTime = DateTime.local();

		return convertToTrueLocal === true
			? this.convertToTrueLocal(date)
			: date;
	}

	/**
	 * Calculates and local timezone in the expected IANA format for Luxon
	 * support.
	 *
	 * @static
	 * @returns {string}
	 * The string based representation of the local IANA formatted time zone
	 * of this client instance.
	 * @memberof DateHelper
	*/
	public static getLocalTimeZone(): string
	{
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	/**
	 * Calculates and returns a javascript local representation of the utc based
	 * system ISO string.
	 * @warn This should be used sparingly. Current uses only include Calendar
	 * support. This will alter the underlying time.
	 *
	 * @static
	 * @param {string} isoString
	 * The current system based time that should be altered to a matching local
	 * JS Date.
	 * @returns {Date}
	 * A javascript date for use in a select few locations where a System time
	 * should be altered to a local time based display.
	 * @memberof DateHelper
	*/
	public static fromUtcSystemIsoToSimulatedLocalDate(
		isoString: string): Date
	{
		return DateTime.fromISO(
			isoString)
			.setZone(
				this.getLocalTimeZone(),
				{
					keepLocalTime: true
				})
			.toJSDate();
	}

	/**
	 * Calculates and returns the system time zone based representation of
	 * a local shifted DateTime value created from the method
	 * fromUtcSystemIsoToSimulatedLocalDate.
	 * @warn This should be used sparingly. Current uses only include Calendar
	 * support. This will alter the underlying time.
	 *
	 * @static
	 * @param {string} isoString
	 * The current iso string that represents the javascript simulated date.
	 * @returns {string}
	 * A system equivalent DateTime that represents the sent simulated
	 * javascript date based ISO.
	 * @memberof DateHelper
	*/
	public static fromSimulatedLocalDateToUtcSystemIso(
		isoString: string): string
	{
		return DateTime.fromISO(
			isoString,
			{
				zone: this.getLocalTimeZone()
			})
			.setZone(
				Settings.defaultZone,
				{
					keepLocalTime: true
				})
			.toISO();
	}

	/**
	 * Calculates and returns the sent date time as the true local
	 * representation of the system based DateTime object.
	 *  @warn This should be used sparingly. Current uses include desired
	 * local display times using the DateTimeToTrueLocalPipe, and rare SQL calls
	 * that also should display in a true local time.
	 *
	 * @static
	 * @param {DateTime} systemDateTime
	 * The current system time based DateTime that should be altered to
	 * represent a true local DateTime.
	 * @returns {DateTime}
	 * A true local based DateTime that will default to displaying and
	 * formatting as the matching local DateTime of the sent default zone
	 * based system time.
	 * @memberof DateHelper
	*/
	public static convertToTrueLocal(
		systemDateTime: DateTime): DateTime
	{
		const offset: number =
			DateTime.local().offset;
		const localOffset: number =
			DateTime.local().setZone(DateHelper.getLocalTimeZone()).offset;

		return systemDateTime
			.plus(
				{
					minute: localOffset - offset
				});
	}

	/**
	 * Determins if a string is a date.
	 *
	 * @static
	 * @param {string} dateString
	 * The string to test.
	 * @returns {boolean}
	 * A value of true if it is a date.
	 * @memberof DateHelper
	*/
	public static isDate(
		dateString: string): boolean
	{
		if (AnyHelper.isNullOrWhitespace(dateString)
			|| dateString === 'null')
		{
			return false;
		}

		try
		{
			const localDate: Date = DateHelper
				.fromUtcSystemIsoToSimulatedLocalDate(dateString);

			const dateTime: DateTime = DateTime
				.fromJSDate(localDate);

			const formattedDate: string = DateHelper.formatDate(dateTime);

			if (formattedDate === 'Invalid DateTime')
			{
				return false;
			}

			return true;
		}
		catch
		{
			return false;
		}
	}
}