/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-manage-user-group',
	templateUrl: './manage-user-group.component.html',
	styleUrls: [
		'./manage-user-group.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ManageUserGroupComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class ManageUserGroupComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Initializes a new instance of the ManageUserGroupComponent class.
	 *
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The api service used to load security application data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to load entity instance data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {Router} router
	 * The router used in this component for site navigation.
	 * @param {ModuleService} moduleService
	 * The module service used in this component for site navigation.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ManageUserGroupComponent
	 */
	public constructor(
		public securityGroupApiService: SecurityGroupApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public router: Router,
		public moduleService: ModuleService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService,
		private readonly insuranceService: InsuranceService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof BaseExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUserGroupComponent
	 */
	public manageUserGroupTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the table definitions for the common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUserGroupComponent
	 */
	public manageUserGroupTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public viewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public viewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public updateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public updateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the dynamic formly layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ManageUserComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the error message displayed on the formly async validator.
	 *
	 * @type {string}
	 * @memberof ManageUserGroupComponent
	 */
	public asyncValidatorErrorMessage: string;

	/**
	 * Gets or sets the route debounce time.
	 *
	 * @type {number}
	 * @memberof ManageUserGroupComponent
	 */
	private readonly routeDebounceTime: number =
		AppConstants.time.oneHundredMilliseconds;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUserComponent
	 */
	public setupPageVariables(): void
	{
		this.viewAvailableColumns =
			[
				{
					dataKey: 'userName',
					columnHeader: 'User Name',
					displayOrder: 1
				}
			];
		this.viewSelectedColumns =
			this.viewAvailableColumns;

		this.updateAvailableColumns =
			[
				...this.viewAvailableColumns
			];
		this.updateSelectedColumns =
			this.updateAvailableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof ManageUserGroupComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions(
				await this.insuranceService.getMasterSecurityGroups());

		this.dynamicFormlyLayout =
			[
				{
					...CommonFormlyFieldConstants
						.publicField,
					props: {
						...CommonFormlyFieldConstants
							.publicField
							.props,
						disabled:
							this.context.source.displayMode ===
								AppConstants.displayMode.view
					}
				},
				{
					...CommonFormlyFieldConstants
						.ownershipSecurityGroupField,
					props: {
						...CommonFormlyFieldConstants
							.ownershipSecurityGroupField
							.props,
						options: securityGroupOptions,
						disabled:
							this.context.source.displayMode ===
								AppConstants.displayMode.view,
						appendTo: FormlyConstants.appendToTargets.body
					}
				},
				{
					key: 'description',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Description',
						rows: 5,
						disabled:
							this.context.source.displayMode ===
								AppConstants.displayMode.view
					}
				}
			];

		const sharedTableDefinitions: any =
			{
				tableTitle: 'Membership',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'User Membership'),
				hideSettings: true,
				nestedTable: true,
				objectSearch: {
					filter: AppConstants.empty,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: 5
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const users: number[] =
							await this.securityGroupApiService
								.getSecurityGroupUsers(
									this.context.source.selectedItem.id);

						this.entityInstanceApiService.entityInstanceTypeGroup =
							AppConstants.typeGroups.users;

						const filterQuery: string =
							AnyHelper.isNullOrWhitespace(objectSearch.filter)
								? `Id in (${users})`
								: `Id in (${users}) AND ${objectSearch.filter}`;

						const entityInstances: object[] =
								(!AnyHelper.isNull(users[0]))
									? await this.entityInstanceApiService
										.query(filterQuery,
											objectSearch.orderBy,
											objectSearch.offset,
											objectSearch.limit)
									: [];

						const manageGroupUsers: object[] = [];
						if (!AnyHelper.isNull(entityInstances[0]))
						{
							entityInstances.forEach(
								(entityInstance: IEntityInstance) =>
								{
									const userName: string =
										StringHelper.interpolate(
											'${entityInstance.data.userName}',
											entityInstance);

									manageGroupUsers.push(
										{
											id: entityInstance.id,
											userName: userName
										});
								});
						}

						return manageGroupUsers;
					},
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				}
			};

		this.manageUserGroupTableDefinitionsView =
			{
				...sharedTableDefinitions,
				availableColumns: this.viewAvailableColumns,
				selectedColumns: this.viewSelectedColumns,
				actions: {
					view: {
						disabledExpandItem: true,
						items: [
							{
								command:
									() =>
									{
										setTimeout(
											() =>
											{
												this.router.navigate(
													[
														`${this.moduleService
															.name}`
															+ '/entities',
														AppConstants.typeGroups
															.users,
														'view',
														this.commonTableContext
															.source
															.selectedItem.id
													]);
											},
											this.routeDebounceTime);
									}
							}
						]
					}
				}
			};

		this.manageUserGroupTableDefinitionsUpdate =
			{
				...sharedTableDefinitions,
				availableColumns: this.updateAvailableColumns,
				selectedColumns: this.updateSelectedColumns,
				actions: {
					create: {
						layout: [
							{
								key: 'userName',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: AppConstants.empty,
									disabled: false,
									required: true
								},
								asyncValidators: {
									validUsername: {
										expression: async(
											control: UntypedFormControl,
											field: FormlyFieldConfig) =>
											this.validUserName(
												control,
												field),
										message: AppConstants.empty
									}
								}
							}
						],
						items: [
							{
								label: 'Add',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.createSecurityGroupUser()
							}]
					},
					view: {
						disabledExpandItem: true,
						items: [
							{
								command:
									() =>
									{
										this.router.navigate(
											[
												`${this.moduleService.name}/`
													+ 'entities',
												AppConstants.typeGroups.users,
												'view',
												this.commonTableContext.source
													.selectedItem.id
											]);
									}
							}
						]
					},
					delete: {
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: () => this.deleteSecurityGroupUser()
							}],
						deleteStatement: () =>
							'Confirm to remove user '
								+ `${this.commonTableContext.source
									.selectedItem?.id} `
								+ `${this.commonTableContext.source
									.selectedItem?.userName} `
								+ 'from Security Group'
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Validates if user name is valid.
	 *
	 * @async
	 * @param {FormControl} control
	 * The form control.
	 * @param {FormlyFieldConfig} field
	 * The field form config.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ManageUserGroupComponent
	 */
	private async validUserName(
		control: UntypedFormControl,
		field: FormlyFieldConfig): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		const initialPromiseArray: Promise<any>[] =
			[
				this.entityInstanceApiService.query(
					`userName eq '${control.value}'`,
					AppConstants.empty)
			];

		initialPromiseArray.push(
			this.securityGroupApiService.getSecurityGroupUsers(
				this.context.source.selectedItem?.id));

		return Promise.all(
			initialPromiseArray)
			.then((
				[
					entityInstance,
					securityGroupUsers
				]) =>
			{
				field.asyncValidators.validUsername.message =
					'Invalid username.';
				let subscribedUser: boolean = false;
				securityGroupUsers.forEach((user: number) =>
				{
					if (user === entityInstance[0]?.id)
					{
						subscribedUser = true;
						field.asyncValidators.validUsername.message =
							'Username already subscribed.';
					}
				});

				return Promise.resolve(
					!AnyHelper.isNullOrEmpty(entityInstance[0])
							&& subscribedUser === false);
			});
	}

	/**
	 * creates a new Security Group User.
	 *
	 * @async
	 * @memberof ManageUserGroupComponent
	 */
	private async createSecurityGroupUser(): Promise<void>
	{
		const createSecurityGroupUser: Function =
			async() =>
			{
				const entityInstanceUser: IEntityInstance =
					await this.entityInstanceApiService
						.getSingleQueryResult(
							`userName eq '${
								this.commonTableContext.source
									.selectedItem.userName}'`,
							AppConstants.empty);

				await this.securityGroupApiService.createSecurityGroupUser(
					this.context.source.selectedItem.id,
					entityInstanceUser.id);

				this.commonTableContext.source.selectedItem.id =
					entityInstanceUser.id;
				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createSecurityGroupUser(),
				'<strong>Adding User</strong>',
				'<strong>Added User</strong>',
				'User was added.',
				'User was not added.'));
	}

	/**
	 * deletes an existing Security Group User.
	 *
	 * @async
	 * @memberof ManageUserGroupComponent
	 */
	private async deleteSecurityGroupUser(): Promise<void>
	{
		const deleteSecurityGroupUser: Function =
			async() =>
			{
				await this.securityGroupApiService.deleteSecurityGroupUser(
					this.context.source.selectedItem.id,
					this.commonTableContext.source.selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteSecurityGroupUser(),
				'<strong>Removing User</strong>',
				'<strong>Removed User</strong>',
				'User was removed.',
				'User was not removed.'));
	}
}