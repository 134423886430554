/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	IEntityTypeDto
} from '@api/interfaces/entities/entity-type.dto.interface';
import {
	BusinessLogicEntity
} from '@shared/business-logic-entities/business-logic-entity';
import {
	FileEntity
} from '@shared/business-logic-entities/file-entity';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FileState
} from '@shared/constants/enums/file-state.enum';
import {
	WorkItemStatus
} from '@shared/constants/enums/work-item-status.enum';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IWorkItemKeyDates
} from '@shared/interfaces/workItems/work-item-key-dates.interface';
import {
	IWorkItemReference
} from '@shared/interfaces/workItems/work-item-reference.interface';
import {
	IWorkItem
} from '@shared/interfaces/workItems/work-item.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';

/**
 * A component representing an instance of the main work items page.
 *
 * @export
 * @class WorkItem
 */
export class WorkItem
	extends BusinessLogicEntity
	implements IWorkItem
{
	/**
	 * Initializes a new instance of the work item.
	 *
	 * @param {IWorkItem} workItem
	 * The underlying IWorkItem.
	 * @param {ResolverService} resolverService
	 * The resolver service.
	 * @memberof WorkItem
	 */
	public constructor(
		workItem: IWorkItem,
		public resolverService: ResolverService)
	{
		super(
			workItem,
			resolverService);
	}

	/**
	 * The work item data object.
	 *
	 * @memberof WorkItem
	 */
	declare public data: {
		assignedTo: string;
		blocked: boolean;
		description: string;
		keyDates: IWorkItemKeyDates;
		metadata: any;
		module: string;
		priority: string;
		queue: string;
		references: IWorkItemReference[];
		status: WorkItemStatus;
		subType: string;
		systemGenerated: boolean;
		type: string;
	};

	/**
	 * Gets a reference.
	 *
	 * @param {string} referenceType
	 * The reference type to get.
	 * @returns {IWorkItemReference}
	 * A work item reference.
	 * @memberof WorkItem
	 */
	public getReference(
		referenceType: string): IWorkItemReference
	{
		return this.data.references
			.find(
				(reference: IWorkItemReference) =>
					reference.type === referenceType);
	}

	/**
	 * Gets an reference identifier.
	 *
	 * @param {string} referenceType
	 * The reference type to get.
	 * @returns {string}
	 * A work item reference identifier (an id, for example).
	 * @memberof WorkItem
	 */
	public getIdentifier(referenceType: string): string
	{
		return this
			.getReference(referenceType)
			.identifier;
	}

	/**
	 * Unblocks this work item.
	 *
	 * @param {string} query
	 * Optional query string.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public unblock(
		query: string = null): Promise<void>
	{
		return this.executeWorkItemAction(
			WorkItemConstants.actions.workItemUnblocked,
			query);
	}

	/**
	 * Blocks this work item.
	 * @param {string} query
	 * Optional query string.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public block(
		query: string = null): Promise<void>
	{
		return this.executeWorkItemAction(
			WorkItemConstants.actions.workItemBlocked,
			query);
	}

	/**
	 * Sets this work item to ignored.
	 *
	 * @param {string} query
	 * Optional query string.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public ignore(
		query: string = null): Promise<void>
	{
		return this.executeWorkItemAction(
			WorkItemConstants.actions.workItemIgnored,
			query);
	}

	/**
	 * Sets this work item to done.
	 *
	 * @param {string} query
	 * Optional query string.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public markDone(
		query: string = null): Promise<void>
	{
		return this.executeWorkItemAction(
			WorkItemConstants.actions.workItemDone,
			query);
	}

	/**
	 * resets the work item state to active and unblocked.
	 *
	 * @param {string} query
	 * Optional query string.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public reset(
		query: string = null): Promise<void>
	{
		return this.executeWorkItemAction(
			WorkItemConstants.actions.workItemReset,
			query);
	}

	/**
	 * Markes the work item done/approved and approves the associated file.
	 *
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public approveFile(): Promise<void>
	{
		return this.fileReviewed(
			FileState.Approved);
	}

	/**
	 * Markes the work item done/rejected and rejects the associated file.
	 *
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public rejectFile(): Promise<void>
	{
		return this.fileReviewed(
			FileState.Rejected);
	}

	/**
	 * Resets the work item and the associated file pre reviewed states.
	 *
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public resetFileReviewState(): Promise<void>
	{
		return this.fileReviewed(
			FileState.Pending);
	}

	/**
	 * Markes the work item done and the associated file as reviewed
	 *
	 * @async
	 * @param {string} reviewState
	 * The review state to mark
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public async fileReviewed(
		reviewState: string): Promise<void>
	{
		EventHelper.dispatchBannerEvent(
			'<strong>Updating</strong> File Status and Business Rules',
			`File Status set as '${reviewState}'.`,
			AppConstants.activityStatus.info);

		await this.activityService.handleActivity(
			new Activity(
				this.executeWorkItemAction(
					`File${reviewState}`),
				'<strong>Updating</strong> File Status and Business Rules',
				'<strong>Updated</strong> File Status and Business Rules',
				`File Status set to '${reviewState}'.`,
				'File Status was not updated.'));
	}

	/**
	 * Gets the associated file entity.
	 *
	 * @async
	 * @returns {Promise<FileEntity>}
	 * The file entity or null if references are not set.
	 * @memberof WorkItem
	 */
	public async getAssociatedFileEntity(): Promise<FileEntity>
	{
		let fileId: number;
		let fileType: string;

		try
		{
			fileId =
				parseInt(
					this.getIdentifier('FileId'),
					AppConstants.parseRadix);

			fileType =
				this.getIdentifier('FileEntityType');
		}
		catch
		{
			return Promise.resolve(null);
		}

		const fileEntityType: IEntityTypeDto =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Name.Equals("${fileType}")`,
					'Id',
					false);

		this.entityInstanceApiService
			.entityInstanceTypeGroup = fileEntityType.group;

		const fileInstance: IEntityInstance =
			await this.entityInstanceApiService.get(fileId);

		return new FileEntity(
			fileInstance,
			this.resolverService);
	}

	/**
	 * Executes the work item action.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public async executeWorkItemAction(
		actionName: string,
		query: string = null): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup
			= (await this.getEntityTypeAsync()).group;

		await this.entityInstanceApiService
			.executeAction(
				this.id,
				actionName,
				null,
				query);
	}
}