<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i
		class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template
	#Content>
	<div
		class="ui-g-12 text-left no-padding">
		<div
			class="ui-g-12 nested-display-subtitle text-left">
			<div class="ellipsis-text">
				Delete: Note {{selectedItem.id}}
			</div>
			<span class="nested-display-actions">
				<ng-template
					ngFor
					let-action
					[ngForOf]="itemActions">
					<span [ngClass]="{
							'selected-bottom-border': (action.id === 'Delete')
						}">
						<app-common-icon-tooltip
							content="{{action.label}}"
							iconClass="fa fa-fw {{action.icon}} theme-color cursor-pointer"
							position="left"
							styleClass="utility-menu-tooltip"
							(iconClick)="action.command($event)">
						</app-common-icon-tooltip>
					</span>
				</ng-template>
			</span>
		</div>

		<div class="ui-g-12 delete-confirmation">
			Are you sure you want to delete note {{selectedItem.id}}?
		</div>

		<div
			class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="changeDisplayMode.emit('List')">
				Cancel
			</a>
			<button
				pButton
				label="Confirm Delete"
				class="background-color-red no-margin-right primary-action-button"
				(click)="deleteItem()"
				[disabled]="saving">
			</button>
		</div>
	</div>
</ng-template>