/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityTableDirective
} from '@entity/directives/entity-table.directive';
import {
	IEntitySearch
} from '@entity/interfaces/entity-search.interface';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISelectable
} from '@shared/interfaces/application-objects/selectable.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';

/* eslint-enable max-len */

@Component({
	selector: 'entity-select',
	templateUrl: './entity-select.component.html',
	styleUrls: ['./entity-select.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A class representing an instance of an entity search component.
 *
 * @export
 * @class EntitySelectComponent
 * @implements {OnInit}
 */
export class EntitySelectComponent
	extends EntityTableDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntitySelectComponent. This component
	 * will display a filtered list of entities for navigation.
	 *
	 * @param {EntityService} entityService
	 * The entity service used to populate get the type name.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service used to populate the entity type filter.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance service used to populate the entity list data.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector ref used to update ui changes.
	 * @memberof EntitySelectComponent
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public changeDetectorReference: ChangeDetectorRef)
	{
		super();
	}

	/**
	 * Gets or sets the simple display mode. This simple display
	 * mode does not offer many features of a full search list.
	 *
	 * @type {boolean}
	 * @memberof EntitySelectComponent
	 */
	@Input() public useSimpleDisplayMode: boolean = true;

	/**
	 * Gets or sets the selected entities that are stored in the
	 * component using this select. Selected entities are set with an
	 * id of zero when loaded in the list allowing for selected
	 * business rules.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof EntitySelectComponent
	 */
	@Input() public selectedEntities: IEntityInstance[] = [];

	/**
	 * Gets or sets the search criteria of the entity
	 * list.
	 *
	 * @type {IEntitySearch}
	 * @memberof EntitySearchDirective
	 */
	@Input() public searchCriteria: IEntitySearch;

	/**
	 * Sets the event emitter action to communicate to
	 * implementing components that a row was selected.
	 *
	 * @type {EventEmitter<ISelectable>}
	 * @memberof EntitySelectComponent
	 */
	@Output() public itemSelected: EventEmitter<ISelectable> =
		new EventEmitter();

	/**
	 * Sets the event emitter action to communicate to
	 * implementing components that a category was selected.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof EntitySelectComponent
	 */
	@Output() public categorySelected: EventEmitter<string> =
		new EventEmitter();

	/**
	 * Gets or sets the entity search table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntitySelectComponent
	 */
	public entitySelectTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof EntitySelectComponent
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the category options.
	 *
	 * @type {object[]}
	 * @memberof EntitySelectComponent
	 */
	public categoryOptions: object[] = [];

	/**
	 * Gets or sets the loaded entity type group.
	 *
	 * @type {string}
	 * @memberof EntitySelectComponent
	 */
	public entityTypeGroup: string;

	/**
	 * Gets or sets the category label.
	 *
	 * @type {string}
	 * @memberof EntitySelectComponent
	 */
	public selectedCategory: string;

	/**
	 * Gets or sets the loading entity type flag.
	 *
	 * @type {boolean}
	 * @memberof EntitySelectComponent
	 */
	public loadingEntityType: boolean = true;

	/**
	 * Gets or sets the string array categories.
	 *
	 * @type {string[]}
	 * @memberof EntitySelectComponent
	 */
	public categories: string[];

	/**
	 * Gets or sets the summary data path available columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntitySelectComponent
	 */
	public summaryDataPathColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the loaded entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntitySelectComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * On initialization event.
	 * Configures this components display based on the sent route
	 * information.
	 *
	 * @async
	 * @memberof EntitySelectComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.setFilterKeywordString(this.searchCriteria.filter);
		await this.setCategories();
		this.setCategoryLabel(this.entityTypeGroup);
		this.summaryDataPathColumns =
			await this.entityService.getDynamicSummaryColumns(
				this.entityTypeGroup);
		this.selectedColumns = this.summaryDataPathColumns;
		this.setupTableDefinitions();
	}

	/**
	 * Sets the categories properties wich defines
	 * the category label and options to be displayed in
	 * the search filter component.
	 *
	 * @async
	 * @memberof EntitySelectComponent
	 */
	public async setCategories(): Promise<void>
	{
		this.categories =
			this.searchCriteria.category
				.split(
					AppConstants.characters.comma);

		for (const category of this.categories)
		{
			const entityType: IEntityType =
				await this.entityTypeApiService
					.getSingleQueryResult(
						`Group eq '${category}'`,
						AppConstants.empty);

			this.categoryOptions.push(
				{
					value: category,
					label:
						StringHelper.beforeCapitalSpaces(
							StringHelper.getLastSplitValue(
								entityType.name,
								AppConstants.characters.period))
				});
		}

		this.entityTypeGroup = AppConstants.empty;
		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Sets the definitions for the entity search common table
	 *
	 * @memberof EntitySelectComponent
	 */
	public setupTableDefinitions(): void
	{
		this.entitySelectTableDefinitions =
			{
				tableTitle:
					AnyHelper.isNullOrWhitespace(this.entityTypeGroup)
						? 'Entities'
						: StringHelper.beforeCapitalSpaces(
							StringHelper.getLastSplitValue(
								this.entityTypeGroup,
								AppConstants.characters.period)),
				hideTableTitle: true,
				objectSearch: {
					filter: this.searchCriteria.filter,
					orderBy: this.searchCriteria.orderBy,
					offset: 0,
					limit: this.searchCriteria.limit,
					virtualIndex: 0,
					virtualPageSize: this.searchCriteria.virtualPageSize
				},
				apiPromise:
					(objectSearch: IObjectSearch) =>
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
								this.entityTypeGroup;

						return this.entityInstanceApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);
					},
				availableColumns: this.summaryDataPathColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				itemSelection: {
					selectionMode: AppConstants.itemSelectionMode.multiple,
					selectedItemEvent: (
						item: any,
						selected: boolean) =>
					{
						item.selected = selected;
						this.itemSelected.emit(
							item);
					}
				},
				actions: {
					filter: {
						considerFilteringResults: () =>
						{
							document.getElementById(
								AppConstants.commonTableActions.filterInput)
								.focus();
						}
					}
				}
			};

		this.loadingTableDefinitions = false;
		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Handles the filter criteria changed event sent from the filter controls.
	 *
	 * @param {string} filterCriteria
	 * The filter criteria sent from the search filter.
	 * @memberof EntitySelectComponent
	 */
	public filterCriteriaChanged(
		filterCriteria: string): void
	{
		// Get the query string filter
		this.searchCriteria.filter =
			(!AnyHelper.isNullOrEmpty(filterCriteria))
				? this.getQueryStringContainsFilter(filterCriteria)
				: AppConstants.empty;

		// Reload the table definitions and query string
		if (AnyHelper.isNullOrEmpty(this.selectedCategory)
			|| this.selectedCategory === AppConstants.undefined)
		{
			return;
		}

		this.commonTableContext.source.filterCriteriaChanged(
			this.searchCriteria.filter);
	}

	/**
	 * Handles the category selection change. This will
	 * update the view based on the selected type group.
	 *
	 * @param {string} selectedCategory
	 * The selected category string.
	 * @async
	 * @memberof EntitySelectComponent
	 */
	public async selectedCategoryChanged(
		selectedCategory: string): Promise<void>
	{
		this.selectedCategory = selectedCategory;
		// Restore the table definition loading state
		this.loadingTableDefinitions = true;

		// Set the Entity Group and Name based on the selected category
		this.entityTypeGroup = selectedCategory;

		this.setCategoryLabel(this.entityTypeGroup);

		// Reload the table definitions and query string
		if (AnyHelper.isNullOrEmpty(selectedCategory))
		{
			setTimeout(
				() =>
				{
					this.filterValue = AppConstants.empty;
					this.searchCriteria.filter = AppConstants.empty;
					this.entitySelectTableDefinitions.objectSearch.filter =
						this.searchCriteria.filter;
					this.loadingTableDefinitions = false;
					this.loadingEntityType = true;
					this.changeDetectorReference.detectChanges();
				});
		}
		else
		{
			this.categorySelected.emit(selectedCategory);
			setTimeout(
				async() =>
				{
					this.summaryDataPathColumns =
						await this.entityService.getDynamicSummaryColumns(
							this.entityTypeGroup);
					this.displayKeywordMessage =
						this.summaryDataPathColumns.length === 0;
					this.selectedColumns = this.summaryDataPathColumns;
					this.setupTableDefinitions();
					this.entitySelectTableDefinitions.objectSearch.filter =
						this.searchCriteria.filter;
					this.loadingEntityType = false;
					this.changeDetectorReference.detectChanges();
				});
		}
	}
}