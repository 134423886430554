/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	CommonModule
} from '@angular/common';
import {
	NgModule
} from '@angular/core';
import {
	RouterModule
} from '@angular/router';
import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntitySearchComponent
} from '@entity/components/entity-search/entity-search.component';
import {
	EntityComponent
} from '@entity/entity.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	SharedModule
} from '@shared/shared.module';
import {
	WorkbenchComponent
} from '@workItems//components/workbench/workbench.component';
import {
	WorkItemExpandComponent
} from '@workItems/components/workbench/work-item-expand/work-item-expand.component';
import {
	WorkItemService
} from '@workItems/services/work-item.service';
import {
	WorkItemTokenLookup
} from '@workItems/work-item-token.lookup';
import {
	WorkItemsComponent
} from '@workItems/work-items.component';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		WorkbenchComponent,
		WorkItemsComponent,
		WorkItemExpandComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild([
			{
				path: 'workItems',
				component: WorkItemsComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						AppConstants.securityGroups.workitemsModuleAccess
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'workbench',
						component: WorkbenchComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard/:dashboardType',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										AppConstants.securityGroups
											.workitemsModuleAccess
									],
									refreshComponent: true
								}
							}
						]
					},
					{
						path: 'entities',
						component: EntityComponent,
						children: [
							{
								path: ':entityType/view/:id',
								component: EntityInstanceComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: ':entityType/edit/:id',
								component: EntityInstanceComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: 'display',
								children: [
									{
										path: ':displayComponent/create',
										component: GenericBasePageComponent,
										data: {
											refreshComponent: true
										}
									}
								]
							},
							{
								path: '',
								component: EntitySearchComponent,
								data: {
									refreshComponent: true
								}
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
		WorkbenchComponent
	],
	providers: [
		WorkItemService,
		{
			provide: WorkItemTokenLookup.tokens.WorkItemService,
			useClass: WorkItemService
		},
		WorkbenchComponent
	]
})

/**
 * A module used to hold work item route specific logic and display resources.
 *
 * @export
 * @class WorkItemsModule
 */
export class WorkItemsModule
{
}