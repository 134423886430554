<div class="ui-g-12 text-center"
	*ngIf="loading">
	<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</span>
</div>

<div *ngIf="!loading"
	class="table-content-container">
	<div class="ui-g-12 no-padding table-expand-section">
		<div class="ui-g-6 no-padding split-section">
			<app-dynamic-formly
				[dataSet]="selectedItem"
				[layoutSchema]="formlyEntityLayout"
				[context]="context">
			</app-dynamic-formly>
		</div>

		<div class="ui-g-6 no-horizontal-padding split-section work-item-summary-section {{siteLayoutService.contentCssClass}}">
			<div>
				<custom-section-title
					[field]="{
						props: {
							label: 'Work Item Information'
						}
					}">
				</custom-section-title>
				<app-dynamic-formly
					[dataSet]="selectedItem"
					[layoutSchema]="summaryFormlyLayout"
					[context]="context">
				</app-dynamic-formly>
			</div>
		</div>


		<div class="ui-g-6 no-horizontal-padding split-section parent-section {{siteLayoutService.contentCssClass}}"
			*ngIf="parentFormlyEntityLayout != null
				&& parentFormlyEntityLayout.length > 0
				&& parentEntityInstance != null">
			<div>
				<custom-section-title
					[field]="{
						props: {
							label: ('Linked: ' + getDisplayName(parentEntityInstance.entityType))
						}
					}">
				</custom-section-title>
				<app-dynamic-formly
					[dataSet]="parentEntityInstance"
					[layoutSchema]="parentFormlyEntityLayout"
					[context]="context">
				</app-dynamic-formly>
			</div>
		</div>
	</div>
</div>