/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	IEntityTypeDto
} from '@api/interfaces/entities/entity-type.dto.interface';
import {
	ISecurityItemDto
} from '@api/interfaces/security/security-item.dto.interface';
import {
	ISecurityRightDto
} from '@api/interfaces/security/security-right.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	ISecurityDefinition
} from '@shared/interfaces/security/security-definition.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	ISecurityItem
} from '@shared/interfaces/security/security-item.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	cloneDeep
} from 'lodash-es';

/* eslint-enable max-len */

/**
 * A class representing the helper methods and properties
 * for security access related processes and items.
 *
 * @export
 * @class SecurityHelper
 */
export class SecurityHelper
{
	/**
	 * Gets the string '.*' wildcard identifier.
	 *
	 * @static
	 * @type {string}
	 * @memberof SecurityHelper
	 */
	public static wildcardIdentifier: string =
		AppConstants.wildcardIdentifier;

	/**
	 * Gets the regular expression that finds wildcards.
	 *
	 * @static
	 * @readonly
	 * @type {RegExp}
	 * @memberof SecurityHelper
	 */
	private static readonly wildCardFinder: RegExp = new RegExp(
		'(?<wildCardPath>(\\$\\.data){1}(\\.[\\w]+)*)(\\.[\\*]{1}){1}');

	/**
	 * Gets the string '$.data.' data path prefix.
	 *
	 * @static
	 * @type {string}
	 * @memberof SecurityHelper
	 */
	private static readonly dataPathPrefix: string =
		AppConstants.nestedDataKeyPrefix
			+ AppConstants.nestedDataIdentifier;

	/**
	 * Gets the security rights for a given data property.
	 *
	 * @static
	 * @param {string} pathToFind
	 * The data item path on which to find the rights.
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data object permissions to check.
	 * @returns {ISecurityItemDto}
	 * the wildcard paths sorted from most granular (most depth) first
	 * to least granular ($.data) last.
	 * @memberof SecurityHelper
	 */
	public static getDataSecurityRights(
		pathToFind: string,
		dataSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto
	{
		let permission: ISecurityItemDto = dataSecurityPermissions.find(
			(item: ISecurityItemDto) => item.path === pathToFind);

		if (!AnyHelper.isNull(permission)) {
			return permission;
		}

		if (pathToFind.endsWith(
			AppConstants.characters.period
				+ AppConstants.commonProperties.resourceIdentifier))
		{
			return <ISecurityItemDto>
				{
					path: pathToFind,
					rights: <ISecurityRightDto>
						{
							create: true,
							read: true,
							update: false,
							delete: false
						}
				};
		}

		const wildcards: ISecurityItemDto[] =
			this.getWildCardPermissions(dataSecurityPermissions);

		permission = wildcards
			.find((wildcard: ISecurityItemDto) =>
				pathToFind.startsWith(wildcard.path)
				|| pathToFind.startsWith(
					wildcard.path.replace(
						this.wildcardIdentifier,
						AppConstants.empty)));

		return permission;
	}

	/**
	 * Gets the security rights for a given action.
	 *
	 * @static
	 * @param {string} pathToFind
	 * The data item path on which to find the rights.
	 * @param {ISecurityItemDto[]} actionSecurityPermissions
	 * The data object permissions to check.
	 * @returns {ISecurityItemDto}
	 * the security path and rights for this action path to find.
	 * @memberof SecurityHelper
	 */
	public static getActionSecurityRights(
		pathToFind: string,
		actionSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto
	{
		if (AnyHelper.isNullOrEmptyArray(actionSecurityPermissions))
		{
			return this.getNullRights(pathToFind);
		}

		const permission: ISecurityItemDto = actionSecurityPermissions.find(
			(item: ISecurityItemDto) => item.path === pathToFind);

		if (AnyHelper.isNullOrEmpty(permission))
		{
			return this.getNullRights(pathToFind);
		}

		return permission;
	}

	/**
	 * Gets the index of the security right for the given data path.
	 *
	 * @static
	 * @param {string} dataPath
	 * The data item path on which to find the index.
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data object permissions to check.
	 * @returns {number}
	 * the index to the best match security rights.
	 * @memberof SecurityHelper
	 */
	public static getSecurityPermissionIndex(
		dataPath: string,
		dataSecurityPermissions: ISecurityItemDto[]): number
	{
		const pathToFind: string = dataPath.startsWith(this.dataPathPrefix)
			? dataPath
			: this.dataPathPrefix + dataPath;

		let index: number = dataSecurityPermissions.findIndex(
			(data: ISecurityItemDto) => data.path === pathToFind);

		if (index > -1)
		{
			return index;
		}

		const foundWildCard: string = this
			.getWildCardPaths(dataSecurityPermissions)
			.find(path => dataPath.startsWith(path));

		if (AnyHelper.isNull(foundWildCard)) {
			return -1;
		}

		index = dataSecurityPermissions.findIndex(
			(data: ISecurityItemDto) =>
				data.path === foundWildCard + this.wildcardIdentifier);

		return index;
	}

	/**
	 * Gets a set entity type security rights.
	 *
	 * @param {number} parentId
	 * The path that should have the null rights.
	 * @param {string} parentTypeGroup
	 * The parent entity type goup.
	 * @param {string} childTypeWildCard
	 * The child wildcard entity type name.
	 * @param {EntityDefinition}
	 * The parent entity definition containbing the supported child types.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * An instance of entity instance api service.
	 * @returns {Promise<ISecurityEntityTypeDefinition[]>}
	 * The entity tyype security rights.
	 * @memberof SecurityHelper
	 */
	public static async getSupportedChildPermissions(
		parentId: number,
		parentTypeGroup: string,
		childTypeWildCard: string,
		entityDefinition: EntityDefinition,
		entityInstanceApiService: EntityInstanceApiService,
		entityTypeApiService: EntityTypeApiService):
		Promise<ISecurityEntityTypeDefinition[]>
	{
		const childBaseType: string =
			childTypeWildCard.replace(
				'.*',
				AppConstants.empty);

		const supportedTypeNames: string[] =
			entityDefinition.supportedChildTypes
				.filter((typeName: string) =>
					typeName === childBaseType
					|| typeName.startsWith(`${childBaseType}.`));

		if (AnyHelper.isNullOrEmptyArray(supportedTypeNames))
		{
			return [];
		}

		const supportedEntityTypes = await entityTypeApiService
			.query(
				`(Name IN ("${supportedTypeNames.join('","')}"))`,
				'Id');

		const supportedEntityTypeGroups: string[] =
			supportedEntityTypes.map(
				(entityType: IEntityTypeDto) =>
					entityType.group);

		entityInstanceApiService
			.entityInstanceTypeGroup = parentTypeGroup;

		const permissions: ISecurityEntityTypeDefinition[] =
			await entityInstanceApiService
				.getHierarchyPermissions(
					parentId,
					supportedEntityTypeGroups);

		return permissions
			.filter((permission: ISecurityEntityTypeDefinition) =>
				supportedTypeNames.includes(permission.entityTypeName));
	}

	/**
	 * Gets a set of null rights with the path given.
	 *
	 * @param path
	 * The path that should have the null rights.
	 * @returns {ISecurityItemDto}
	 * The null security rights item.
	 * @memberof SecurityHelper
	 */
	public static getNullRights(
		path: string): ISecurityItemDto
	{
		const nullRights: ISecurityItemDto =
			<ISecurityItemDto>
			{
				path,
				rights: <ISecurityRightDto>
					{
						create: null,
						read: null,
						update: null,
						delete: null,
						execute: null
					}
			};

		return nullRights;
	}

	/**
	 * Determines if an action is authorized.
	 *
	 * @param {string} entityType
	 * The entity type name.
	 * @param {string} actionName
	 * The action name.
	 * @param {ISecurityEntityTypeDefinition[]} securityDefinitions
	 * the list of security definitions.
	 * @returns {boolean}
	 * the response of true if authorized; false otherwise.
	 * @memberof SecurityHelper
	 */
	public static actionAuthorized(
		entityType: string,
		actionName: string,
		securityDefinitions: ISecurityEntityTypeDefinition[]): boolean
	{
		if (AnyHelper.isNullOrEmptyArray(securityDefinitions))
		{
			return false;
		}

		const actions: ISecurityItemDto[] =
			securityDefinitions
				.find((definition: ISecurityEntityTypeDefinition) =>
					definition.entityTypeName === entityType)
				.securityDefinition.actions;

		const securityItem =
			this.getActionSecurityRights(
				actionName,
				actions);

		return securityItem.rights.execute === true;
	}

	/**
	 * Checks to see if the security permissions contain the
	 * right and is true.
	 *
	 * @param {SecurityRightType} right
	 * the right name.
	 * @param {string} entityType
	 * @param { ISecurityEntityTypeDefinition[]} securityDefinitions
	 * The security definitions.
	 * @returns
	 * The boolean true if use permissions have the right and it is true.
	 * @memberof SecurityHelper
	 */
	public static HasRight(
		right: SecurityRightType,
		entityType: string,
		securityDefinitions: ISecurityEntityTypeDefinition[]): boolean
	{
		if (AnyHelper.isNullOrEmptyArray(securityDefinitions))
		{
			return false;
		}

		const rights: ISecurityRightDto =
			securityDefinitions
				.find((definition: ISecurityEntityTypeDefinition) =>
					definition.entityTypeName === entityType)
				?.securityDefinition
				?.rights;

		switch (right)
		{
			case SecurityRightType.create:
				return rights?.create ?? false;
			case SecurityRightType.read:
				return rights?.read ?? false;
			case SecurityRightType.update:
				return rights?.update ?? false;
			case SecurityRightType.delete:
				return rights?.delete ?? false;
			case SecurityRightType.execute:
				return rights?.execute ?? false;
			default:
				throw new Error(`Security Right '${right}' is invalid.`);
		}
	}

	/**
	 * Checks to see if the current user has a role that is
	 * in the allowed security groups.
	 *
	 * @param {string[]} allowedGroups
	 * The allowed security groups.
	 * @param {IUser} user
	 * The logged in user.
	 * @returns
	 * The boolean true if the user has a role that is in the allowed groups.
	 * @memberof SecurityHelper
	 */
	public static membershipExists(
		allowedGroups: string[],
		user: IUser): boolean
	{
		const memberGroups =
			user?.membershipSecurityGroups ?? [];

		return memberGroups
			.some((group: any) =>
				allowedGroups.includes(group.name));
	}

	/**
	 * Performs a security scrub on the menu items.
	 *
	 * @param {ISecureMenuItem[]} menuItems
	 * The menu itesm to scrub.
	 * @param {string} entityType
	 * The entity type.
	 * @param {ISecurityEntityTypeDefinition[]} securityDefinitions
	 * The security definitions.
	 * @param {string} directEntityType
	 * If sent this will be the entity type specific to this scrub menu items
	 * call and the direct access policy definition will replace the overall
	 * security definitions for this instance only.
	 * @param {ISecurityDefinition} directAccessPolicyDefinition
	 * If sent this will be the access policy specific to this scrub menu items
	 * call and the direct access policy definition will replace the overall
	 * security definitions for this instance only.
	 * @returns
	 * The scrubbed menu items.
	 * @memberof SecurityHelper
	 */
	public static scrubMenuItems(
		menuItems: ISecureMenuItem[],
		entityType: string,
		securityDefinitions: ISecurityEntityTypeDefinition[],
		directEntityType: string = null,
		directAccessPolicyDefinition: ISecurityDefinition = null):
		ISecureMenuItem[]
	{
		const authorizedMenuItems: ISecureMenuItem[] = [];

		const mergedSecurityDefinitions =
			AnyHelper.isNullOrWhitespace(directEntityType)
				? securityDefinitions
				: this.mergeDirectAccessPolicyDefinitions(
					cloneDeep(securityDefinitions),
					directEntityType,
					directAccessPolicyDefinition);

		for (const action of menuItems)
		{
			let hasRight: boolean = false;

			switch (action.securityRightCategory)
			{
				case SecurityRightCategory.TopLevelRight:

					hasRight =
						SecurityHelper
							.HasRight(
								action.securityRightType,
								entityType,
								mergedSecurityDefinitions);

					break;

				case SecurityRightCategory.Data:

					const dataRights: ISecurityItem[] =
						mergedSecurityDefinitions
							.find(
								(definition: ISecurityEntityTypeDefinition) =>
									definition.entityTypeName === entityType)
							.securityDefinition.data;

					if (!dataRights)
					{
						hasRight = false;

						break;
					}

					const itemRights: ISecurityItem =
						SecurityHelper
							.getDataSecurityRights(
								action.securityRightPath,
								dataRights);

					switch (action.securityRightType)
					{
						case SecurityRightType.create:
							hasRight = itemRights?.rights?.create ?? false;
							break;
						case SecurityRightType.read:
							hasRight = itemRights?.rights?.read ?? false;
							break;
						case SecurityRightType.update:
							hasRight = itemRights?.rights?.update ?? false;
							break;
						case SecurityRightType.delete:
							hasRight = itemRights?.rights?.delete ?? false;
							break;
						default:
							throw new Error(
								`Right '${action.securityRightType}' is invalid`
								+ ` for ${SecurityRightCategory.Data}.`);
					}

					break;

				case SecurityRightCategory.Action:

					hasRight =
						SecurityHelper
							.actionAuthorized(
								entityType,
								action.securityRightPath,
								mergedSecurityDefinitions);

					break;

				default:
					// Do we want to throw or log and revoke right?
					// hasRight = false;
					throw new Error(
						'Could not scrub menu items. Invalid requirement '
							+ `category ${action.securityRightCategory}. `
							+ 'Right not given.');
			}

			if (hasRight)
			{
				authorizedMenuItems.push(action);
			}
		}

		return authorizedMenuItems;
	}

	/**
	 * Gets the system security restrictions to define a password.
	 *
	 * @type {any}
	 * @param {any} systemSecurity
	 * The system security object.
	 * @returns
	 * Returns the security restrictions for a password, otherwise returns null.
	 * @memberof SecurityComponent
	 */
	public static getSecurityRestrictions(
		systemSecurity: any): any
	{
		if (AnyHelper.isNull(systemSecurity))
		{
			return null;
		}

		const password: any =
			systemSecurity.passwords;
		const passwordRestrictions: any =
			systemSecurity.passwords.requires;
		const allowPasswordReuse: string =
			systemSecurity.passwords.historyReuseRestriction === true
				? 'Yes'
				: 'No';

		const restrictions: any =
			{
				uppercase: passwordRestrictions.uppercase,
				lowercase: passwordRestrictions.lowercase,
				number: passwordRestrictions.number,
				special: passwordRestrictions.special,
				minimumLength: password.minimumLength,
				maximumLength: password.maximumLength,
				allowPasswordReuse: allowPasswordReuse
			};

		return restrictions;
	}

	/**
	 * Verifies if the provided password is valid based on the system security
	 * restrictions.
	 *
	 * @param {string} password
	 * The password to verify.
	 * @param {any} securityRestrictions
	 * The system password security restrictions.
	 * @param {boolean} required
	 * Determines if the required check is needed.
	 * @returns
	 * Returns true if the provided password is valid, otherwise returns false.
	 * @memberof SecurityHelper
	 */
	public static isValidPassword(
		password: string,
		securityRestrictions: any,
		required: boolean = true): boolean
	{
		if (required === false && AnyHelper.isNullOrWhitespace(password))
		{
			return true;
		}

		if (AnyHelper.isNull(securityRestrictions))
		{
			return false;
		}

		if (AnyHelper.isNullOrWhitespace(password)
			|| password.length
				< securityRestrictions.minimumLength
			|| password.length
				> securityRestrictions.maximumLength)
		{
			return false;
		}

		let upperCount: number = 0;
		let lowerCount: number = 0;
		let numberCount: number = 0;
		let specialCount: number = 0;

		for (let index = 0; index < password.length; index++)
		{
			const char = password.charAt(index);

			if (/[A-Z]/.test(char))
			{
				upperCount++;
			}
			else if (/[a-z]/.test(char))
			{
				lowerCount++;
			}
			else if (/[0-9]/.test(char))
			{
				numberCount++;
			}
			else if (/[#*$-+?_&=!%{}/]/.test(char))
			{
				specialCount++;
			}
		}

		return upperCount >= securityRestrictions.uppercase
			&& lowerCount >= securityRestrictions.lowercase
			&& numberCount >= securityRestrictions.number
			&& specialCount >= securityRestrictions.special;
	}

	/**
	 * sorts wildcard permissions from most granular first
	 * to least granular ($.data) last.
	 *
	 * @static
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data object permissions to sort.
	 * @returns {ISecurityItemDto[]}
	 * the sorted rights
	 * @memberof SecurityHelper
	 */
	private static sort(
		dataSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto[]
	{
		const period: string = AppConstants.characters.period;

		const sortedPermissions: ISecurityItemDto[] =
			Array.from(dataSecurityPermissions);

		return sortedPermissions
			.sort(
				function (itemA, itemB)
				{
					return itemB.path.split(period).length
						- itemA.path.split(period).length;
				});
	}

	/**
	 * Gets the set of wildcard permissions.
	 *
	 * @static
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data objetc permissions to check.
	 * @returns {ISecurityItemDto[]}
	 * the wildcard permissions sorted from most granular (most depth) first
	 * to least granular ($.data) last.
	 * @memberof SecurityHelper
	 */
	private static getWildCardPermissions(
		dataSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto[] {
		return this
			.sort(dataSecurityPermissions)
			.filter((data: ISecurityItemDto) =>
				this.wildCardFinder.test(data.path));
	}

	/**
	 * Gets the set of wildcard paths (no asterik).
	 *
	 * @static
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data objetc permissions to check.
	 * @returns {string[]}
	 * the wildcard paths sorted from most granular (most depth) first
	 * to least granular ($.data) last.
	 * @memberof SecurityHelper
	 */
	private static getWildCardPaths(
		dataSecurityPermissions: ISecurityItemDto[]): string[] {
		return this.getWildCardPermissions(dataSecurityPermissions)
			.map((data: ISecurityItemDto) =>
				this.wildCardFinder
					.exec(data.path)
					.groups
					.wildCardPath);
	}

	/**
	 * Given a sent entity type and direct access policy definition, this
	 * will map the security definitions clone for that type to be specific
	 * to the item instance based permissions.
	 *
	 * @static
	 * @param {ISecurityEntityTypeDefinition[]} securityDefinitions
	 * The set of security definitions to merge.
	 * @param {string} directEntityType
	 * The entity type associated to the sent direct access policy.
	 * @param {ISecurityDefinition} directAccessPolicyDefinition
	 * The direct access policy to override the security definitions with.
	 * @returns {ISecurityEntityTypeDefinition[]}
	 * The merged set of security entity type definitions with direct access
	 * policy overrides.
	 * @memberof SecurityHelper
	 */
	private static mergeDirectAccessPolicyDefinitions(
		securityDefinitions: ISecurityEntityTypeDefinition[],
		directEntityType: string,
		directAccessPolicyDefinition: ISecurityDefinition):
		ISecurityEntityTypeDefinition[]
	{
		const securityDefinition: ISecurityEntityTypeDefinition =
			securityDefinitions.find(
				(definition: ISecurityEntityTypeDefinition) =>
					definition.entityTypeName === directEntityType);

		securityDefinition.securityDefinition =
			directAccessPolicyDefinition;

		return securityDefinitions;
	}
}