/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	InjectionToken
} from '@angular/core';
import {
	WorkItemService
} from '@workItems/services/work-item.service';

/*
 * Export all tokens to be used in the dependency injector for
 * string based resolution
*/
export const workItemServiceToken =
	new InjectionToken<WorkItemService>(
		'WorkItemService');

/**
 * A lookup to be shared accross the application used
 * for classes available in the work item module.
 *
 * @export
 * @class WorkItemTokenLookup
 */
export class WorkItemTokenLookup
{
	/**
	 * Dictionary to be used in dependency injector for
	 * string based resolution.
	 *
	 * @static
	 * @memberof WorkItemTokenLookup
	 */
	public static readonly tokens =
		{
			'WorkItemService':
				workItemServiceToken
		};
}