
/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import {
	Injectable
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';

/**
 * A class representing a User Service.
 *
 * @export
 * @class UserService
 */
@Injectable({
	providedIn: 'root'
})
export class UserService
{
	/**
	 * Creates an instance of UserService.
	 *
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The security group api service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @memberof UserService
	 */
	public constructor(
		private readonly securityGroupApiService: SecurityGroupApiService,
		private readonly entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Decorates the security groups that the user has access to
	 * into the session user object.
	 *
	 * @async
	 * @param {IUser} user
	 * The logged in user.
	 * @param {ISecurityGroup[]} accessibleGroups
	 * The security groups to decorate the user with
	 * specific ones.
	 * @param {ISecurityGroup[]} membershipGroups
	 * The security groups the user is a member of. Optionally added
	 * to the user here, or a fresh set will be retieved form the server.
	 * @returns {Promise<IUser>}
	 * The user with user security group values set and ready for use.
	 * @memberof UserService
	 */
	public async setUserSecurityGroups(
		user: IUser,
		membershipGroups: ISecurityGroup[] = []): Promise<IUser>
	{
		const membershipFilter: string =
			'SecurityGroupEntityInstances.Any('
				+ `EntityInstanceId eq ${user.id})`;

		const membershipSecurityGroups: ISecurityGroup[] =
			membershipGroups.length > 0
				? membershipGroups
				: await ApiHelper.getFullDataSet(
					this.securityGroupApiService,
					membershipFilter,
					AppConstants.empty,
					AppConstants.empty,
					AppConstants.dataLimits.maxResultSet);

		user.membershipSecurityGroups = membershipSecurityGroups;

		return user;
	}

	/**
	 * Updates the User Instance and its security groups.
	 *
	 * @async
	 * @param {IUser} user
	 * The logged in user.
	 * @param {ISecurityGroup[]} initialSecurityGroups
	 * The initial security groups.
	 * @param {ISecurityGroup[]} changedSecurityGroups
	 * The changed security groups.
	 * @memberof UserService
	 */
	 public async updateUserSecurityGroups(
		user: IEntityInstance,
		initialSecurityGroups: ISecurityGroup[],
		changedSecurityGroups: ISecurityGroup[]): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;

		await this.entityInstanceApiService
			.update(
				user.id,
				user);

		for (const initialSecurityGroup of initialSecurityGroups)
		{
			if (!changedSecurityGroups.some((changedSecurityGroup) =>
				initialSecurityGroup.id === changedSecurityGroup.id))
			{
				await this.securityGroupApiService
					.deleteSecurityGroupUser(
						initialSecurityGroup.id,
						user.id);
			}
		}

		for (const changedSecurityGroup of changedSecurityGroups)
		{
			if (!initialSecurityGroups.some((initialSecurityGroup) =>
				initialSecurityGroup.id === changedSecurityGroup.id))
			{
				await this.securityGroupApiService
					.createSecurityGroupUser(
						changedSecurityGroup.id,
						user.id);
			}
		}
	}
}