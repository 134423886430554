/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * An enum for designating file state.
 */
export enum FileState
{
	/**
	 * The file is active.
	 *
	 * @type {string}
	 * @memberof FileState
	 */
	Active = 'Active',

	/**
	 * The file has been removed/deleted.
	 *
	 * @type {string}
	 * @memberof FileState
	 */
	Removed = 'Removed',

	/**
	 * The file has been approved.
	 *
	 * @type {string}
	 * @memberof FileState
	 */
	Approved = 'Approved',

	/**
	 * The file has been rejected.
	 *
	 * @type {string}
	 * @memberof FileState
	 */
	Rejected = 'Rejected',

	/**
	 * The file is awaiting review.
	 *
	 * @type {string}
	 * @memberof FileState
	 */
	Pending = 'Pending'
}