/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	NoteDirective
} from '@shared/directives/note.directive';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ActivityService
} from '@shared/services/activity.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-note-delete',
	templateUrl: './note-delete.component.html',
	styleUrls: [
		'./note-delete.component.scss'
	]
})

/**
 * A component representing context level notes delete display.
 *
 * @export
 * @class NoteDeleteComponent
 * @extends {NoteDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class NoteDeleteComponent
	extends NoteDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the common list component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @memberof NoteDeleteComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService,
		public activityService: ActivityService)
	{
		super(entityTypeApiService);
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof NoteCreateComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the selected entity instance item.
	 *
	 * @type {IEntityInstanceDto}
	 * @memberof NoteDeleteComponent
	 */
	@Input() public selectedItem: IEntityInstanceDto;

	/**
	 * Gets or sets the selected entity instance type group.
	 *
	 * @type {string}
	 * @memberof NoteDeleteComponent
	 */
	@Input() public typeGroup: string;

	/**
	 * Gets or sets the selected entity identifier.
	 *
	 * @type {number}
	 * @memberof NoteDeleteComponent
	 */
	@Input() public id: number;

	/**
	 * Gets or sets a value indicating whether the component is loading.
	 *
	 * @type {boolean}
	 * @memberof NoteDeleteComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a value indicating whether the component is saving.
	 *
	 * @type {boolean}
	 * @memberof NoteDeleteComponent
	 */
	public saving: boolean = false;

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof NoteDeleteComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.typeGroup = this.typeGroup
			|| (await this.getEntityTypesFromNameList(
				[this.selectedItem.entityType])).pop()?.group;
		this.id = this.id || this.selectedItem.id;

		this.entityInstanceApiService.entityInstanceTypeGroup
			= this.typeGroup;
		this.selectedItem
			= this.selectedItem
				|| await this.entityInstanceApiService.get(this.id);

		this.loading = false;
	}

	/**
	 * A method that performs the activity of deleting an entity instance.
	 *
	 * @returns {Promise<void>}
	 * @memberof NoteDeleteComponent
	 */
	public async deleteItem(): Promise<void>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = this.typeGroup;

		this.saving = true;
		await this.activityService.handleActivity<object>(
			new Activity<object>(
				this.entityInstanceApiService.delete(this.id),
				`<strong>Deleting</strong> note ${this.id}`,
				`<strong>Deleted</strong> note ${this.id}`,
				`Note ${this.id} was deleted.`,
				`Note ${this.id} was not deleted.`));
		this.saving = false;
		this.changeDisplayMode.emit(
			AppConstants.displayMode.list);
	}
}